import { Box, Button, Card, CardContent, Collapse, Divider, IconButton, LinearProgress, Link, Paper, Stack, TextField, Typography } from "@mui/material"
import Explanation from "../../Explanation"
import parse from 'html-react-parser';
import { useAuth } from "../../../../contexts/AuthContext";
import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import { Fragment, useEffect, useState } from "react";
import { removeFile, uploadFile } from "../../../../firebase/cloudFunctions";
import { getStorageBlob, _arrayBufferToBase64 } from "../../../../helper/fileHelpers";
import { useGetFiles } from "../../../../hooks/fileData/useGetFiles";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const FileUploadCard = ({ projectData, step, location, assignedQuestion, question, handleChange }) => {
    const { currentUser } = useAuth();
    const [expand, setExpand] = useState(false)

    const { files: uploadedFiles, isLoading } = useGetFiles(projectData.id, location, assignedQuestion.key, "uploaded", true)

    const [update, setUpdate] = useState(false);

    const [files, setFiles] = useState([]);
    const [imageSrc, setImageSrc] = useState(undefined);

    useEffect(() => {
        const loadFiles = async () => {
            let fileExists = [];
            for (let index = 0; index < uploadedFiles.length; index++) {
                const file = uploadedFiles[index];
                if (file.blob != null) {
                    let fileData = new File([file.blob], file.fileName, { type: file.blob.type });
                    fileExists.push({
                        id: file.id,
                        file: fileData,
                        uploadStatus: "success",
                        valid: true,
                        firebaseID: file.id,
                    })
                }
                else {
                    if (expand === true && currentUser.role === 1) {
                        await getStorageBlob(file);

                        let fileData = new File([file.blob], file.fileName, { type: file.blob.type });
                        fileExists.push({
                            id: file.id,
                            file: fileData,
                            uploadStatus: "success",
                            valid: true,
                            firebaseID: file.id,
                        })
                        //console.log("Blob:", file.blob)
                    }
                    else {
                        let emptyFile = new File([], file.fileName, { type: file.fileType });
                        fileExists.push({
                            id: file.id,
                            file: emptyFile,
                            uploadStatus: "success",
                            valid: true,
                            firebaseID: file.id,
                        })
                    }

                }

            }

            //console.log("Files: ", fileExists)
            setFiles(fileExists)
        }

        loadFiles();
    }, [uploadedFiles, expand])

    const updateFiles = (incommingFiles) => {
        //console.log("incomming files", incommingFiles);
        incommingFiles.forEach(file => {
            if (files.includes(file) === false && file.valid === true && file.uploadStatus !== "success") {
                file.uploadStatus = "uploading";
                uploadFileFunc(file).then((data) => {
                    //console.log("Response: ", data)
                    file.uploadStatus = "success";
                    file.firebaseID = data.data;
                }).catch((e) => {
                    console.error("Error: ", e);
                    file.uploadStatus = "error";
                }).finally(() => {
                    setUpdate(!update);
                })
            }
        });
        setFiles(incommingFiles);
    };

    const onDelete = (id) => {
        const deletedFile = files.find((x) => x.id === id);

        if (deletedFile != null) {
            if (deletedFile.valid === true && deletedFile.firebaseID != null && deletedFile.firebaseID !== ""){
                removeFile({
                    projectID: projectData.id,
                    fileID: deletedFile.firebaseID,
                    step: step,
                    assignedID: assignedQuestion.key,
                })
            }
            
            setFiles(files.filter((x) => x.id !== id));
        }

    };

    const handleSee = (imageSource) => {
        setImageSrc(imageSource);
    };

    const uploadFileFunc = async (file) => {
        const buffer = await file?.file?.arrayBuffer()
        const b64String = _arrayBufferToBase64(buffer)

        let fileName = file?.file?.name;
        let fileType = file?.file?.type;


        return uploadFile({
            projectID: projectData.id,
            location: location,
            step: step,
            isIBO: true,
            assignedID: assignedQuestion.key,
            isExternal: true,
            fileData: b64String,
            fileName: fileName,
            fileType: fileType,
        });
    }

    const onDownload = async (file) => {
        const link = document.createElement("a");

        link.download = file.fileName;
        if (file.blob == null) {
            await getStorageBlob(file);
        }
        link.href = URL.createObjectURL(file.blob);
        link.click();
    };

    const collapseBox = () => {
        setExpand(!expand)
    }

    return (
        <Card className="characters" variant="outlined" key={assignedQuestion.key} sx={{ maxWidth: 700, marginBottom: '1rem', paddingBottom: '0rem' }}>
            <CardContent >
                {currentUser.role >= 2 ? (
                    <Fragment>
                        <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                            <Box>
                                <strong>Uploaded Files: </strong>
                            </Box>

                            <Box sx={{ color: 'gray' }}>
                                <i>{files.length} files</i>
                            </Box>
                        </Stack>
                        {isLoading ? (
                            <Box>
                                <LinearProgress />
                            </Box>
                        ) : (
                            <Fragment>
                                {uploadedFiles.length > 0 ? (
                                    <Collapse in={expand} >
                                        <Box sx={{ marginTop: '1px' }}>
                                            <Stack >
                                                {uploadedFiles.map((file) => {
                                                    return (
                                                        <Box>
                                                            <Stack sx={{ marginTop: '0px' }} direction={'row'} alignItems="center">
                                                                <Box minWidth={'300px'}>
                                                                    {file.fileName}
                                                                </Box>
                                                                <IconButton size="small" onClick={() => { onDownload(file) }}><FileDownloadIcon /></IconButton>
                                                            </Stack>

                                                            <Divider />
                                                        </Box>
                                                    )
                                                })}
                                            </Stack>
                                        </Box>
                                    </Collapse>
                                ) : (
                                    <Box sx={{ marginTop: '10px' }}>
                                        No files uploaded!
                                    </Box>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                            <Box>
                                <strong>{parse(question.text)}</strong>
                            </Box>

                            <Box sx={{ color: 'gray' }}>
                                <i>Max: 25Mb - {files.length}/5 files</i>
                            </Box>
                        </Stack>

                        {assignedQuestion.note != null && assignedQuestion.note !== "" ? (
                            <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")
                        }
                        <Divider />
                        <Collapse in={expand} >
                            {isLoading ? (
                                <Box>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                <Fragment>
                                    {question.questionExplanation != null && question.explanation !== "" ? (
                                        <Explanation key={question.key} data={question} />
                                    ) : ('')}

                                    <Dropzone
                                        style={{ maxWidth: "100%" }}
                                        onChange={updateFiles}
                                        minHeight="60px"
                                        value={files}
                                        maxFiles={5}
                                        maxFileSize={26210000}
                                        label="Drag a file here, or click to upload file"
                                        //accept="image/*, application/pdf, .txt, .doc, .docx"
                                        uploadingMessage={"Uploading..."}
                                        view="list"
                                        header={false}
                                        footer={false}
                                        disableScroll
                                    >
                                        {files.length > 0 &&
                                            files.map((file) => (
                                                <FileItem
                                                    {...file}
                                                    key={file.id}
                                                    onDelete={onDelete}
                                                    onSee={handleSee}
                                                    resultOnTooltip
                                                    preview
                                                    info
                                                    hd
                                                />
                                            ))}

                                    </Dropzone>
                                    <Box>
                                        <FullScreenPreview
                                            imgSource={imageSrc}
                                            openImage={imageSrc}
                                            onClose={(e) => handleSee(undefined)}
                                        />
                                    </Box>

                                    {uploadedFiles.length > 0 ? (
                                        <Box sx={{ marginTop: '10px' }}>
                                            <Typography><strong>Uploaded Files:</strong> </Typography>
                                            <Stack >
                                                {uploadedFiles.map((file) => {
                                                    return (
                                                        <Box key={file.id}>
                                                            <Stack sx={{ marginTop: '0px' }} direction={'row'} alignItems="center">
                                                                <Box minWidth={'300px'}>
                                                                    {file.fileName}
                                                                </Box>
                                                                <IconButton size="small" onClick={() => { onDownload(file) }}><FileDownloadIcon /></IconButton>
                                                            </Stack>

                                                            <Divider />
                                                        </Box>
                                                    )
                                                })}
                                            </Stack>

                                        </Box>
                                    ) : ("")}
                                </Fragment>
                            )}
                        </Collapse>
                    </Fragment>
                )}

            </CardContent >
            {currentUser.role === 1 || uploadedFiles.length > 0 ? (
                <Button fullWidth size="small" onClick={collapseBox}>
                    {expand ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                </Button>
            ) : ("")}

        </Card >
    )
}
