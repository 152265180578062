import { useEffect, useState } from 'react'
import '../../assets/scss/app.scss'
import { assignBDToProj } from '../../firebase/cloudFunctions';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Alert, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, Paper, Stack, TableFooter, TablePagination, TextField } from '@mui/material';
import { useGetBdOwners } from '../../hooks/userData/useGetBdOwners';
import { useGetProjects } from '../../hooks/projectData/useGetProjects';
import { useConfirm } from 'material-ui-confirm';
import StepTableRow from './TableRow'
import Box from '@mui/material/Box';
import { TableCell } from '@mui/material';
import { useGetIboNames } from '../../hooks/userData/useGetIboNames';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
//const assignBDToProj = firebase.functions().httpsCallable('assignBDToProj');



function addUnassigned(project) {
    if (project.bdowner == null || project.bdowner.length === 0) {
        return (
            <option value={-1} disabled>
                {"Unassigned"}
            </option>
        );

    } else {
        return;
    }
}

const TableIbo = () => {
    const location = useLocation();
    const selectedTab = location.pathname;
    //console.log("Selected:", selectedTab)
    const [searchValString, setSearchValString] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const { currentUser } = useAuth();
    const projects = useGetProjects();
    const bdowners = useGetBdOwners();
    const [iboNames] = useGetIboNames();
    const [error, setError] = useState(false);
    const [orderBy, setOrderBy] = useState("Next");
    const [order, setOrder] = useState("desc");
    const [sortableProjects, setSortableProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElStatus, setAnchorElStatus] = useState(null);
    const [anchorElSearch, setAnchorElSearch] = useState(null);
    const [bdFilter, setBdFilter] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [filterStatus, setFilterStatus] = useState('active')
    const [updateSearch, setUpdateSearch] = useState(false);

    const open = Boolean(anchorEl);
    const openStatus = Boolean(anchorElStatus);
    const openSearch = Boolean(anchorElSearch);

    useEffect(() => {
        if (updateSearch === true){
            //console.log("Val: ", searchValString)
            setSearchVal(searchValString);
            setUpdateSearch(false);
        }
    }, [updateSearch])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        setAnchorEl(null);
        if (value != null) {
            setBdFilter(value);
        }
    };

    const handleClickStatus = (event) => {
        setAnchorElStatus(event.currentTarget);
    };

    const handleCloseStatus = (value) => {
        setAnchorElStatus(null);
        if (value != null) {
            setFilterStatus(value);
        }
    };

    const handleClickSearch = (event) => {
        if (openFilter === true) {
            setOpenFilter(false);
            setSearchVal('');
        }
        else {
            setOpenFilter(true);
        }

    };

    const handleSearchValChange = (event) => {
        setSearchValString(event.target.value);
        if (updateSearch === false){
            const delayDebounceFn = setTimeout(() => {
                setUpdateSearch(true);
            }, 800);

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
        
    }

    const handleCloseSearch = (value) => {
        //setAnchorElSearch(null);
        setOpenFilter(false);
    };

    const clearSearch = () => {
        setOpenFilter(false);
        setSearchValString('');
        setSearchVal('');
    }


    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);

    /*
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            fontFamily: ['Montserrat', 'serif'].join(','),

        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
            marginTop: '20px',
        },
        visuallyHidden: {
            border: 1,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        head: {
            fontWeight: 'bold',
        },

    }));*/

    const confirm = useConfirm();


    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (projects.length > 0 && iboNames.length > 0 && bdowners.length > 0) {
            const sortProjects = [];
            //console.log("Trigger", projects.length, iboNames.length, bdowners.length)
            projects.forEach((project) => {
                let IboName = "Not Found"
                let BdName = "Unassigned"
                let bdID;
                const index = iboNames.find(iboName => iboName.id === project.iboOwner[0])
                if (index != null) {
                    IboName = index.value;
                }
                const bdindex = bdowners.find(bdName => bdName.id === project.bdowner[0])

                if (bdindex != null) {
                    BdName = bdindex.value;
                    bdID = bdindex.id;
                }

                if (project.deadline != null) {
                    sortProjects.push({
                        project: project,
                        id: project.id,
                        IBO: project.value,
                        Contact: IboName,
                        BD: BdName,
                        BDId: bdID,
                        isActive: project.isActive,
                        Progress: project.currentStep,
                        Next: new Date(project.deadline.time * 1000),
                    });
                }
                else {
                    sortProjects.push({
                        project: project,
                        id: project.id,
                        IBO: project.value,
                        Contact: IboName,
                        BD: BdName,
                        BDId: bdID,
                        isActive: project.isActive,
                        Progress: project.currentStep,
                        Next: new Date(8640000000000000),
                    });
                }

            });

            setSortableProjects(sortProjects);
        }
    }, [projects, iboNames, bdowners]);


    //console.log("Projs: ", sortableProjects);

    function AssignBD(projectID, event) {
        let userid = event.target.value
        //console.log('this event' + event.target.value);
        let username;
        bdowners.forEach((owner) => {
            if (event.target.value === owner.id) {
                username = owner.value;
            }
        })
        confirm({ title: 'Please confirm', description: 'Are you sure you want to assign ' + username + ' as Business Designer?' })
            .then(() => {
                setIsLoading(true);
                assignBDToProj({
                    projectID: projectID,
                    userID: userid,
                })
                    .then(() => {
                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    })
                    //this code below runs when the message was NOT successfully sent from inside of the cloud function
                    .catch(() => {
                        //set `error` state to true
                        setError(true);

                        //set `isLoading` state to false, so the spinner is not rendered anymore
                        setIsLoading(false);

                        //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                        setOpenSnackbar(true);
                    });
            })
            .catch(() => { setIsLoading(false); });
    }

    const createSortHandler = (property) => (event) => {
        //onRequestSort(event, property);
        const isAsc = orderBy === property && order === 'desc';
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'asc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function filterTab(item) {
        if (filterStatus === 'active' && item.isActive === false) {
            return false;
        }
        else if (filterStatus === 'inactive' && item.isActive !== false) {
            return false;
        }
        else if ((bdFilter === "assignedMe" || bdFilter === "") && item.BDId !== currentUser.uid) {
            return false;
        }
        else if ((bdFilter === "assigned" || bdFilter === "") && (item.BDId == null || item.BDId === "")) {
            return false;
        }
        else {
            if (searchVal != null && searchVal !== '') {
                if (item?.IBO.toLowerCase().includes(searchVal.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }

    }

    function filterBDs(item) {
        if (bdFilter === "assignedMe" || bdFilter === "") {
            if (item.BDId === currentUser.uid) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (bdFilter === "assigned") {
            if (item.BDId != null) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    const filterSearch = (item) => {
        if (searchVal != null && searchVal !== '') {
            if (item?.IBO.toLowerCase().includes(searchVal.toLowerCase())) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }

    return (
        <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
            ) : (
                <Table style={{
                    marginTop: '20px',

                }}
                    aria-labelledby="iboTable"
                    aria-label="IBO Table">
                    <TableHead>
                        <TableRow>
                            {/* <th>Delete</th> */}
                            {selectedTab === "/admin" && currentUser.role >= 3 ? (<TableCell>
                                Delete
                            </TableCell>) : (<></>)}
                            <TableCell>
                                <IconButton size='small' onClick={handleClickStatus}>
                                    <FilterListIcon style={{ width: "18px", height: "fit-content" }} />
                                </IconButton>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElStatus}
                                    open={openStatus}
                                    onClose={() => { handleCloseStatus(null) }}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {filterStatus === "active" || filterStatus === "" ? (
                                        <MenuItem style={{ color: "black" }} value='active' onClick={() => handleClose("active")}>Only Active</MenuItem>
                                    ) : (
                                        <MenuItem style={{ color: "gray" }} value='active' onClick={() => handleCloseStatus("active")}>Only Active</MenuItem>
                                    )}
                                    {filterStatus === "inactive" || filterStatus === "" ? (
                                        <MenuItem style={{ color: "black" }} value='inactive' onClick={() => handleClose("inactive")}>Only Inactive</MenuItem>
                                    ) : (
                                        <MenuItem style={{ color: "gray" }} value='inactive' onClick={() => handleCloseStatus("inactive")}>Only Inactive</MenuItem>
                                    )}
                                    {filterStatus === "all" ? (
                                        <MenuItem style={{ color: "black" }} value='all' onClick={() => handleClose("all")}>All</MenuItem>
                                    ) : (
                                        <MenuItem style={{ color: "gray" }} value='all' onClick={() => handleCloseStatus("all")}>All</MenuItem>
                                    )}
                                </Menu>
                            </TableCell>
                            <TableCell sx={{ minWidth: '100px' }}>
                                <Stack direction={'row'}>
                                    <IconButton onClick={handleClickSearch}>
                                        {searchVal != null && searchVal !== '' ? (
                                            <SearchIcon style={{ width: "18px", height: "fit-content" }} htmlColor='#4eacc1' />
                                        ) : (
                                            <SearchIcon style={{ width: "18px", height: "fit-content" }} />
                                        )}
                                    </IconButton>
                                    <TableSortLabel
                                        active={orderBy === "IBO"}
                                        direction={orderBy === "IBO" ? order : 'desc'}
                                        onClick={createSortHandler("IBO")}
                                    >IBO</TableSortLabel>

                                    {openFilter === true ? (
                                        <Box>
                                            <FormControl sx={{ mb: '-4px', width: '25ch' }} variant="outlined">
                                                <InputLabel htmlFor='search-box'>Search</InputLabel>
                                                <OutlinedInput
                                                    id='search-box'
                                                    label="Search"
                                                    size='small'
                                                    sx={{ width: '150px' }}
                                                    value={searchValString}
                                                    onChange={handleSearchValChange}
                                                    onBlur={(e) => { setSearchVal(e.target.value) }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setSearchVal(e.target.value);
                                                        }
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle clear search"
                                                                onClick={clearSearch}
                                                                edge="end"
                                                            >
                                                                <ClearIcon fontSize='small' />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Box>

                                    ) : ("")}
                                </Stack>

                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                                <TableSortLabel
                                    active={orderBy === "Contact"}
                                    direction={orderBy === "Contact" ? order : 'desc'}
                                    onClick={createSortHandler("Contact")}
                                >Contact Person</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                                <IconButton
                                    onClick={handleClick}>
                                    <FilterListIcon style={{ width: "18px", height: "fit-content" }} />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => handleClose(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {bdFilter === "assignedMe" || bdFilter === "" ? (<MenuItem style={{ color: "black" }} value='assignedMe' onClick={() => handleClose("assignedMe")}>Assigned to me</MenuItem>)
                                        : (<MenuItem style={{ color: "gray" }} value='assignedMe' onClick={() => handleClose("assignedMe")}>Assigned to me</MenuItem>)}
                                    {bdFilter === "assigned" ? (<MenuItem style={{ color: "black" }} value='assigned' onClick={() => handleClose("assigned")}>All assigned</MenuItem>)
                                        : (<MenuItem style={{ color: "gray" }} value='assigned' onClick={() => handleClose("assigned")}>All assigned</MenuItem>)}
                                    {bdFilter === "all" ? (<MenuItem style={{ color: "black" }} value='all' onClick={() => handleClose("all")}>All</MenuItem>)
                                        : (<MenuItem style={{ color: "gray" }} value='all' onClick={() => handleClose("all")}>All</MenuItem>)}
                                </Menu>

                                <TableSortLabel
                                    active={orderBy === "BD"}
                                    direction={orderBy === "BD" ? order : 'desc'}
                                    onClick={createSortHandler("BD")}
                                >BD</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                                Process
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                <TableSortLabel
                                    active={orderBy === "Progress"}
                                    direction={orderBy === "Progress" ? order : 'desc'}
                                    onClick={createSortHandler("Progress")}
                                >Progress</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                                <TableSortLabel
                                    active={orderBy === "Next"}
                                    direction={orderBy === "Next" ? order : 'desc'}
                                    onClick={createSortHandler("Next")}
                                >Next deadline/Meeting</TableSortLabel>
                            </TableCell>
                            <TableCell>Miro Link</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>Files</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortableProjects.filter(filterTab).sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => {
                            return (
                                <StepTableRow key={project.id || index} project={project.project} addUnassigned={addUnassigned} AssignBD={AssignBD} bdowners={bdowners} iboNames={iboNames} isAdmin={selectedTab === "/admin" && currentUser.role >= 3} />
                            )
                        })}

                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelRowsPerPage=""
                                page={page}
                                count={sortableProjects.filter(filterBDs).length}
                                rowsPerPageOptions={[10, 20, 50]}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />
                        </TableRow>

                    </TableFooter>
                </Table>
            )}
            {/* {projects.map((project) => {
                return (
                    <Comments key={project.id} project={project} />
                )
            })} */}
            {error ? (
                <Snackbar
                    open={openSnackbar}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={closeSnackbar} severity="error">
                        Something went wrong!
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={closeSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="success">Done!!</Alert>
                </Snackbar>
            )}

        </Box>

    )
}

export default TableIbo