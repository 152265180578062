import { Box, Button, CircularProgress, Divider, Grid, IconButton, LinearProgress, Stack, TextField, Tooltip } from "@mui/material";
import { hover } from "@testing-library/user-event/dist/hover";
import { format } from "date-fns";
import { Fragment, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LaunchIcon from '@mui/icons-material/Launch';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { getStorageBlob, getStorageUrl, _arrayBufferToBase64, canPreviewFile } from "../../helper/fileHelpers";
import { Dropzone, FileItem } from "@dropzone-ui/react";
import { removeFile, setFileStatus, uploadFile } from "../../firebase/cloudFunctions";

export const FileRows = ({ projID, files, sortOrder, location = null, questionKey = null, step = null, isExternal = false }) => {
    const { currentUser } = useAuth()

    const [isLoading, setIsLoading] = useState(false);
    const [newfiles, setNewFiles] = useState([]);
    const [hover, setHover] = useState('');
    const [update, setUpdate] = useState(false);
    const [fileLoading, setFileLoading] = useState({})

    const handleMouseOver = (file) => {
        setHover(file);
    }

    const handleMouseOut = () => {
        setHover('');
    }

    const sortList = (a, b) => {
        if (sortOrder === 'ascending') {
            return a.uploaded?.seconds - b.uploaded?.seconds;
        }
        else {
            return b.uploaded?.seconds - a.uploaded?.seconds;
        }
    }

    const deleteFile = (file) => {
        setIsLoading(true);
        //console.log("Delete: ", file)

        let datasnap = {
            projectID: projID,
            fileID: file.id,
        }

        if (file.assignedID != null && file.assignedID !== "") {
            datasnap['assignedID'] = file.assignedID;
        }

        if (file.step != null && file.step !== "") {
            datasnap['step'] = file.step;
        }

        removeFile(datasnap).catch((e) => {
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const onDownload = async (file) => {
        const link = document.createElement("a");

        link.download = file.fileName;
        if (file.blob == null) {
            await getStorageBlob(file);
        }
        link.href = URL.createObjectURL(file.blob);
        link.click();
    };

    const onOpenLink = async (file) => {
        //console.log("File: ", file)
        if (file.blob == null) {
            await getStorageBlob(file);
        }

        const newWindow = window.open()
        newWindow.document.title = file.fileName;
        newWindow.document.write(`<iframe src="${URL.createObjectURL(file.blob)}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`)
    };

    const updateFiles = (incommingFiles) => {
        //console.log("incomming files", incommingFiles);
        incommingFiles.forEach(file => {
            if (files.includes(file) === false && file.valid === true && file.uploadStatus !== "success") {
                file.uploadStatus = "uploading";
                uploadFileFunc(file).then((data) => {
                    //console.log("Response: ", data)
                    file.uploadStatus = "success";
                    file.firebaseID = data.data;
                }).catch((e) => {
                    console.error("Error: ", e);
                    file.uploadStatus = "error";
                }).finally(() => {
                    setUpdate(!update);
                })
            }
        });
        setNewFiles(incommingFiles);
    };

    const uploadFileFunc = async (file) => {
        const buffer = await file?.file?.arrayBuffer()
        const b64String = _arrayBufferToBase64(buffer)

        let fileName = file?.file?.name;
        let fileType = file?.file?.type;


        //console.log("FileBuffer: ", projID, step, location);

        return uploadFile({
            projectID: projID,
            location: location,
            step: step,
            isIBO: false,
            isExternal: isExternal,
            fileData: b64String,
            fileName: fileName,
            fileType: fileType,
        });
    }


    const setFileStatusFunc = async (file, setToPublic) => {
        //console.log("File: ", file, "New Status: ", setToPublic)
        file.isExternal = setToPublic;
        fileLoading[file.id] = true;
        setUpdate(!update);
        await setFileStatus({
            projectID: projID,
            fileID: file.id,
            isExternal: setToPublic,
        }).then(() => {
            //console.log("Done");
            fileLoading[file.id] = false;
        }).catch((error) => {
            console.error("Error: ", error);
            fileLoading[file.id] = false;
        }).finally(() => {
            setUpdate(!update);
        })
    }

    const onDelete = (id) => {
        setNewFiles(files.filter((x) => x.id !== id));
    };

    const filterList = (item) => {
        if (location != null && location !== "") {
            if (item.isIBO === true) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return true;
        }
    }

    return (
        <Fragment>
            <Box sx={{
                width: 900,
                maxWidth: '100%',
            }}>
                <Box>
                    {files.filter(filterList).length > 0 ? (
                        <Fragment>
                            {files.filter(filterList).sort(sortList).map((file) => {
                                let style = {};
                                return (
                                    <Fragment>
                                        <Box sx={style} onMouseOver={() => handleMouseOver(file.id)} onMouseOut={handleMouseOut}>
                                            <Stack>
                                                <Grid container columns={{ xs: 4, sm: 6, md: 12 }} justifyContent="space-between" spacing={1}>
                                                    <Grid item xs={4} sm={4} md={10}>
                                                        <Stack direction={'row'} justifyContent="flex-start" alignItems={'center'}>
                                                            <span className={file?.isIBO === true ? "file-name-ibo" : "file-name"}>
                                                                {file?.fileName}
                                                            </span>
                                                            <span>
                                                                {file?.uploaded != null ? (format(new Date(file?.uploaded?.seconds * 1000), 'yyyy-MM-dd HH:mm')) : ("Not Set")}
                                                            </span>

                                                            <span style={{ color: 'grey', marginLeft: '5px' }}>
                                                                {file?.location != null ? (file?.location) : ("General")}
                                                            </span>

                                                            {file?.assignedID !== "undefined" && file?.assignedID != null ? (
                                                                <span>
                                                                    <QuizOutlinedIcon
                                                                        style={{
                                                                            fontSize: "17px",
                                                                            marginLeft: "5px",
                                                                            color: 'lightblue',
                                                                            //float: "middle",
                                                                        }}
                                                                    />
                                                                </span>
                                                            ) : ("")}

                                                            <div style={{ marginLeft: '10px' }}>
                                                                {file.isExternal ? (
                                                                    <Tooltip title="External" arrow>
                                                                        <VisibilityIcon style={{
                                                                            fontSize: "17px",
                                                                            color: '#8f8f8f',
                                                                        }} />
                                                                    </Tooltip>

                                                                ) : (
                                                                    <Tooltip title="Internal" arrow>
                                                                        <VisibilityOffIcon style={{
                                                                            fontSize: "17px",
                                                                            color: '#8f8f8f',
                                                                        }} />
                                                                    </Tooltip>

                                                                )}
                                                            </div>
                                                        </Stack>

                                                        <Stack direction={'row'} justifyContent="flex-start" spacing={0.5}>

                                                            <span>
                                                                Uploaded by:
                                                            </span>

                                                            {file?.isIBO === true ? (
                                                                <span><strong>IBO</strong></span>
                                                            ) : ("")}

                                                            <span>{file?.createdUserName}</span>


                                                        </Stack>
                                                    </Grid>


                                                    <Grid item xs={4} sm={1} md={2}>
                                                        <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent="flex-end">
                                                            {currentUser != null && currentUser.uid === file.uploadedBy ? (
                                                                <IconButton disabled={isLoading} onClick={() => { deleteFile(file) }} size={'small'}>
                                                                    <HighlightOffOutlinedIcon
                                                                        fontSize="small"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            ) : ("")}
                                                            {/* 
                                                            {currentUser != null && (currentUser.uid === file.uploadedBy || currentUser.role <= 3) && file?.isIBO === false && file?.location != null ? (
                                                                <Box>
                                                                    {fileLoading[file.id] === true ? (
                                                                        <Fragment>
                                                                            <IconButton size={'small'} disabled>
                                                                               <CircularProgress size={'22px'} /> 
                                                                            </IconButton>
                                                                            
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            {file.isExternal ? (
                                                                                <Tooltip title="Public. Click to make file private." arrow>
                                                                                    <IconButton onClick={() => { setFileStatusFunc(file, false) }} size={'small'}>
                                                                                        <PublicIcon
                                                                                            style={{
                                                                                                fontSize: '22px',
                                                                                                color: '#46a63f',
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Tooltip title="Private. Click to make file public for IBO." arrow>
                                                                                    <IconButton onClick={() => { setFileStatusFunc(file, true) }} size={'small'}>
                                                                                        <PublicOffIcon
                                                                                            style={{
                                                                                                fontSize: '22px',
                                                                                                color: '#b63a3a',
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )}
                                                                        </Fragment>
                                                                    )}

                                                                </Box>
                                                            ) : (
                                                                <Box sx={{ minWidth: '33px' }}>

                                                                </Box>
                                                            )}*/}


                                                            {canPreviewFile(file) === true ? (
                                                                <IconButton onClick={() => { onOpenLink(file) }} size={'small'}>
                                                                    <LaunchIcon
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            ) : (
                                                                <Box sx={{ minWidth: '33px' }}>

                                                                </Box>
                                                            )}


                                                            <IconButton onClick={() => { onDownload(file) }} size={'small'}>
                                                                <FileDownloadIcon
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </Stack>
                                                    </Grid>

                                                </Grid>
                                                {/* 
                                                <Stack direction={'row'} alignItems='center' justifyContent="space-between">
                                                    <Stack>
                                                        <Stack direction={'row'}>
                                                            <span className="comment_username">
                                                                {file?.fileName}
                                                            </span>
                                                            <span>
                                                                {file?.uploaded != null ? (format(new Date(file?.uploaded?.seconds * 1000), 'yyyy-MM-dd HH:mm')) : ("Not Set")}
                                                            </span>

                                                            <span style={{ color: 'grey', marginLeft: '10px' }}>
                                                                {file?.location != null ? (file?.location) : ("General")}
                                                            </span>

                                                            {file?.assignedID !== "undefined" && file?.assignedID != null ? (
                                                                <span>
                                                                    <QuizOutlinedIcon
                                                                        style={{
                                                                            fontSize: "17px",
                                                                            marginLeft: "5px",
                                                                            color: 'lightblue',
                                                                            //float: "middle",
                                                                        }}
                                                                    />
                                                                </span>
                                                            ) : ("")}
                                                        </Stack>
                                                        <Stack direction={'row'} spacing={0.5}>
                                                            <span>
                                                                Uploaded by:
                                                            </span>

                                                            {file?.isIBO === true ? (
                                                                <span><strong>IBO</strong></span>
                                                            ) : ("")}

                                                            <span>{file?.createdUserName}</span>
                                                        </Stack>
                                                    </Stack>

                                                    <Stack direction={'row'} spacing={1}>
                                                        {currentUser != null && currentUser.uid === file.uploadedBy ? (
                                                            <IconButton disabled={isLoading} onClick={() => { deleteFile(file) }} size={'small'}>
                                                                <HighlightOffOutlinedIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ) : ("")}


                                                        {canPreview(file) === true ? (
                                                            <IconButton onClick={() => { onOpenLink(file) }} size={'small'}>
                                                                <LaunchIcon
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ) : (
                                                            <Box sx={{ width: '35px' }}>

                                                            </Box>
                                                        )}


                                                        <IconButton onClick={() => { onDownload(file) }} size={'small'}>
                                                            <FileDownloadIcon
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </IconButton>


                                                    </Stack>
                                                </Stack>*/}
                                            </Stack>
                                        </Box>
                                        <Divider sx={{ marginTop: '6px', marginBottom: '6px' }} />
                                    </Fragment>
                                )
                            }
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                                No files uploaded 😥
                            </Box>
                        </Fragment>
                    )}

                </Box>


                {isLoading ? (
                    <Box sx={{ width: 'auto', marginBottom: '7px' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Box sx={{ width: 'auto', height: '5px', marginBottom: '7px' }}>

                    </Box>
                )}

                <form className="form_internal">
                    <Fragment>
                        <Dropzone
                            style={{ minWidth: "250px" }}
                            onChange={updateFiles}
                            minHeight="60px"
                            value={newfiles}
                            maxFileSize={26210000}
                            label="Drag a file here, or click to upload file"
                            //accept="image/*, application/pdf, .txt, .doc, .docx"
                            uploadingMessage={"Uploading..."}
                            view="list"
                            header={false}
                            footer={false}
                            disableScroll
                            fakeUploading
                        >
                            {newfiles.length > 0 &&
                                newfiles.map((file) => (
                                    <FileItem
                                        {...file}
                                        key={file.id}
                                        onDelete={onDelete}
                                        resultOnTooltip
                                        preview
                                        info
                                        hd
                                    />
                                ))}

                        </Dropzone>
                    </Fragment>
                </form>
            </Box >
        </Fragment>
    )
}