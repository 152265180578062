import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal } from "@mui/material";
import { Fragment, useState } from "react";
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import EditIcon from '@mui/icons-material/Edit';
import UpdateUserInfo from "../../UpdateData/UpdateUserInfo";
import { useAuth } from "../../../contexts/AuthContext";
import { styled } from "@mui/system";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";
import { useGetUserInfoTexts } from "../../../hooks/languageHooks/useGetUserInfoTexts";
import { useGetGeneralPhrases } from "../../../hooks/languageHooks/useGetGeneralPhrases";

export const DisplayUserInfo = ({ user, updateFrame }) => {
    const userInfoHeaders = useGetUserInfoTexts();
    const generalPhrases = useGetGeneralPhrases();

    const { currentUser } = useAuth();

    const [openModalUser, setOpenModalUser] = useState(false);
    const handleModalUserOpen = () => {
        setOpenModalUser(true);
    }

    const handleModalUserClose = () => {
        updateFrame();
        setOpenModalUser(false);
    }

    const displayLang = (lang) => {
        if (lang === 'swe') {
            return "Svenska";
        }
        else {
            return "English";
        }
    }

    return (
        <Fragment>
            <Box className="EntryStep-container">
                {currentUser.role >= 2 || currentUser.uid === user.id ? (
                    <Fragment>
                        {user.data != null && user.data.disabled === true ? (
                            <span>
                                <strong>{userInfoHeaders.userInfo}: {userInfoHeaders.deactivated}</strong>
                            </span>
                        ) : (
                            <div>
                                <strong>{userInfoHeaders.userInfo}: {user.data != null ? user.data.firstName : generalPhrases.notSet} {user.data != null ? user.data.lastName : generalPhrases.notSet}</strong>

                                <IconButton
                                    className="edit-icon-right"

                                    onClick={handleModalUserOpen}>

                                    <EditIcon style={{ width: "18px", height: "fit-content" }} />
                                </IconButton>
                            </div>
                        )}

                        <List disablePadding style={{ width: '80%' }}>
                            <ListItem key={user.id + "-email"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.email} secondary={user.data != null ? user.data.email : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />

                            <ListItem key={user.id + "-gender"}>
                                {currentUser.role >= 2 ? (
                                    <BarChartRoundedIcon style={{ marginLeft: '-40px', marginRight: '15px', color: 'green' }} />
                                ) : ("")}

                                <StyledListItemEntryStep primary={userInfoHeaders.gender} secondary={user.data != null ? user.data.gender : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />

                            <ListItem key={user.id + "-dob"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.dob} secondary={user.data != null ? user.data.dateOfBirth : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />

                            <ListItem key={user.id + "-city"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.city} secondary={user.data != null ? user.data.address || user.data.city : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />

                            <ListItem key={user.id + "-phone"}>
                                <StyledListItemEntryStep
                                    primary={userInfoHeaders.phone}
                                    secondary={user.data != null ? user.data.phoneNr : generalPhrases.notProvided}
                                />
                            </ListItem>
                            <Divider />

                            <ListItem key={user.id + "-edu"}>
                                {currentUser.role >= 2 ? (
                                    <BarChartRoundedIcon style={{ marginLeft: '-40px', marginRight: '15px', color: 'green' }} />
                                ) : ("")}

                                <StyledListItemEntryStep primary={userInfoHeaders.education} secondary={user.data != null ? user.data.education : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />
                            <ListItem key={user.id + "-connection"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.hkrConn} secondary={user.data != null ? user.data.hkrConnection : generalPhrases.notSet} />
                            </ListItem>
                            <Divider />
                            <ListItem key={user.id + "-pref"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.language} secondary={user.data != null ? displayLang(user.data.prefLang) : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />
                            <ListItem key={user.id + "-expectation"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.expectation} secondary={user.data != null ? user.data.expectation : generalPhrases.notSet} />
                            </ListItem>

                            <Divider />
                            <ListItem key={user.id + "-reference"}>
                                <StyledListItemEntryStep primary={userInfoHeaders.reference} secondary={""} />
                            </ListItem>
                            {user.data != null && user.data.reference != null ? String(user.data.reference).split(';').map((answer) => {
                                return (
                                    <ListItem key={user.id + "-reference-" + answer}>
                                        <StyledListItemEntryStep primary={""} secondary={answer} />
                                    </ListItem>);
                            }) : [""]}
                        </List>

                        <Modal
                            open={openModalUser}
                            onClose={handleModalUserClose}
                        >
                            <UpdateUserInfo userInfo={user} closeModal={handleModalUserClose} />
                        </Modal>
                    </Fragment>
                ) : ("")}
            </Box>
        </Fragment>
    )
};