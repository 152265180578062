import { createContext, useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebase'
import { createUserData, deactivateUser } from '../firebase/cloudFunctions';

import CircularProgress from '@mui/material/CircularProgress';
//import useGetUserData from '../hooks/useGetUserData';
//import firebase from 'firebase';
import firebaseRefList from '../firebase/firebaseRefList'
import { createUserWithEmailAndPassword, EmailAuthProvider, onAuthStateChanged, reauthenticateWithCredential, sendPasswordResetEmail, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

//const createUserData = firebase.functions().httpsCallable('createUserData');
//const deactivateUser = firebase.app().functions("europe-west1").httpsCallable('deactivateUser');

const AuthContext = createContext(null)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider = (props) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const analytics = getAnalytics();
    //const { userData } = useGetUserData();

    const login = async (email, password) => {
        const response = await signInWithEmailAndPassword(auth, email, password);
        logEvent(analytics, 'login', {
            method: 'Email',
        });
        return response;
    }

    const logout = async () => {
        try {
            firebaseRefList.forEach((subscriber) => {
                if (typeof subscriber === 'function') {
                    subscriber()
                }
            });
            firebaseRefList.length = 0;
        } catch (error) {
            //console.log(error);
        }

        await auth.signOut();

        try {
            window.localStorage.clear();
            sessionStorage.clear();
        } catch (e) {
            //console.log(e);
        }
    }

    const signup = async (email, password, firstName, lastName, isSecondary) => {
        try {
            const user = await createUserWithEmailAndPassword(auth, email, password);
            logEvent(analytics, 'sign_up', {
                method: 'Email',
                is_secondary: isSecondary,
            });
            //const navigate = useNavigate()
            if (isSecondary) {
                await createUserData({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    isSecondary: isSecondary,
                });
            }
            else {
                await createUserData({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                });
            }

            return user;
        } catch (error) {
            throw error;
        }

    }

    const changePassword = async (oldPassword, newPassword) => {
        if (auth?.currentUser?.email != null) {
            let credential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);

            return reauthenticateWithCredential(auth.currentUser, credential).then(() => {
                // @ts-ignore
                return updatePassword(auth.currentUser, newPassword);
            }).catch((error) => {
                return error;
            });
        }

    }

    const deactivateAccont = async (password) => {
        if (auth?.currentUser != null && auth.currentUser?.email != null) {
            let credential = EmailAuthProvider.credential(auth.currentUser?.email, password);

            return reauthenticateWithCredential(auth.currentUser, credential).then(() => {
                deactivateUser({
                    userID: auth.currentUser?.uid,
                }).then(() => {
                    logout();
                }).catch((e) => {
                    return e;
                })

            }).catch((error) => {
                return error;
            });
        }
    }

    const reset = (email) => {
        return sendPasswordResetEmail(auth, email)
    }

    // const firestoreRef = db.collection("Users").doc(currentUser);
    // const userData = await firestoreRef.get();
    // if (userData.exists && userData.data().role )


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async user => {
            if (user) {
                const userData = auth.currentUser;
                userData['dataSet'] = false;
                const firestoreRef = doc(db, "Users/" + auth.currentUser.uid);
                await getDoc(firestoreRef).then((userDataLocal) => {
                    if (userDataLocal.data() != null) {
                        if (userDataLocal.data().role != null) {
                            userData["role"] = userDataLocal.data().role;
                            userData["userInfo"] = userDataLocal.data();
                            userData['dataSet'] = true;
                        }
                    }
                });

                setCurrentUser(userData)
            }
            else {
                //console.log("Logout");
                setCurrentUser(null)
            }

            setLoading(false)
        })

        return unsubscribe
    }, []);

    const contextValues = {
        currentUser,

        loading,
        login,
        logout,
        reset,
        signup,
        changePassword,
        deactivateAccont,
    }

    return (
        <AuthContext.Provider value={contextValues}>
            {loading && (<div className="d-flex justify-content-center my-5"><CircularProgress /></div>)}
            {!loading && props.children}
        </AuthContext.Provider>
    )
}

export { AuthContext, useAuth, AuthProvider as default }
