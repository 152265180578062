import { format } from 'date-fns';
import { doc, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList';

export const useGetProjectLinkStep = (projectID, step) => {
    const [stepData, setStepData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (projectID == null || step == null) return;
        const ref = onSnapshot(doc(db, "Projects/" + projectID + "/Steps/" + step), (querySnapshot) => {
            if (querySnapshot.exists() === true) {
                let snap = {
                    ...querySnapshot.data(),
                    key: querySnapshot.id,
                }
                // eslint-disable-next-line eqeqeq
                if (querySnapshot.data().nextTime != undefined) {
                    snap["nextDeadline"] = format(new Date(querySnapshot.data().nextTime.seconds * 1000), 'yyyy-MM-dd eeee HH:mm')
                }

                setStepData(snap)
                setIsLoading(false);
            };
        })

        firebaseRefList.push(ref);
    }, [projectID, step]);

    return {stepData, isLoading};
};