import { Fragment, useEffect, useState } from "react";
import { useAuth } from '../../../contexts/AuthContext'
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { addProjectInfo, createNewProject } from '../../../firebase/cloudFunctions';
import { Autocomplete, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, LinearProgress, Radio, RadioGroup } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetUserProjectId } from "../../../hooks/userData/useGetUserProjectId";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../../helper/errorAlert";
import { getAnalytics, logEvent } from "firebase/analytics";
import { REGION_LIST, COUNTY_LIST } from "../../../constants/lang/general/regions";

import textBubble from '../../../assets/images/tip-bubble.png'


const CreateNewProject = ({ handleChange, values }) => {
    const { projectIDs, loadingProjID } = useGetUserProjectId();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const [projectID, setProjectID] = useState("");
    const [creatingProj, setCreatingProj] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('');
    const [autoSave, setAutoSave] = useState('');

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const setInitYearFounded = () => {
        if (values.founded != null && values.founded !== "") {
            let localYear = new Date(Date.parse(values.founded));
            return localYear;
        }
        else {
            return null;
        }
    }

    const [year, setYear] = useState(setInitYearFounded());
    const [isProjNameInvalid, setProjNameInvalid] = useState(false);
    const [isExistingCompInvalid, setExistingCompInvalid] = useState(false);
    const [isCompNameInvalid, setCompNameInvalid] = useState(false);
    const [isCompFormInvalid, setCompFormInvalid] = useState(false);
    const [isOrgNumInvalid, setOrgNumInvalid] = useState(false);
    const [isCompCityInvalid, setCompCityInvalid] = useState(false);
    const [isRegionInvalid, setRegionInvalid] = useState(false);
    const [isCountyInvalid, setCountyInvalid] = useState(false);
    const [isCompRoleInvalid, setCompRoleInvalid] = useState(false);
    const [isRevenueInvalid, setRevenueInvalid] = useState(false);
    const [isYearFoundedInvalid, setYearFoundedInvalid] = useState(false);
    const [isGeneralInvalid, setGeneralInvalid] = useState(false);

    const [queueSubmit, setQueueSubmit] = useState(false);
    const [queueSave, setQueueSave] = useState(false);


    const companyChoices = {
        no: "No/Not yet",
        yes_new: "Yes, in a newly formed company based on the idea",
        yes_established: "Yes, within an established company",
    }

    useEffect(() => {
        setYear(setInitYearFounded());
    }, [values]);

    useEffect(() => {
        if (loadingProjID === false) {
            if (projectIDs == null || projectIDs.length === 0) {
                createProject();
            }
            else {
                setProjectID(projectIDs[0]);
            }

        }
    }, [projectIDs, loadingProjID])

    useEffect(() => {
        if (queueSubmit === true && creatingProj === false) {
            setQueueSubmit(false)
            if (validateAllFields() === false) {
                saveProjectInfo();
            }
        }
    }, [queueSubmit, creatingProj])

    useEffect(() => {
        if (queueSave === true && creatingProj === false) {
            setQueueSave(false)
            if (validateAllFields() === false) {
                updateProjectInfo();
            }
        }
    }, [queueSave, creatingProj])

    const createProject = () => {
        if (creatingProj === false && (projectID == null || projectID === "")) {

            setCreatingProj(true);
            createNewProject({
                uid: currentUser.uid,
            }).then((result) => {
                //console.log("Res: ", result);
                setProjectID(String(result.data))
            }).catch((error) => {
                console.error("Error:", error)
            }).finally(() => {
                //console.log("Completed")
                setCreatingProj(false);
            })
        }
    }

    useEffect(() => {
        if (saveTrigger !== '') {
            const delayDebounceFn = setTimeout(() => {
                saveProgress();
                setSaveTrigger('');
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTrigger]);

    function saveProgress() {
        if (creatingProj === false) {
            updateProjectInfo();
        }
        else {
            setQueueSave(true);
        }
    }

    const updateProjectInfo = () => {
        setIsLoading(true);
        const data = {
            projectID: projectID,
            projName: values.projName,
            existingComp: values.existingComp,
            updateData: true,
        }

        if (values.website != null && values.website !== "") {
            data['website'] = values.website
        }

        if (values.existingComp === companyChoices['yes_new'] || values.existingComp === companyChoices['yes_established']) {
            data['compForm'] = values.compForm
            data['orgNum'] = values.orgNum
            data['compName'] = values.compName
            data['compCity'] = values.compCity

            if (values.existingComp === companyChoices['yes_established']) {
                data['founded'] = values.founded;
                data['compRole'] = values.compRole;
                data['revenue'] = values.revenue;
            }
        }

        //console.log("Send: ", data)
        addProjectInfo(data).then(() => {
            setAutoSave(new Date(Date.now()).toLocaleString())
        }).catch((error) => {
            console.error("Error:", error)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const saveProjectInfo = () => {
        setIsLoading(true);
        const data = {
            projectID: projectID,
            projName: values.projName,
            existingComp: values.existingComp,
        }

        if (values.website != null && values.website !== "") {
            data['website'] = values.website
        }

        if (values.existingComp === companyChoices['yes_new'] || values.existingComp === companyChoices['yes_established']) {
            data['compForm'] = values.compForm
            data['orgNum'] = values.orgNum
            data['compName'] = values.compName
            data['compCity'] = values.compCity

            if (values.existingComp === companyChoices['yes_established']) {
                data['founded'] = values.founded;
                data['compRole'] = values.compRole;
                data['revenue'] = values.revenue;
            }
        }

        addProjectInfo(data).then(() => {
            logEvent(analytics, 'completed_signup_step', {
                step: 3,
                invited: false,
            })
            navigate('/set-up-account#ideas')
        }).catch((error) => {
            console.error("Error:", error)
            setErrorInfo(error)
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const validateAllFields = () => {
        let isError = false;

        if (values.projName != null && values.projName !== "") {
            setProjNameInvalid(false);
        }
        else {
            isError = true;
            setProjNameInvalid(true);
        }

        if (values.existingComp != null && values.existingComp !== "") {
            setExistingCompInvalid(false);
        }
        else {
            isError = true;
            setExistingCompInvalid(true);
        }

        if (values.existingComp === companyChoices['yes_new'] || values.existingComp === companyChoices['yes_established']) {
            if (values.compForm != null && values.compForm !== "") {
                setCompFormInvalid(false);
            }
            else {
                isError = true;
                setCompFormInvalid(true);
            }

            if (values.orgNum != null && values.orgNum !== "") {
                setOrgNumInvalid(false);
            }
            else {
                isError = true;
                setOrgNumInvalid(true);
            }

            if (values.compName != null && values.compName !== "") {
                setCompNameInvalid(false);
            }
            else {
                isError = true;
                setCompNameInvalid(true);
            }

            if (values.compCity != null && values.compCity !== "") {
                setCompCityInvalid(false);
                if (values.region != null && values.region !== "" && values.region.label !== '') {
                    setRegionInvalid(false);
                }
                else {
                    setRegionInvalid(true);
                    isError = true;
                }
                if (values.county != null && values.county !== "" && values.county.label !== '') {
                    setCountyInvalid(false);
                }
                else {
                    if (values.region?.label === "Skåne") {
                        setCountyInvalid(true);
                        isError = true;
                    }
                    else {
                        setCountyInvalid(false);
                    }
                }
            }
            else {
                isError = true;
                setCompCityInvalid(true);
            }

            if (values.existingComp === companyChoices['yes_established']) {
                if (values.founded != null && values.founded !== "") {
                    setYearFoundedInvalid(false);
                }
                else {
                    isError = true;
                    setYearFoundedInvalid(true);
                }

                if (values.compRole != null && values.compRole !== "") {
                    setCompRoleInvalid(false);
                }
                else {
                    isError = true;
                    setCompRoleInvalid(true);
                }

                if (values.revenue != null && values.revenue !== "") {
                    setRevenueInvalid(false);
                }
                else {
                    isError = true;
                    setRevenueInvalid(true);
                }
            }
        }
        setGeneralInvalid(isError);
        return isError;
    }

    const validateInput = (type) => {
        switch (type) {
            case 'projName':
                if (values.projName != null && values.projName !== "") {
                    setProjNameInvalid(false);
                }
                else {
                    setProjNameInvalid(true);
                }
                break;
            case 'existingComp':
                if (values.existingComp != null && values.existingComp !== "") {
                    setExistingCompInvalid(false);
                }
                else {
                    setExistingCompInvalid(true);
                }
                break;
            case "compName":
                if (values.compName != null && values.compName !== "") {
                    setCompNameInvalid(false);
                }
                else {
                    setCompNameInvalid(true);
                }
                break;
            case "orgNum":
                if (values.orgNum != null && values.orgNum !== "") {
                    setOrgNumInvalid(false);
                }
                else {
                    setOrgNumInvalid(true);
                }
                break;

            case "compCity":
                if (values.compCity != null && values.compCity !== "") {
                    setCompCityInvalid(false);
                    if (values.region != null && values.region !== "" && values.region.label !== '') {
                        setRegionInvalid(false);
                    }
                    else {
                        setRegionInvalid(true);
                    }
                    if (values.county != null && values.county !== "" && values.county.label !== '') {
                        setRegionInvalid(false);
                    }
                    else {
                        if (values.region?.label === "Skåne") {
                            setCountyInvalid(false);
                        }
                        else {
                            setCountyInvalid(true);
                        }
                    }
                }
                else {
                    setCompCityInvalid(true);
                }
                break;
            case "compRole":
                if (values.compRole != null && values.compRole !== "") {
                    setCompRoleInvalid(false);
                }
                else {
                    setCompRoleInvalid(true);
                }
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    const clearInputError = (type) => {
        switch (type) {
            case 'projName':
                setProjNameInvalid(false);
                break;
            case 'existingComp':
                setExistingCompInvalid(false);
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    const setErrorMessage = (type) => {
        switch (type) {
            case 'projName':
                if (isProjNameInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'existingComp':
                if (isExistingCompInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'compForm':
                if (isCompFormInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'compName':
                if (isCompNameInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'orgNum':
                if (isOrgNumInvalid) {
                    return "Organization number is required";
                }
                else {
                    return "";
                }
            case 'compCity':
                if (isCompCityInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'region':
                if (isRegionInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'county':
                if (isCountyInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'compRole':
                if (isCompRoleInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'revenue':
                if (isRevenueInvalid) {
                    return "Please choose one option"
                }
                else {
                    return ""
                }
            case 'general':
                if (isGeneralInvalid === true) {
                    return "Please fill the required fields."
                }
                else {
                    return ""
                }
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    const handleChangeLocal = (e) => {
        handleChange(e);
        clearInputError(e.target.name);
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };

    const handleChangeRegion = (value) => {
        //console.log(value);
        handleChange({ target: { name: "region", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };

    const handleChangeCounty = (value) => {
        //console.log(value);
        handleChange({ target: { name: "county", value: value } });
        setSaveTrigger(value);
        setUpdate(!update);
    };



    //this function is called when clicking the `Send` button
    const handleSubmit = e => {
        e.preventDefault()
        //console.log("Submit: ", values)
        if (validateAllFields() === false) {
            setIsLoading(true);
            if (creatingProj === true) {
                setQueueSubmit(true);
            }
            else {
                saveProjectInfo();
            }
        }
    };

    const onChangeYear = (value) => {
        setYear(value['$d']);
        handleChange({ target: { name: "founded", value: value['$d'].getFullYear() } });
    }

    /*
    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }*/

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <form onSubmit={handleSubmit} id="project-form">
                    <Grid container spacing={3} justifyContent="center" padding={3}>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                fullWidth
                                label="Project name*"
                                name="projName"
                                type={'text'}
                                onBlur={() => { validateInput('projName') }}
                                value={values.projName || ""}
                                onChange={handleChangeLocal}
                                error={isProjNameInvalid}
                                helperText={setErrorMessage('projName')}
                                margin="normal"
                            />

                            <TextField
                                fullWidth
                                label="Website (if applicable)"
                                name="website"
                                placeholder="i.e: www.youradress.com"
                                value={values.website || ""}
                                onChange={handleChangeLocal}
                                margin="normal"
                            />

                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl required>
                                <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: '10px' }}>Are you developing this idea witin an existing company?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="existingComp"
                                    value={values.existingComp}
                                    onChange={handleChangeLocal}
                                >
                                    <FormControlLabel value={companyChoices['no']} control={<Radio />} label={companyChoices['no']} />
                                    <FormControlLabel value={companyChoices['yes_new']} control={<Radio />} label={companyChoices['yes_new']} />
                                    <FormControlLabel value={companyChoices['yes_established']} control={<Radio />} label={companyChoices['yes_established']} />

                                </RadioGroup>
                                <FormHelperText error={isExistingCompInvalid}>{setErrorMessage('existingComp')}</FormHelperText>
                            </FormControl>
                        </Grid>


                        {(values.existingComp === companyChoices['yes_new'] || values.existingComp === companyChoices['yes_established']) &&
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl required>
                                        <FormLabel id="demo-controlled-radio-buttons-group" error={isCompFormInvalid}>Company form</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="compForm"
                                            value={values.compForm}
                                            onChange={handleChangeLocal}
                                        >
                                            <FormControlLabel value="Enskild firma (or equivalent)" control={<Radio />} label="Enskild firma (or equivalent)" />
                                            <FormControlLabel value="Handelsbolag/Kommanditbolag (or equivalent)" control={<Radio />} label="Handelsbolag / Kommanditbolag (or equivalent)" />
                                            <FormControlLabel value="Aktiebolag (or equivalent)" control={<Radio />} label="Aktiebolag (or equivalent)" />

                                        </RadioGroup>
                                        <FormHelperText error={isCompFormInvalid}>{setErrorMessage('compForm')}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Company Name"
                                        name="compName"
                                        placeholder="Enter your company name"

                                        value={values.compName || ""}
                                        onChange={handleChangeLocal}
                                        onBlur={() => { validateInput('compName') }}
                                        error={isCompNameInvalid}
                                        helperText={setErrorMessage('compName')}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Organization number"
                                        name="orgNum"
                                        placeholder="Enter number"
                                        value={values.orgNum || ""}
                                        onChange={handleChangeLocal}
                                        onBlur={() => { validateInput('orgNum') }}
                                        error={isOrgNumInvalid}
                                        helperText={setErrorMessage('orgNum')}
                                        margin="normal"
                                    />
                                </Grid>

                                {/* TODO: Gör om fält till ny specifikation 
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="City (headquarter if multiple)"
                                        name="compCity"
                                        placeholder="Enter city"
                                        value={values.compCity || ""}
                                        onChange={handleChangeLocal}
                                        onBlur={() => { validateInput('compCity') }}
                                        error={isCompCityInvalid}
                                        helperText={setErrorMessage('compCity')}
                                        margin="normal"
                                    />
                                </Grid>*/}

                                <Grid item xs={12} sm={12} md={6}>
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        value={values.region}
                                        options={REGION_LIST}
                                        onChange={(event, newValue) => {
                                            handleChangeRegion(newValue);
                                        }}
                                        fullWidth
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label="Region (headquarter if multiple)"
                                                error={isRegionInvalid}
                                                helperText={setErrorMessage('region')}
                                                margin="normal" />
                                        }
                                    />

                                    {values.region?.label === "Skåne" ? (
                                        <Autocomplete
                                            disablePortal
                                            autoComplete
                                            value={values.county}
                                            options={COUNTY_LIST}
                                            onChange={(event, newValue) => {
                                                handleChangeCounty(newValue);
                                            }}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="County" error={isCountyInvalid} helperText={setErrorMessage('county')} margin="normal" />}
                                        />) : ("")}
                                </Grid>
                            </>
                        }

                        {values.existingComp === companyChoices['yes_established'] &&
                            <>
                                <Grid item xs={12} sm={12} md={6}>
                                    <DesktopDatePicker
                                        views={['year']}
                                        label="Year founded"
                                        mask="____"
                                        value={year}
                                        onChange={onChangeYear}
                                        renderInput={(params) => <TextField {...params} helperText={null} margin="normal" fullWidth error={isYearFoundedInvalid} />}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Your role in the company"
                                        name="compRole"
                                        placeholder="Answer"
                                        value={values.compRole || ""}
                                        margin="normal"
                                        onChange={handleChangeLocal}
                                        onBlur={() => { validateInput('compRole') }}
                                        error={isCompRoleInvalid}
                                        helperText={setErrorMessage('compRole')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormControl required>
                                        <FormLabel id="demo-controlled-radio-buttons-group" error={isRevenueInvalid}>Revenue (latest full fiscal year)</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="revenue"
                                            value={values.revenue}
                                            onChange={handleChangeLocal}
                                        >
                                            <FormControlLabel value="&lt;500k SEK" control={<Radio />} label="&lt;500k SEK" />
                                            <FormControlLabel value="501k - 2m SEK" control={<Radio />} label="501k - 2m SEK" />
                                            <FormControlLabel value="2-10m SEK" control={<Radio />} label="2-10m SEK" />
                                            <FormControlLabel value="&gt;10m SEK" control={<Radio />} label="&gt;10m SEK" />

                                        </RadioGroup>
                                        <FormHelperText error={isRevenueInvalid}>{setErrorMessage('revenue')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Divider />
                    <div className="backConfirm">
                        <FormHelperText error={isGeneralInvalid}>{setErrorMessage('general')}</FormHelperText>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {isLoading ? (
                            <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                                <LinearProgress color="info" />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "4px" }}>

                            </Box>
                        )}
                    </div>
                    <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={saveProgress}
                            disabled={isLoading}
                        >
                            Save
                        </Button>

                        <Button
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                        >
                            Save & Continue
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                        <i>{autoSave !== '' ? ('Last save: ' + autoSave) : ('')}</i>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                        <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                    </div>
                </form>
            </LocalizationProvider>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Fragment>

    );
}

export default CreateNewProject;

