import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { db } from '../../firebase'
import { useAuth } from "../../contexts/AuthContext"
import { useLocation, useNavigate } from 'react-router-dom'
import firebaseRefList from '../../firebase/firebaseRefList'


//import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { ReactComponent as EntryGray } from '../../assets/images/stepper/Entry_gray.svg';
import { ReactComponent as EntryGrayGreenOutline } from '../../assets/images/stepper/Entry_grayGreenOutline.svg';
//import { ReactComponent as EntryOrangeGreenOutline } from '../../assets/images/stepper/Entry_orangeGreenOutline.svg';
import { ReactComponent as EntrySolidGreen } from '../../assets/images/stepper/Entry_solidGreen.svg';
import { ReactComponent as BallGray } from '../../assets/images/stepper/Ball_gray.svg';
import { ReactComponent as BallOrangeGreenOutline } from '../../assets/images/stepper/Ball_orangeGreenOutline.svg';
import { ReactComponent as BallSolidGreen } from '../../assets/images/stepper/Ball_solidGreen.svg';
import { ReactComponent as BallGrayGreenOutline } from '../../assets/images/stepper/Ball_grayGreenOutline.svg';
import { ReactComponent as LinkGrayGreenOutline } from '../../assets/images/stepper/Link_grayGreenOutline.svg';
import { ReactComponent as LinkOrangeGreenOutline } from '../../assets/images/stepper/Link_orangeGreenOutline.svg';
import { ReactComponent as LinkSolidGreen } from '../../assets/images/stepper/Link_solidGreen.svg';
import { ReactComponent as LinkGray } from '../../assets/images/stepper/Link_gray.svg';
import { ReactComponent as ExitGray } from '../../assets/images/stepper/Exit_gray.svg';
import { ReactComponent as ExitGrayGreenOutline } from '../../assets/images/stepper/Exit_grayGreenOutline.svg';
import { ReactComponent as ExitOrangeGreenOutline } from '../../assets/images/stepper/Exit_orangeGreenOutline.svg';
import { ReactComponent as ExitSolidGreen } from '../../assets/images/stepper/Exit_solidGreen.svg';
import { collection, onSnapshot } from 'firebase/firestore';
import { LinkStep } from './StepperComponents/LinkStep';
import { EntryStep } from './StepperComponents/EntryStep';
import { ExitStep } from './StepperComponents/ExitStep';
import { MeetStep } from './StepperComponents/MeetStep';


const knownSteps = {
    a: null,
    b: null,
    c: null,
    d: null,
    e: null,
    f: null,
    meet1: null,
    meet2: null,
    meet3: null,
    meet4: null,
    meet5: null,
}

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {<span>{children}</span>}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function IBOStepperGraphic(props) {
    //const [usernames] = useGetUserNames();
    const [step, setStep] = useState([])
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const location = useLocation()
    const selectedTab = location.hash;
    const [unlockedSteps, setUnlockedSteps] = useState({});
    const [value, setValue] = useState(getHashValueInit(selectedTab));
    getHashValue(selectedTab);


    //console.log("Props: ", props)
    //console.log("Hash: ", selectedTab)

    //Reading the initial value from the hash-value
    function getHashValueInit(hashVal) {
        //console.log(unlockedSteps);
        switch (hashVal) {
            case '#step1':
                if (unlockedSteps[1] === true) {
                    return 1;
                }
                else {
                    return 0;
                }
            case '#step2':
                if (unlockedSteps[2] === true) {
                    return 2;
                }
                else {
                    return 0;
                }
            case '#step3':
                if (unlockedSteps[3] === true) {
                    return 3;
                }
                else {
                    return 0;
                }
            case '#step4':
                if (unlockedSteps[4] === true) {
                    return 4;
                }
                else {
                    return 0;
                }
            case '#step5':
                if (unlockedSteps[5] === true) {
                    return 5;
                }
                else {
                    return 0;
                }
            case '#step6':
                if (unlockedSteps[6] === true) {
                    return 6;
                }
                else {
                    return 0;
                }
            case '#step7':
                if (unlockedSteps[7] === true) {
                    return 7;
                }
                else {
                    return 0;
                }
            case '#step8':
                if (unlockedSteps[8] === true) {
                    return 8;
                }
                else {
                    return 0;
                }
            case '#step9':
                if (unlockedSteps[9] === true) {
                    return 9;
                }
                else {
                    return 0;
                }
            case '#step10':
                if (unlockedSteps[10] === true) {
                    return 10;
                }
                else {
                    return 0;
                }
            case '#step11':
                if (unlockedSteps[11] === true) {
                    return 11;
                }
                else {
                    return 0;
                }
            case '#step12':
                if (unlockedSteps[12] === true) {
                    return 12;
                }
                else {
                    return 0;
                }
            default:
                //console.log("Default")
                return 0;
        }
    }

    function getHashValue(hashVal) {
        switch (hashVal) {
            case '#step1':
                if (value !== 1 && unlockedSteps[1] === true) {
                    setValue(1);
                }
                break;
            case '#step2':
                if (value !== 2 && unlockedSteps[2] === true) {
                    setValue(2);
                }
                break;
            case '#step3':
                if (value !== 3 && unlockedSteps[3] === true) {
                    setValue(3);
                }
                break;
            case '#step4':
                if (value !== 4 && unlockedSteps[4] === true) {
                    setValue(4);
                }
                break;
            case '#step5':
                if (value !== 5 && unlockedSteps[5] === true) {
                    setValue(5);
                }
                break;
            case '#step6':
                if (value !== 6 && unlockedSteps[6] === true) {
                    setValue(6);
                }
                break;
            case '#step7':
                if (value !== 7 && unlockedSteps[7] === true) {
                    setValue(7);
                }
                break;
            case '#step8':
                if (value !== 8 && unlockedSteps[8] === true) {
                    setValue(8);
                }
                break;
            case '#step9':
                if (value !== 9 && unlockedSteps[9] === true) {
                    setValue(9);
                }
                break;
            case '#step10':
                if (value !== 10 && unlockedSteps[10] === true) {
                    setValue(10);
                }
                break;
            case '#step11':
                if (value !== 11 && unlockedSteps[11] === true) {
                    setValue(11);
                }
                break;
            case '#step12':
                if (value !== 12 && unlockedSteps[12] === true) {
                    setValue(12);
                }
                break;
            default:
                //console.log("Default")
                if (value !== 0) {
                    setValue(0);
                }
                break;
        }
    }

    useEffect(() => {
        if (!props.data) return;

        const ref = onSnapshot(collection(db, "Projects/" + props.data.id + "/Steps"), (snapshot) => {
            snapshot.docs.forEach((doc) => {
                knownSteps[doc.id] = doc.data();
                //console.log(doc.data())
                const copystep = [...step]
                setStep([...copystep, doc])
            })

            setLoading(false);
        });

        firebaseRefList.push(ref);

    }, [loading, props.data])




    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // function CheckStep(stepName) {

    //     step.forEach((step) => {
    //         if (step.data().id === stepName) {
    //             setStepVisuals(step, stepName);
    //             return;
    //         }
    //     })
    //     setStepVisuals(null, stepName);
    // }

    function onClickStep(href) {
        navigate(href, { state: { projectData: props.data } })
    }

    function setStepVisuals(step, type, order) {
        let currentTime = new Date(Date.now());
        let stepLabel = null;
        let disabled = false;

        if (step === null) {
            switch (type) {
                case "Meeting":
                    stepLabel = <BallGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Link":
                    stepLabel = <LinkGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Init":
                    stepLabel = <EntryGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Exit":
                    stepLabel = <ExitGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                default:
                    return;
            }
        } else if (step.type === "Meeting") {
            let late = false;
            // eslint-disable-next-line eqeqeq
            if (step.nextTime != undefined) {
                let deadline = new Date(step.nextTime.seconds * 1000);

                if (currentTime.getTime() > deadline.getTime()) {
                    late = true;
                }
                //console.log("Time: " + deadline.toString() + ", Now: " + currentTime.toString() + "Late: " + late);
            }

            switch (step.meetState) {
                case "Not Booked":
                    stepLabel = <BallGray className="stepper-graphics" />;
                    if (currentUser.role < 2) {
                        disabled = true;
                    }
                    break;
                case "Booked":
                    if (late) {
                        stepLabel = <BallOrangeGreenOutline className="stepper-graphics" />;
                    }
                    else {
                        stepLabel = <BallGrayGreenOutline className="stepper-graphics" />;
                    }
                    break;
                case "Completed":
                    stepLabel = <BallSolidGreen className="stepper-graphics" />;
                    break;
                default:
                    stepLabel = <BallGray className="stepper-graphics" />;
                    disabled = true;
                    break;
            }
        } else if (step.type === "Init") {
            switch (step.BDState) {
                case "Locked":
                    stepLabel = <EntryGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Unlocked":
                    if (step.IBOState === "Not Completed") {
                        stepLabel = <EntryGrayGreenOutline className="stepper-graphics" />;
                    } else if (step.IBOState === "Completed") {
                        stepLabel = <EntrySolidGreen className="stepper-graphics" />;
                    }
                    break;
                case "Passed":
                    stepLabel = <EntrySolidGreen className="stepper-graphics" />;
                    break;
                default:
                    stepLabel = <EntryGray className="stepper-graphics" />;
                    disabled = true;
                    break;
            }
        } else if (step.type === "Link") {
            let late = false;
            // eslint-disable-next-line eqeqeq
            if (step.nextTime != undefined) {
                let deadline = new Date(step.nextTime.seconds * 1000);

                if (currentTime.getTime() > deadline.getTime()) {
                    late = true;
                }
                //console.log("Time: " + deadline.toString() + ", Now: " + currentTime.toString() + "Late: " + late);
            }

            switch (step.BDState) {
                case "Locked":
                    stepLabel = <LinkGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Unlocked":
                    if (step.IBOState === "Not Completed") {
                        if (late) {
                            stepLabel = <LinkOrangeGreenOutline className="stepper-graphics" />;
                        }
                        else {
                            stepLabel = <LinkGrayGreenOutline className="stepper-graphics" />;
                        }
                        break;
                    } else if (step.IBOState === "Completed") {
                        stepLabel = <LinkOrangeGreenOutline className="stepper-graphics" />;
                        break;
                    }
                    break;
                case "Passed":
                    stepLabel = <LinkSolidGreen className="stepper-graphics" />;
                    break;
                default:
                    stepLabel = <LinkGray className="stepper-graphics" />;
                    disabled = true;
                    break;
            }
        } else if (step.type === "Exit") {
            switch (step.BDState) {
                case "Locked":
                    stepLabel = <ExitGray className="stepper-graphics" />;
                    disabled = true;
                    break;
                case "Unlocked":
                    if (step.IBOState === "Not Completed") {
                        stepLabel = <ExitGrayGreenOutline className="stepper-graphics" />;
                    } else if (step.IBOState === "Completed") {
                        stepLabel = <ExitOrangeGreenOutline className="stepper-graphics" />;
                    }
                    break;
                case "Passed":
                    stepLabel = <ExitSolidGreen className="stepper-graphics" />;
                    break;
                default:
                    stepLabel = <ExitGray className="stepper-graphics" />;
                    disabled = true;
                    break;
            }
        }

        let localUnlockedSteps = unlockedSteps;
        if (disabled) {
            localUnlockedSteps[order] = false;
            //setUnlockedSteps(localUnlockedSteps);
            return <Tab className="stepper-tab" label={stepLabel} {...a11yProps(order)} onClick={() => onClickStep(window.location.pathname + "#step" + order)} disabled />
        }
        else {
            localUnlockedSteps[order] = true;
            //setUnlockedSteps(localUnlockedSteps);
            return <Tab className="stepper-tab" label={stepLabel} {...a11yProps(order)} onClick={() => onClickStep(window.location.pathname + "#step" + order)} />
        }
    }

    /*
    for (const key in knownSteps) {
        if (Object.hasOwnProperty.call(knownSteps, key)) {
            const element = knownSteps[key];

            if (element != null) {
                //console.log("Step: " + key + ", val: " + element.type)
            }
            else {
                //onsole.log("Step: " + key + ", val: " + element)
            }

        }
    }*/
    // step.forEach((obj) => {
    //     console.log('step: ' + obj.id + ', data: ' + obj.data().type)
    // })


    return (
        <Box className="tab-stepper" sx={{ width: '100%', height: '100%' }}>
            <Box sx={{
                width: '100%', padding: '10px'
            }}>
                <Tabs value={value} onChange={handleChange} aria-label="stepper ibo home" TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }}>

                    {setStepVisuals(knownSteps['a'], "Init", 1)}
                    {setStepVisuals(knownSteps['meet1'], "Meeting", 2)}
                    {setStepVisuals(knownSteps['b'], "Link", 3)}
                    {setStepVisuals(knownSteps['meet2'], "Meeting", 4)}
                    {setStepVisuals(knownSteps['c'], "Link", 5)}
                    {setStepVisuals(knownSteps['meet3'], "Meeting", 6)}
                    {setStepVisuals(knownSteps['d'], "Link", 7)}
                    {setStepVisuals(knownSteps['meet4'], "Meeting", 8)}
                    {setStepVisuals(knownSteps['e'], "Link", 9)}
                    {setStepVisuals(knownSteps['meet5'], "Meeting", 10)}
                    {setStepVisuals(knownSteps['f'], "Exit", 11)}
                </Tabs>
            </Box>
            {/* <TabPanel value={value} index={0}>
                <HomeIbo projectData={props.data} />
            </TabPanel> */}
            <TabPanel value={value} index={1}>
                <EntryStep projectData={props.data} step="a" />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MeetStep projectData={props.data} step="meet1" location="First Meet" />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <LinkStep projectData={props.data} step="b" />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <MeetStep projectData={props.data} step="meet2" location="Checkpoint 1" />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <LinkStep projectData={props.data} step="c" />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <MeetStep projectData={props.data} step="meet3" location="Checkpoint 2" />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <LinkStep projectData={props.data} step="d" />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <MeetStep projectData={props.data} step="meet4" location="Checkpoint 3" />
            </TabPanel>
            <TabPanel value={value} index={9}>
                <LinkStep projectData={props.data} step="e" />
            </TabPanel>
            <TabPanel value={value} index={10}>
                <MeetStep projectData={props.data} step="meet5" location="Checkpoint 4" />
            </TabPanel>
            <TabPanel value={value} index={11}>
                <ExitStep projectData={props.data} step="f" />
            </TabPanel>

        </Box>
    );
}