import { Box, Button, IconButton, Modal } from "@mui/material";
import { Fragment, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useGetIntroParagraphs } from "../../../hooks/languageHooks/useGetIntroParagraphs"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

export const IntroductionComponent = () => {
    const { currentUser } = useAuth();

    const [openInfo, setOpenInfo] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    const handleOpenInfo = () => setOpenInfo(true);
    const handleCloseInfo = () => setOpenInfo(false);
    const handleOpenVideo = () => setOpenVideo(true);
    const handleCloseVideo = () => setOpenVideo(false);

    //const paragraphs = useGetIntroParagraphs(currentUser?.userInfo?.prefLang || 'eng', handleOpenInfo, handleOpenVideo);
    const paragraphs = useGetIntroParagraphs(handleOpenInfo, handleOpenVideo);

    return (
        <Fragment>
            <Box>
                {Object.keys(paragraphs).map((key) => {
                    return paragraphs[key];
                })}
            </Box>

            <Modal
                open={openInfo}
                onClose={handleCloseInfo}

            >
                <Box sx={modalStyle}>
                    <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={handleCloseInfo}>
                        <CloseRoundedIcon />
                    </IconButton>
                    {currentUser?.userInfo?.prefLang === 'swe' ? (
                        <span>Här kommer det instruktioner!</span>
                    ) : (
                        <span>Here be instructions!</span>
                    )}
                </Box>
            </Modal>

            <Modal
                open={openVideo}
                onClose={handleCloseVideo}

            >
                <Box className="videomodal-landing">
                    <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-25px', marginRight: '-25px', marginBottom: '5px' }} onClick={handleCloseVideo}>
                        <CloseRoundedIcon /></IconButton>
                    <iframe className="responsive-iframe" src="https://www.youtube.com/embed/ub62-2m1s1o" title="YouTube video player" frameBorder="0" height="auto" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"></iframe>
                    <br></br>

                    <iframe className="responsive-iframe" src="https://www.youtube.com/embed/7L1-0DOGHDY" title="YouTube video player" frameBorder="0" height="auto" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"></iframe>
                </Box>
            </Modal>
        </Fragment>


    )
}