import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { entryStepQuestions } from '../../firebase/cloudFunctions';
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GLOBAL_GOALS_FIELDS } from "../../constants/lang/eng/fields";
import { ErrorAlert } from "../../helper/errorAlert";

//const entryStepQuestions = firebase.app().functions("europe-west1").httpsCallable('entryStepQuestions');

// Destructuring props
const UpdateIdeas = ({ projectID, ideasData, closeModal }) => {
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [oldIdeas] = useState(setData('ideas'))
    const [oldUnique] = useState(setData('unique'))
    const [oldAlready] = useState(setData('already'));
    const [oldCompetitors] = useState(setData('competitors'));
    const [oldCustomers] = useState(setData('customers'));
    const [oldDemand] = useState(setData('demand'));
    const [oldGlobalGoals] = useState(setData('globalGoals'));
    const [oldMotivate] = useState(setData('motivate'));

    const [ideas, setIdeas] = useState(setData('ideas'))
    const [unique, setUnique] = useState(setData('unique'))
    const [already, setAlready] = useState(setData('already'));
    const [competitors, setCompetitors] = useState(setData('competitors'));
    const [customers, setCustomers] = useState(setData('customers'));
    const [demand, setDemand] = useState(setData('demand'));
    const [globalGoalsString, setGlobalGoalsString] = useState(setData('globalGoals'));
    const [globalGoals, setGlobalGoals] = useState(setData('globalGoalsObj'));
    const [motivate, setMotivate] = useState(setData('motivate'));


    function dataUpdated() {
        if (oldIdeas === ideas &&
            oldUnique === unique &&
            oldAlready === already &&
            oldCompetitors === competitors &&
            oldCustomers === customers &&
            oldDemand === demand &&
            oldGlobalGoals === globalGoalsString &&
            oldMotivate === motivate) {
            return true;
        }
        else {
            return false;
        }
    }

    function setData(type) {
        if (ideasData != null) {
            let ideaObject = ideasData.find(obj => obj.id === type);
            console.log("Data: ", type, ideaObject)
            if (type === 'globalGoalsObj') {
                ideaObject = ideasData.find(obj => obj.id === 'globalGoals');
                const split = String(ideaObject.answerData?.answer).split(';');
                const localGlobalGoals = {};
                Object.keys(GLOBAL_GOALS_FIELDS).forEach((goal) => {
                    console.log("Goals: ", GLOBAL_GOALS_FIELDS[goal])
                    if (split.indexOf(GLOBAL_GOALS_FIELDS[goal]) === -1) {
                        localGlobalGoals[goal] = false;
                    }
                    else {
                        localGlobalGoals[goal] = true;
                    }
                });
                return localGlobalGoals;
            }
            else {
                //console.log("Data: ", ideasData[type])
                if (ideaObject != null) {
                    return ideaObject.answerData?.answer;
                }
                else {
                    return '';
                }
            }
        }
        else {
            return '';
        }
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const handleChange = (event) => {
        const value = event.target.value;

        switch (event.target.name) {
            case "ideas":
                setIdeas(value);
                break;
            case "unique":
                setUnique(value);
                break;
            case "already":
                setAlready(value);
                break;
            case "competitors":
                setCompetitors(value);
                break;
            case "customers":
                setCustomers(value);
                break;
            case "demand":
                setDemand(value);
                break;
            case "globalGoals":
                const key = getKeyByValue(GLOBAL_GOALS_FIELDS, value);

                globalGoals[key] = !globalGoals[key];
                let goalsString = "";
                Object.keys(globalGoals).forEach((goal) => {
                    if (globalGoals[goal] === true) {
                        goalsString += GLOBAL_GOALS_FIELDS[goal] + ";"
                    }
                });

                goalsString = goalsString.slice(0, goalsString.length - 1);
                setGlobalGoalsString(goalsString);
                break;
            case "motivate":
                setMotivate(value);
                break;
            default:
                break;
        }
    }

    function collectData() {
        const dataToSend = [
            { title: "ideas", answer: ideas },
            { title: "unique", answer: unique },
            { title: "already", answer: already },
            { title: "competitors", answer: competitors },
            { title: "customers", answer: customers },
            { title: "demand", answer: demand },
            { title: "motivate", answer: motivate },
            { title: "globalGoals", answer: globalGoalsString },
        ];

        return dataToSend;
    }

    const handleSubmit = e => {
        e.preventDefault();

        setIsLoading(true);
        console.log(projectID);

        entryStepQuestions({
            projectID: projectID,
            entryQuestions: collectData(),
            updateData: true,
        }).then(() => {
            closeModal();
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })

    };



    return (
        <Box className="update-ideas">
            <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                <CloseRoundedIcon /></IconButton>
            <div style={{ marginBottom: '20px' }}>
                <span><strong>Update business idea:</strong></span>
            </div>
            <Fragment>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>1. What need does your business idea meet? How did you discover that need?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="ideas"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={ideas}
                                />
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>2. Describe your service / product / idea?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="already"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={already}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel className="ideas-input-update">3. In what way is your service / product / idea better than those on the market today?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="unique"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={unique}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" className="ideas-input-update">
                                <FormLabel>4. In what way does your project contribute to the sustainable development of society?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="motivate"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={motivate}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FormControl component="fieldset" className="ideas-input-update">
                                <FormLabel component="legend">4b. Which of the Global goals for sustainable development does your idea contribute to, if any?</FormLabel>
                                <FormGroup aria-label="position" id="reference">
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"1 - No poverty"}
                                            checked={globalGoals["poverty"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="1 - No poverty"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"2 - Zero hunger"}
                                            checked={globalGoals["hunger"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="2 - Zero hunger"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"3 - Good health and well-being"}
                                            checked={globalGoals["goodHealth"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="3 - Good health and well-being"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"4 - Quality education"}
                                            checked={globalGoals["education"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="4 - Quality education"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"5 - Gender equality"}
                                            checked={globalGoals["equality"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="5 - Gender equality"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"6 - Clean water and sanitation"}
                                            checked={globalGoals["sanitation"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="6 - Clean water and sanitation"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"7 - Affordable and clean energy"}
                                            checked={globalGoals["energy"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="7 - Affordable and clean energy"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"8 - Decent work and economic growth"}
                                            checked={globalGoals["economicGrowth"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"

                                        />}
                                        label="8 - Decent work and economic growth"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"9 - Industry, innovation and infrastructure"}
                                            checked={globalGoals["industry"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="9 - Industry, innovation and infrastructure"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"10 - Reduced inequalities"}
                                            checked={globalGoals["inequalities"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="10 - Reduced inequalities"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"11 - Sustainable cities and communities"}
                                            checked={globalGoals["cities"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="11 - Sustainable cities and communities"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"12 - Responsible consumption and production"}
                                            checked={globalGoals["consumption"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="12 - Responsible consumption and production"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"13 - Climate action"}
                                            checked={globalGoals["climate"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="13 - Climate action"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"14 - Life below water"}
                                            checked={globalGoals["lifeWater"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="14 - Life below water"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"15 - Life on land"}
                                            checked={globalGoals["lifeLand"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="15 - Life on land"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"16 - Peace, justice and strong institutions"}
                                            checked={globalGoals["peace"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="16 - Peace, justice and strong institutions"

                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            value={"17 - Partnerships for the goals"}
                                            checked={globalGoals["partnerships"]}
                                            onChange={handleChange}
                                            name="globalGoals"
                                            //name="checked"
                                            color="primary"
                                        />}
                                        label="17 - Partnerships for the goals"

                                    />

                                </FormGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel style={{ maxWidth: '800px' }}>5. Describe the target group for your product / service, who is your customer?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="customers"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={customers}

                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel style={{ maxWidth: '800px' }}>6. Have you tested your business idea?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="demand"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={demand}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel style={{ maxWidth: '800px' }}>7. If you launched your product / service today, who would be your three biggest competitors?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="competitors"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={competitors}

                                />
                            </FormControl>
                        </Grid>

                    </Grid>


                    <div className="backConfirm" style={{ paddingTop: '10px' }}>
                        <LoadingButton
                            sx={{ width: '170px' }}
                            onClick={handleSubmit}
                            loading={isLoading}
                            loadingPosition="start"
                            variant="contained"
                            disabled={dataUpdated()}
                        >
                            Update Info
                        </LoadingButton>
                    </div>
                </form>

            </Fragment>
            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
};

export default UpdateIdeas;