import { Box, CircularProgress, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOwnProjectEntryQuestions } from "../../hooks/projectData/useGetProjectEntryQuestions";
import useGetUserData from "../../hooks/userData/useGetUserData";
import useGetUserProject from "../../hooks/userData/useGetUserProject";
import ConfirmUserInfo from "./Steps/ConfirmUserInfo";
import CreateNewProject from "./Steps/CreateNewProject";
import FirstMeetDatePicker from "./Steps/FirstMeetDate";
import IdeasStep from "./Steps/IdeasStep";
import UserInfo from "./Steps/UserInfo";


export const OnBoarding = () => {
    const labels = ["User Info", "Confirm", "Project Info", "Ideas", "Choose a date"];

    const [userValues, setUserValues] = useState({
        language: "English",
        phone: "",
        gender: "",
        reference: "",
        date: "",
        education: "",
        hkrConnection: "",
        termsChecked: false,
        referenceList: {},
    });

    const [projectValues, setProjectValues] = useState({
        founded: "",
        projName: "",
        compName: "",
        website: "",
        revenue: "",
        compCity: "",
        region: "",
        county: "",
        orgNum: "",
        compRole: "",
        compForm: "",
        existingComp: "",
    });

    const [ideasValues, setIdeasValues] = useState({
        globalGoalsList: [],
        ideas: "",
        unique: "",
        already: "",
        competitors: "",
        customers: "",
        demand: "",
        challenge: "",
        aloneORteam: "",
        competences: "",
        timeANDresources: "",
        motivate: "",
        globalGoals: "",
        nextStep: "",
    });


    const [loadingPage, setLoadingPage] = useState(true);
    const { userData, loading } = useGetUserData();
    const { project, loading: loadProject } = useGetUserProject();
    const { questionList, isLoading: loadQuestions } = useGetOwnProjectEntryQuestions();
    const [update, setUpdate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loading === false) {
            if (userData != null && Object.keys(userData).length > 0) {
                setLoadingPage(true);

                loadUserData(userData);

                if (loadProject === false) {
                    // @ts-ignore
                    if (project != null && project.data != null && Object.keys(project.data).length > 0) {
                        //console.log("Project: ", project)
                        loadProjectData(project);
                    }
                }

                if (loadQuestions === false) {
                    //console.log("Questions: ", questionList)
                    loadIdeasData(questionList);
                }

                setStepFromDB(userData.dataSet);
            }



        }

    }, [loading, loadProject, loadQuestions, userData, project, questionList])

    useEffect(() => {
        setUpdate(!update);
    }, [userValues])


    const finishLoading = () => {
        setTimeout(() => {
            setLoadingPage(false);
        }, 500);
    }

    const checkTerms = (checked) => {
        const localForms = userValues;
        localForms['termsChecked'] = checked;
        setUserValues(localForms);
    }

    const loadUserData = (userData) => {
        let dbValues = {
            language: "English",
            phone: "",
            gender: "",
            reference: "",
            date: "",
            education: "",
            hkrConnection: "",
            termsChecked: false,
            referenceList: {},
        };

        // @ts-ignore
        if (userData.phoneNr != null) {
            // @ts-ignore
            dbValues['phone'] = userData.phoneNr;
        }

        // @ts-ignore
        if (userData.city != null) {
            // @ts-ignore
            dbValues['city'] = userData.city;
        }

        // @ts-ignore
        if (userData.gender != null) {
            // @ts-ignore
            dbValues['gender'] = userData.gender;
        }

        // @ts-ignore
        if (userData.prefLang != null) {
            // @ts-ignore
            if (userData.prefLang === 'eng') {
                dbValues['language'] = "English";
            }
            // @ts-ignore
            else if (userData.prefLang === 'swe') {
                dbValues['language'] = "Svenska";
            }
        }

        // @ts-ignore
        if (userData.dateOfBirth != null) {
            // @ts-ignore
            dbValues['date'] = userData.dateOfBirth;
        }

        // @ts-ignore
        if (userData.education != null) {
            // @ts-ignore
            dbValues['education'] = userData.education;
        }

        // @ts-ignore
        if (userData.hkrConnection != null) {
            // @ts-ignore
            dbValues['hkrConnection'] = userData.hkrConnection;
        }

        // @ts-ignore
        if (userData.reference != null) {
            // @ts-ignore
            dbValues['reference'] = userData.reference;

            let refList = {};
            // @ts-ignore
            String(userData.reference).split(';').forEach((value) => {
                refList[value] = true;
            });

            dbValues['referenceList'] = refList;
        }

        // @ts-ignore
        if (userData.expectation != null) {
            // @ts-ignore
            dbValues['expectations'] = userData.expectation;
        }

        if (userData.acceptTerms != null){
            dbValues['termsChecked'] = userData.acceptTerms;
        }
        //console.log("Valueus:", dbValues)
        setUserValues(dbValues);
    }

    const loadProjectData = (projData) => {
        let dbValues = {
            founded: "",
            projName: "",
            compName: "",
            website: "",
            revenue: "",
            compCity: "",
            region: "",
            county: "",
            orgNum: "",
            compRole: "",
            compForm: "",
            existingComp: "",
        };

        if (projData.data?.projectName != null) {
            dbValues['projName'] = projData.data?.projectName;
        }

        if (projData.data?.website != null) {
            dbValues['website'] = projData.data?.website;
        }

        if (projData.data?.existingComp != null) {
            dbValues['existingComp'] = projData.data?.existingComp;
        }

        if (projData.compData?.founded != null) {
            dbValues['founded'] = projData.compData?.founded;
        }

        if (projData.compData?.compCity != null) {
            dbValues['compCity'] = projData.compData?.compCity;
            if (String(projData.compData?.compCity).includes(';') === true) {
                let cityData = String(projData.compData?.compCity).split(';');
                if (cityData[0] != null && cityData[0] !== "") {
                    dbValues['county'] = { label: cityData[0] };
                }
                if (cityData[1] != null && cityData[1] !== "") {
                    dbValues['region'] = { label: cityData[1] };
                }
                
            }
        }

        if (projData.compData?.compForm != null) {
            dbValues['compForm'] = projData.compData?.compForm;
        }

        if (projData.compData?.compName != null) {
            dbValues['compName'] = projData.compData?.compName;
        }

        if (projData.compData?.orgNum != null) {
            dbValues['orgNum'] = projData.compData?.orgNum;
        }

        if (projData.compData?.compRole != null) {
            dbValues['compRole'] = projData.compData?.compRole;
        }

        if (projData.compData?.revenue != null) {
            dbValues['revenue'] = projData.compData?.revenue;
        }

        setProjectValues(dbValues);
    }

    const loadIdeasData = (questionList) => {
        let dbValues = {
            globalGoalsList: [],
            ideas: "",
            unique: "",
            already: "",
            competitors: "",
            customers: "",
            demand: "",
            challenge: "",
            aloneORteam: "",
            competences: "",
            timeANDresources: "",
            motivate: "",
            globalGoals: "",
            nextStep: "",
        };

        questionList.forEach(element => {
            if (element?.id === "globalGoals") {
                const goalList = String(element?.answerData?.answer).split(';');
                dbValues['globalGoalsList'] = goalList;
            }

            dbValues[element?.id] = element?.answerData?.answer;
        });

        setIdeasValues(dbValues);
    }

    const selectedTab = location.hash;

    const setStepFromDB = (step) => {
        if (selectedTab === "" && step != null && step !== "") {

            if (step === 0) {
                navigate('/set-up-account#user')
                finishLoading();
            }
            else if (step === 1) {
                navigate('/set-up-account#user')
                navigate('/set-up-account#confirm')
                navigate('/set-up-account#project')
                finishLoading();
            }
            else if (step === 2) {
                navigate('/set-up-account#user')
                navigate('/set-up-account#confirm')
                navigate('/set-up-account#project')
                finishLoading();
            }
            else if (step === 3) {
                navigate('/set-up-account#user')
                navigate('/set-up-account#confirm')
                navigate('/set-up-account#project')
                navigate('/set-up-account#ideas')
                finishLoading();

            }
            else if (step === 4) {
                navigate('/set-up-account#user')
                navigate('/set-up-account#confirm')
                navigate('/set-up-account#project')
                navigate('/set-up-account#ideas')
                navigate('/set-up-account#firstmeet')
                finishLoading();
            }
            else if (step === 5) {
                navigate("/home")
            }
        }
        else {
            setLoadingPage(false);
        }
    }



    const handleChangeUser = (e) => {
        const { name, value } = e.target;
        //console.log("Here!", name, value)
        const localForms = userValues;
        // Set values
        if (name === "reference") {
            if (localForms.referenceList[value] != null) {
                localForms.referenceList[value] = !localForms.referenceList[value];
            }
            else {
                localForms.referenceList[value] = true;
            }

            let options = [];
            Object.keys(localForms.referenceList).forEach((key) => {
                if (localForms.referenceList[key] === true) {
                    options.push(key);
                }
            })
            localForms.reference = options.join(';');

        }
        else {
            localForms[name] = value;
        }

        setUserValues(localForms);
    };

    const handleChangeProject = (e) => {
        const { name, value } = e.target;
        const localForms = projectValues;

        if (name === "region") {
            //console.log("Region: ", value?.label)
            if (localForms["county"] != null && localForms["county"] !== "") {
                localForms["county"] = "";
            }
            localForms['compCity'] = ";" + value?.label;
        }
        else if (name === "county") {
            //console.log("County: ", value?.label)
            if (localForms["region"] != null && localForms["region"] !== "") {
                localForms['compCity'] = value?.label + ";" + localForms["region"].label;
            }
            else {
                localForms['compCity'] = value?.label + ";";
            }
        }
        localForms[name] = value;

        setProjectValues(localForms);
    };

    const handleChangeIdeas = (e) => {
        const { name, value } = e.target;
        const localForms = ideasValues;
        // Set values
        if (name === "globalGoals") {
            let index = localForms.globalGoalsList.indexOf(value);
            if (index !== -1) {
                localForms.globalGoalsList.splice(index, 1);
            }
            else {
                localForms.globalGoalsList.push(value);
            }
            localForms.globalGoals = localForms.globalGoalsList.join(';');
        }
        else {
            localForms[name] = value;
        }

        setIdeasValues(localForms);
    };
    //Reading the initial value from the hash-value
    function getHashValueInit(hashVal) {
        switch (hashVal) {
            case '#user':
                return 0;
            case '#confirm':
                return 1;
            case '#project':
                return 3;
            case '#ideas':
                return 4;
            case '#firstmeet':
                return 5;
            default:
                return 0;
        }
    }

    const [activeStep, setActiveStep] = useState(getHashValueInit(selectedTab));

    useEffect(() => {
        if (selectedTab != null) {
            getHashValue(selectedTab)
        }
    }, [selectedTab])

    function getHashValue(hashVal) {
        switch (hashVal) {
            case '#user':
                if (activeStep !== 0) {
                    setActiveStep(0);
                }
                break;
            case '#confirm':
                if (activeStep !== 1) {
                    setActiveStep(1);
                }
                break;
            case '#project':
                if (activeStep !== 2) {
                    setActiveStep(2);
                }
                break;
            case '#ideas':
                if (activeStep !== 3) {
                    setActiveStep(3);
                }
                break;
            case '#firstmeet':
                if (activeStep !== 4) {
                    setActiveStep(4);
                }
                break;
            default:
                if (activeStep !== 0) {
                    setActiveStep(0);
                }
                break;
        }
    }
    const handleSteps = (step) => {
        if (loadingPage === false) {
            switch (step) {
                case 0:
                    return (
                        <div>
                            <UserInfo handleChange={handleChangeUser} values={userValues} updateVals={update} />
                        </div>
                    );
                case 1:
                    return (
                        <div>
                            <ConfirmUserInfo values={userValues} checkTerms={checkTerms} />
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <CreateNewProject handleChange={handleChangeProject} values={projectValues} />
                        </div>
                    );
                case 3:
                    return (
                        <div>
                            <IdeasStep handleChange={handleChangeIdeas} values={ideasValues} />
                        </div>
                    );
                case 4:
                    return (
                        <div>
                            <FirstMeetDatePicker />
                        </div>
                    );
                default:
                    break;
            }
        }

    };

    return (
        <>
            <>
                <Box style={{ margin: "30px 0 50px" }}>
                    <Typography variant="h4" align="center">
                        Set up account
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ margin: "10px 0" }}
                    >
                        We need some information about you and your business ideas.
                    </Typography>
                </Box>
                <Stepper
                    id="set-up-stepper"
                    activeStep={activeStep}

                    alternativeLabel

                >
                    {labels.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {loadingPage === true || loading === true ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                ) : (
                    <>
                        {handleSteps(activeStep)}
                    </>
                )}

            </>
        </>
    );
};
