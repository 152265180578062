import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
//import useGetUserData from '../../hooks/useGetUserData';
import { setStepState } from '../../../firebase/cloudFunctions';
import { Link } from 'react-router-dom'
//import { auth } from '../../firebase';

import { useConfirm } from 'material-ui-confirm';
import { Alert, Grid, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../contexts/AuthContext'
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import CommentDialogStepInternal from '../../Comments/CommentDialogStepInternal';
import CommentLogMeetNotes from '../../Comments/CommentLogMeetNotes';
import { useGetProjectLinkStep } from '../../../hooks/projectData/useGetProjectLinkStep';
import DatePickerMeets from '../../TimePickers/DatePickerMeets';
import { DatePickerBox } from '../../TimePickers/DatePickerBox';
//import FormLabel from '@mui/material/FormLabel';


//const setNextTimeStep = firebase.app().functions("europe-west1").httpsCallable('setNextTimeStep');



export const MeetStep = ({ projectData, step, location }) => {
    const { stepData, isLoading: loadingStepData } = useGetProjectLinkStep(projectData.id, step);
    const confirm = useConfirm();

    const { currentUser } = useAuth();
    //const navigate = useNavigate();
    //const setStepState = firebase.app().functions("europe-west1").httpsCallable('setStepState');
    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (loadingStepData === false) {
            if (stepData?.meetState === "Completed") {
                setChecked(true);
            }
        }
    }, [stepData, loadingStepData])

    const handleChange = (event) => {
        if (event.target.checked === true) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want set status to completed?' })
                .then(() => {
                    setStatusLoading(true);
                    setChecked(event.target.checked);
                    setStepState({
                        projectID: projectData.id,
                        stateType: 'meetState',
                        step: step,
                        state: "Completed",

                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        } else if (event.target.checked === false) {
            confirm({ title: 'Please confirm', description: 'Are you sure you want set status to booked?' })
                .then(() => {
                    setChecked(event.target.checked);
                    setStatusLoading(true);
                    setStepState({
                        projectID: projectData.id,
                        stateType: 'meetState',
                        step: step,
                        state: "Booked",

                    })
                        .then(() => {
                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        })
                        //this code below runs when the message was NOT successfully sent from inside of the cloud function
                        .catch(() => {
                            //set `error` state to true
                            setError(true);

                            //set `isLoading` state to false, so the spinner is not rendered anymore
                            setStatusLoading(false);

                            //set `openSnackbar` state to true, so the snackbar is rendered, with content that depends on the error state
                            setOpenSnackbar(true);
                        });
                })
                .catch(() => { setStatusLoading(false); });
        }
    };


    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    function getNextStepState() {
        switch (step) {
            case 'meet1':
                return { projectData: projectData, step: "b", stepName: "Homework 1" };
            case 'meet2':
                return { projectData: projectData, step: "c", stepName: "Homework 2" };
            case 'meet3':
                return { projectData: projectData, step: "d", stepName: "Homework 3" };
            case 'meet4':
                return { projectData: projectData, step: "e", stepName: "Homework 4" };
            default:
                break;
        }
    }

    if (loadingStepData) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><span><CircularProgress /></span></div>
    }

    return (
        <div>
            {currentUser.role >= 2 ? (
                <>
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>

                        {stepData != null && stepData.nextTime != null ? (
                            <Stack direction='row' spacing={1} alignItems="center">
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <div style={{ marginTop: '-2px', paddingRight: '4px' }}>Booked time for this meeting:</div>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <strong>{stepData.nextDeadline}</strong>
                                            {step !== 'meet1' ? (
                                                <DatePickerMeets projectData={projectData} stepdata={stepData} />
                                            ) : ("")}

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        ) : (
                            <span>No time has been set for this meeting.</span>
                        )}
                    </div>

                    {step !== 'meet1' && (stepData?.nextTime == null) ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ marginTop: '30px' }}>
                                <DatePickerBox projectData={projectData} stepdata={stepData} isNew={true} />
                            </div>
                        </div>
                    ) : ("")}

                    {stepData != null && stepData.nextTime != null ? (
                        <>
                            <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
                                <CommentLogMeetNotes projID={projectData.id} project={projectData} step={step} location={location} />
                            </div>

                            <div style={{ marginTop: '20px' }}>
                                {statusLoading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', }}><CircularProgress /></div>
                                ) : (
                                    <>
                                        <div style={{ display: 'flex', justifyContent: 'center', }}>

                                            <FormControlLabel
                                                control={<Checkbox />}
                                                checked={checked}
                                                onChange={handleChange}
                                                //inputProps={{ 'aria-label': 'controlled' }}
                                                label="Meeting successfully completed"


                                            />
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                                                {/*<FileOverviewStepInternal projData={projectData} step={step} location={location} />*/}
                                                <CommentDialogStepInternal projID={projectData.id} projData={projectData} step={step} location={location} />
                                            </div>
                                        </div>
                                        <Box mt="1rem" style={{ display: 'flex', justifyContent: 'center', }}>
                                            {loading ? (
                                                <CircularProgress />
                                            ) : (
                                                <>
                                                    {step !== 'meet5' &&
                                                        <Button
                                                            component={Link} to="/question-manager"
                                                            state={getNextStepState()}
                                                            //size="medium"

                                                            variant="contained"
                                                            disabled={checked !== true}
                                                        >
                                                            Assign homework <SettingsIcon style={{ color: "black", marginLeft: "10px", fontSize: "16px" }} />
                                                        </Button>
                                                    }
                                                </>


                                            )}
                                        </Box>
                                    </>)}
                            </div></>) : ("")}


                </>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}><CircularProgress /></div>
                        ) : (
                            <>
                                <div className="booked-time">
                                    {stepData != null && stepData.nextTime != null ? (
                                        <Stack direction='row' spacing={1} alignItems="center">
                                            <Grid container alignItems="center" justifyContent="center">
                                                <Grid item>
                                                    <div style={{ marginTop: '-2px', paddingRight: '4px' }}>Booked time for this meeting:</div>
                                                </Grid>
                                                <Grid item>
                                                    <div><strong>{stepData.nextDeadline}</strong></div>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    ) : (<span>No time has been set for this meeting.</span>)}
                                </div>
                                <div className="meet-notes">
                                    <CommentLogMeetNotes projID={projectData.id} project={projectData} step={step} location={location} />
                                </div>
                            </>
                        )}
                    </Box>
                </div>
            )}

            {
                error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Done!</Alert>
                    </Snackbar>
                )
            }

        </div >
    )
}