import { Box, Card, CardContent, TextField } from "@mui/material"
import Explanation from "../../Explanation"
import { QuestionCommentBox } from "../QuestionCommentBox";


export const MiroCard = ({ projectData, step, location, assignedQuestion, question }) => {
    return (
        <Card className="characters" key={question.key} sx={{
            maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem', marginLeft: "-15px"
        }}>
            <CardContent>
                {assignedQuestion.note != null ? (
                    <p className="note-style"><strong>{assignedQuestion.note}</strong></p>) : ("")}
                <iframe width="100%" height="400" src={projectData.miroLink} title="Miro" frameBorder="0"></iframe>

            </CardContent>

            <QuestionCommentBox projectData={projectData} step={step} location={location} assignedQuestion={assignedQuestion} />
        </Card>
    )
}