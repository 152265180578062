import notFound from '../../assets/images/notFound2.png'
import Box from "@mui/material/Box/Box"
import { CircularProgress, Stack } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { Fragment, useEffect, useState } from 'react'
import useGetUserProject from '../../hooks/userData/useGetUserProject'

const InactivePage = () => {
    const { project, loading } = useGetUserProject()
    const [pageLoading, setPageLoading] = useState(true);
    const analytics = getAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        if (loading === false) {
            //console.log("Project: ", project?.data?.isActive)
            if (project?.data?.isActive !== false) {
                navigate("/home", { replace: true });
            }
            setPageLoading(false);
        }
    }, [loading, project])

    useEffect(() => {
        logEvent(analytics, 'page_view', {
            page_title: 'Inactive',
            page_path: window.location.pathname,
        });
    }, [])

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack justifyContent="center" alignItems="center" spacing={2} sx={{ marginTop: '50px' }}>
                {pageLoading ? (
                    <Box>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Fragment>
                        <Box>
                            <h5>Inactive</h5>
                        </Box>
                        <Box fontSize={'16px'} textAlign={'center'} maxWidth={'1060px'}>
                            You has been marked as inactive on the Krinova Start Platform. Contact your assigned Business Designer to request re-activation.
                            If you have been inactive for more than 12 months, your account will be permanently deleted.
                        </Box>
                    </Fragment>
                )}

            </Stack>

        </Box>
    )
}

export default InactivePage;