import { Fragment, useEffect, useState } from 'react'
import Select from '@mui/material/Select';
//import { NativeSelect } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import AddMiroLink from './AddMiroLink'
import MenuItem from '@mui/material/MenuItem';
import { IconButton, TableCell, Tooltip } from '@mui/material';
import { deleteProject, setActiveStatus } from '../../firebase/cloudFunctions';
import Button from '@mui/material/Button';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useConfirm } from 'material-ui-confirm';
import CircularProgress from '@mui/material/CircularProgress';
import StepperPopOver from '../Stepper/StepperPopOver'
import CommentDialogLog from '../Comments/CommentDialogLogInternal';
import CommentDialogLogExternal from '../Comments/CommentDialogLogExternal';
import { FileOverview } from '../Files/FileOverview';

function setDefaultOwner(project) {
    if (project.bdowner == null || project.bdowner.length === 0) {
        //console.log('before' + project.bdowner.length)
        return -1
    } else {
        //console.log('after' + project.bdowner[0])
        return project.bdowner[0]

    }

}

//const deleteProject = firebase.app().functions("europe-west1").httpsCallable('deleteProject');

const StepTableRow = ({ project, addUnassigned, AssignBD, bdowners, iboNames, isAdmin }) => {
    // const [deleteIbo, setDeleteIbo] = useState(null);
    const confirm = useConfirm();
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('')
    // useDeleteIbo(deleteIbo);

    const handleDeleteIbo = (project) => {
        confirm({ title: 'Please confirm', description: 'Are you sure you want to delete IBO ' + project.value + '?' })
            .then(() => {
                setIsLoading(true);
                deleteProject({
                    projectID: project.id
                }).then(() => {
                    setIsLoading(false);
                }).catch((e) => {
                    setIsLoading(false);
                })
            })
            .catch(() => {
                //set `error` state to true
                return

            });
    }

    useEffect(() => {
        let localUsername = '';
        iboNames.forEach((owner) => {
            if (project.iboOwner === owner.id) {
                localUsername = owner.value;
            }
        });

        setUsername(localUsername)
    }, [iboNames, project.iboOwner])


    /**
     * 
     * @param {Date} deadline
     * @returns {boolean}
     */
    function isLate(deadline) {
        const now = new Date(Date.now());
        if (deadline < now) {
            return true;
        }

        return false;
    }

    const handleSetToActive = () => {
        confirm({ title: 'Please confirm', description: 'Do you want to mark the IBO ' + project?.value + ' as active?' })
            .then(() => {
                setIsLoading(true);
                setActiveStatus({
                    projectID: project?.id,
                    isActive: true,
                })
                    .then(() => {
                        project['isActive'] = true;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            })
            .catch(() => { setIsLoading(false); });

    }

    const handleSetToInactive = () => {
        confirm({ title: 'Please confirm', description: 'Do you want to mark the IBO ' + project?.value + ' as inactive?' })
            .then(() => {
                setIsLoading(true);
                setActiveStatus({
                    projectID: project?.id,
                    isActive: false,
                })
                    .then(() => {
                        project['isActive'] = false;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    })
            })
            .catch(() => { setIsLoading(false); });
    }

    if (!project) return null
    return (
        <TableRow key={project.id}>
            {isAdmin ? (
                !isLoading ? (<TableCell style={{ paddingRight: '40px' }} padding='normal'><Button className="delbtn"
                    onClick={() => {
                        handleDeleteIbo(project);
                    }}>
                    <HighlightOffOutlinedIcon style={{ fontSize: "20px" }} />
                </Button></TableCell>) : (<div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}><CircularProgress size={"20px"} /></div>)
            ) : (<></>)}
            <TableCell>
                {isLoading === true ? (
                    <Fragment>
                        <CircularProgress size={'16px'} />
                    </Fragment>
                ) : (
                    <Fragment>
                        {project?.isActive === false ? (
                            <Tooltip title="Inactive" placement='left'>
                                <IconButton size='small' sx={{ height: '22px' }} onClick={handleSetToActive}>
                                    <svg height="20" width="20">
                                        <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="0" fill="#ed1c24c4" />
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Active" placement='left'>
                                <IconButton size='small' sx={{ height: '22px' }} onClick={handleSetToInactive}>
                                    <svg height="20" width="20">
                                        <circle cx="9" cy="9" r="8" stroke="black" strokeWidth="0" fill="#44a829b5" />
                                    </svg>
                                </IconButton>
                            </Tooltip>)}
                    </Fragment>
                )}

            </TableCell>
            <TableCell padding='normal' sx={{ maxWidth: '340px' }}>
                <Link
                    to='/home'
                    state={{ projectData: project }}
                    style={{ color: '#4eacc1', marginLeft: '-5px' }}
                ><strong>{project?.value}</strong></Link>
            </TableCell>
            <TableCell padding='normal'>

                {iboNames.filter(iboName => iboName.id === project.iboOwner[0]).map(filteredName => (
                    <span key={filteredName}>
                        {filteredName.value}
                    </span>
                )
                )}
                <span>{username}</span>

            </TableCell>

            <TableCell padding='none'>
                <Select
                    id="select-bd"
                    value={setDefaultOwner(project)}
                    size="small"
                    sx={{ fontSize: '11px' }}
                    variant="standard"
                    onChange={(event) => AssignBD(project.id, event)}>
                    {addUnassigned(project)}

                    {bdowners.map((owner) => {
                        //console.log(owner)
                        //console.log('project id:' + project.id)
                        return (
                            <MenuItem
                                key={owner.id}
                                value={owner.id}>{owner.value}
                            </MenuItem>)
                    })
                    }

                </Select>
            </TableCell>
            <TableCell>
                <Select
                    size='small'
                    variant="standard"
                    value={'startup'}
                >
                    <MenuItem value={'startup'}>Startup</MenuItem>
                    <Tooltip title="Coming soon™" placement='right' arrow>
                        <span><MenuItem value={'incubator'} disabled>Incubator</MenuItem></span>
                    </Tooltip>
                    <Tooltip title="Coming soon™" placement='right' arrow>
                        <span><MenuItem value={'scale'} disabled>Scale</MenuItem></span>
                    </Tooltip>

                </Select>
            </TableCell>
            <TableCell>
                <span style={{ display: 'flex', justifyContent: 'right', }}>
                    <div style={{minWidth: '90px'}}>
                        {project.currentStep}
                    </div>
                    
                    <StepperPopOver projData={project} />
                </span>
            </TableCell>
            {project.deadline !== undefined ? (
                <>
                    {isLate(new Date(project.deadline['time'].seconds * 1000)) ? (
                        <TableCell style={{ textAlign: "center" }}><span style={{ color: '#ac2020', fontStyle: "italic", justifyContent: 'right' }}>{format(new Date(project.deadline['time'].seconds * 1000), 'yyyy-MM-dd HH:mm')}</span></TableCell>
                    ) : (
                        <TableCell style={{ textAlign: "center" }}><span style={{ justifyContent: 'right' }}>{format(new Date(project.deadline['time'].seconds * 1000), 'yyyy-MM-dd HH:mm')}</span></TableCell>
                    )}
                </>
            ) : (
                <TableCell padding='none' style={{ textAlign: "center" }}><span style={{ justifyContent: 'right' }}>Not Set</span></TableCell>
            )
            }
            <TableCell sx={{ textAlign: "center" }} padding='none'><AddMiroLink projID={project.id} projData={project} /></TableCell>
            <TableCell sx={{ textAlign: "center" }} padding='none'><span style={{ display: 'flex', justifyContent: 'center' }}><CommentDialogLog projID={project.id} projData={project} /><CommentDialogLogExternal projID={project.id} projData={project} /></span></TableCell>
            <TableCell><FileOverview projData={project} /></TableCell>
        </TableRow>
    )
}

export default StepTableRow
