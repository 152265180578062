import { useState, Fragment } from 'react'
import '../../assets/scss/app.scss'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { writeNote, deleteNote, editNote } from '../../firebase/cloudFunctions';
import Stack from '@mui/material/Stack'

import { useAuth } from '../../contexts/AuthContext'
import format from 'date-fns/format'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Divider, IconButton, LinearProgress } from '@mui/material'
import { ErrorAlert } from '../../helper/errorAlert'

/**
 * 
 * @param {object} param0 
 * @param {string} param0.projID
 * @param {Array} param0.comments
 * @param {string} param0.location
 * @param {boolean} param0.isExternal
 * @param {('ascending' | 'descending')} param0.sortOrder
 * @param {string} [param0.questionKey]
 * @param {string} [param0.step]
 * @returns 
 */
const CommentLog = ({ projID, comments, location, isExternal, sortOrder, questionKey = null, step = null }) => {
    const { currentUser } = useAuth()

    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editComment, seteditComment] = useState({});
    const [commentText, setCommentText] = useState('')

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [hover, setHover] = useState('');

    const handleMouseOver = (comment) => {
        setHover(comment);
    }

    const handleMouseOut = () => {
        setHover('');
    }

    const submitComment = async () => {
        setIsLoading(true);

        let data = {
            projectID: projID,
            isExternal: isExternal,
            location: location,
            message: commentText,
        }

        if (questionKey != null && questionKey !== "") {
            data['assignedID'] = questionKey;
        }

        if (step != null && step !== "") {
            data['step'] = step;
        }

        // call the cloud function. 
        writeNote({
            projectID: projID,
            isExternal: isExternal,
            location: location,
            message: commentText,
        }).then(() => {
            setCommentText('')
        }).catch((e) => {
            console.error(e);
            setErrorInfo(e);
            setErrorMsg('Something went wrong! Could not add comment.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const deleteComment = async (comments) => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        // call the cloud function. 
        deleteNote({
            messageID: comments.id,
            projectID: projID,
            isExternal: isExternal,
        }).catch((e) => {
            console.error(e);
            setErrorInfo(e);
            setErrorMsg('Something went wrong! Could not delete comment.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const editCommentFunc = (comment) => {
        //console.log("Edit: ", comment, projID);
        seteditComment(comment);
        setCommentText(comment?.message)
        setIsEdit(true);
    };

    const canceleditCommentFunc = () => {
        seteditComment({});
        setCommentText('')
        setIsEdit(false);
    };


    const submiteditComment = () => {
        //console.log("Comment: ", editComment, commentText)
        if (editComment != null) {
            setIsLoading(true);

            const data = {
                // @ts-ignore
                messageID: editComment.id,
                projectID: projID,
                isExternal: isExternal,
                message: commentText,
            }

            // @ts-ignore
            if (editComment.assignedID != null) {
                // @ts-ignore
                data['assignedID'] = editComment.assignedID
            }

            // @ts-ignore
            if (editComment.approved != null && editComment.step != null) {
                // @ts-ignore
                data['approved'] = editComment.approved;
                // @ts-ignore
                data['step'] = editComment.step;
            }

            //console.log("Snap: ", data)

            editNote(data).then(() => {
                seteditComment({});
                setCommentText('')
                setIsEdit(false);
            }).catch((e) => {
                console.error(e);
                setErrorInfo(e);
                setErrorMsg('Something went wrong! Could not update comment.');
                setError(true);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    const sortList = (a, b) => {
        if (sortOrder === 'ascending') {
            return a.created?.seconds - b.created?.seconds;
        }
        else {
            return b.created?.seconds - a.created?.seconds;
        }
    }

    return (
        <>
            <Box sx={{
                width: 800,
                maxWidth: '100%',
            }}>
                <Box>
                    {comments.sort(sortList).map((comment) => {
                        let style = {};

                        if (currentUser && currentUser.uid === comment.createdBy) {
                            style['backgroundColor'] = '#4eacc11f';
                            style['borderRadius'] = '6px';
                        }
                        return (
                            <Fragment>
                                <Box sx={style} onMouseOver={() => handleMouseOver(comment.id)} onMouseOut={handleMouseOut}>
                                    <Stack>
                                        <Stack direction={'row'} alignItems='center' justifyContent="space-between">
                                            <Stack>
                                                <Stack direction={'row'}>
                                                    <span className="comment_username">
                                                        {comment?.createdUserName}
                                                    </span>
                                                    <span>
                                                        {comment?.created != null ? (format(new Date(comment?.created?.seconds * 1000), 'yyyy-MM-dd HH:mm')) : ("Not Set")}
                                                    </span>

                                                    <span style={{ color: 'grey', marginLeft: '10px' }}>
                                                        {comment?.location}
                                                    </span>

                                                    {comment?.assignedID !== "undefined" && comment?.assignedID != null ? (
                                                        <span>
                                                            <QuizOutlinedIcon
                                                                style={{
                                                                    fontSize: "17px",
                                                                    marginLeft: "5px",
                                                                    color: 'lightblue',
                                                                    //float: "middle",
                                                                }}
                                                            />
                                                        </span>
                                                    ) : ("")}
                                                </Stack>
                                                <Stack direction={'row'}>
                                                    {comment?.message != null && comment?.message !== "" ? (comment.message) : ("-")}
                                                </Stack>
                                            </Stack>
                                            {currentUser && currentUser.uid === comment.createdBy && hover === comment.id ? (
                                                <Stack direction={'row'}>
                                                    <IconButton onClick={() => { editCommentFunc(comment) }} size={'small'}>
                                                        <EditIcon
                                                            fontSize="small"
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => { deleteComment(comment) }} size={'small'}>
                                                        <HighlightOffOutlinedIcon
                                                            fontSize="small"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </IconButton>

                                                </Stack>
                                            ) : ("")}
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Divider sx={{ marginTop: '6px', marginBottom: '6px' }} />
                            </Fragment>
                        )
                    }
                    )}
                </Box>


                {isLoading ? (
                    <Box sx={{ width: 'auto', marginBottom: '7px' }}>
                        <LinearProgress />
                    </Box>
                ) : (
                    <Box sx={{ width: 'auto', height: '5px', marginBottom: '7px' }}>

                    </Box>
                )}

                <form className="form_internal">
                    <Stack spacing={0} direction="row" alignItems={'flex-end'}>
                        {isEdit ? (
                            <Fragment>
                                <Box>
                                    <TextField
                                        size='small'
                                        className='form-control-internal-textfield-edit'
                                        id='standard-comment'
                                        label='Edit comment'
                                        multiline={true}
                                        value={commentText}
                                        onChange={(e) => { setCommentText(e.target.value) }}
                                    />
                                </Box>

                                <Button size="medium" className="post_comment_btn" variant="contained"
                                    color="primary"
                                    disabled={isLoading || commentText.length < 1}
                                    onClick={submiteditComment}
                                >Update</Button>

                                <Button size="medium" className="post_comment_btn" variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={canceleditCommentFunc}
                                >Cancel</Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Box>
                                    <TextField
                                        size='small'
                                        className='form-control-internal-textfield'
                                        id='standard-comment'
                                        label='Comment'
                                        multiline={true}
                                        maxRows={6}
                                        value={commentText}
                                        onChange={(e) => { setCommentText(e.target.value) }}
                                    />
                                </Box>

                                <Button size="medium" className="post_comment_btn" variant="contained"
                                    color="primary"
                                    disabled={isLoading || commentText.length < 1}
                                    onClick={submitComment}
                                >Post</Button>
                            </Fragment>
                        )}

                    </Stack>
                </form>

            </Box >

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
}

export default CommentLog
