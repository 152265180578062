import { useEffect, useState } from "react"
import { INTRODUCTION_PARAGRAPHS as INTRODUCTIONTEXT_SWE } from "../../constants/lang/swe/startPageIBO";
import { INTRODUCTION_PARAGRAPHS as INTRODUCTIONTEXT_ENG } from "../../constants/lang/eng/startPageIBO";
import useGetUserData from "../userData/useGetUserData";

/**
 * 
 *  @param {function():void} handleOpenInfo 
 *  @param {function():void} handleOpenVideo 
 */
export const useGetIntroParagraphs = (handleOpenInfo, handleOpenVideo) => {
    const { userData, loading } = useGetUserData();
    const [paragraphs, setParagraphs] = useState({});

    useEffect(() => {
        if (loading === false) {
            // @ts-ignore
            if (userData?.prefLang === 'swe') {
                setParagraphs(INTRODUCTIONTEXT_SWE(handleOpenInfo, handleOpenVideo))
            }
            else {
                setParagraphs(INTRODUCTIONTEXT_ENG(handleOpenInfo, handleOpenVideo))
            }
        }
        else {
            setParagraphs(INTRODUCTIONTEXT_ENG(handleOpenInfo, handleOpenVideo))
        }

    }, [loading, userData]);

    return paragraphs;
}