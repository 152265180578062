import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../../../contexts/AuthContext'
import CommentDialogStepInternal from '../../Comments/CommentDialogStepInternal';
import QuestionForm from '../../Questions/QuestionForm';
import { useGetProjectLinkStep } from '../../../hooks/projectData/useGetProjectLinkStep';
import { IconButton, Stack } from '@mui/material';

import { DatePickerUpdateDeadline } from '../../TimePickers/DatePickerUpdateDeadline';
import { FileOverviewStepInternal } from '../../Files/FileOverviewStepInternal';
import { FileOverviewStepExternal } from '../../Files/FileOverviewStepExternal';
import { FileOverviewStep } from '../../Files/FileOverviewStep';
import { FilePublicFileList } from '../../Files/FilePublicFileList';

export const LinkStep = ({ step, projectData }) => {
    const { currentUser } = useAuth();
    const { stepData, isLoading } = useGetProjectLinkStep(projectData.id, step);

    if (isLoading) {
        return <div className='flex-center'><span><CircularProgress /></span></div>
    }

    return (
        <Box>
            {currentUser.role >= 2 ? (
                <>
                    <Box sx={{
                        '& > :not(style)': {
                            width: 1, display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center'
                        },
                    }}>
                        <Box>
                            <CommentDialogStepInternal projID={projectData.id} projData={projectData} step={step} location={stepData?.name} />
                            <FileOverviewStepExternal projData={projectData} step={step} location={stepData?.name} />
                        </Box>
                    </Box>



                </>
            ) : ("")}

            <div className='flex-center'>
                {isLoading ? (
                    <div className='flex-center' style={{ marginBottom: '20px' }}><CircularProgress /></div>
                ) : (
                    <Stack direction='row' justifyContent="center" alignItems='center'>
                        {stepData?.nextTime != null ? (
                            <span>Deadline: <strong>{stepData?.nextDeadline}</strong></span>
                        ) : (
                            <span>No deadline has been set for this step.</span>
                        )}

                        {currentUser.role >= 2 ? (
                            <DatePickerUpdateDeadline projectData={projectData} stepdata={stepData} />
                        ) : ("")}
                    </Stack>
                )}
            </div>

            <FilePublicFileList projData={projectData} step={step} location={stepData?.name} />

            <Box className='flex-center' style={{ display: 'flex', justifyContent: 'center' }}>
                <Box style={{ marginTop: '20px' }}>
                    <QuestionForm projectData={projectData} step={step} stepData={stepData} location={stepData?.name} />
                </Box>
            </Box>
        </Box >
    )
}