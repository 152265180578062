import React, { Fragment, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import { updateUserData } from "../../../firebase/cloudFunctions";
import { Box, LinearProgress } from "@mui/material";
import { REFERENCE_LABELS } from "../../../constants/lang/eng/fields";
import { useAuth } from "../../../contexts/AuthContext";
import { ErrorAlert } from "../../../helper/errorAlert";
import { getAnalytics, logEvent } from "firebase/analytics";

import textBubble from '../../../assets/images/tip-bubble.png'
import { DatepickerBithday } from "../Components/DatepicketBithday";


// Destructuring props
const UserInfo = ({ handleChange, values, updateVals, invite = false }) => {
    const [update, setUpdate] = useState(false);
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const { currentUser } = useAuth();
    const [isExpecationsInvalid, setExpectationsInvalid] = useState(false);
    const [isCityInvalid, setCityInvalid] = useState(false);
    const [isPhoneInvalid, setPhoneInvalid] = useState(false);
    const [isGenderInvalid, setGenderInvalid] = useState(false);
    const [isEducationInvalid, setEducationInvalid] = useState(false);
    const [isHkrConnectionInvalid, setHkrConnectionInvalid] = useState(false);
    const [isReferenceInvalid, setReferenceInvalid] = useState(false);
    const [isBirthdayInvalid, setBirthdayInvalid] = useState(false);
    const [generalInvalid, setGeneralInvalid] = useState(false);

    const [saveTrigger, setSaveTrigger] = useState('');
    const [autoSave, setAutoSave] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    useEffect(() => {
        //console.log(values.date)
        setUpdate(!update);
    }, [values])

    const validateAllFields = () => {
        let isError = false;
        if (values.phone != null && values.phone.length > 7 && values.phone.length < 15) {
            setPhoneInvalid(false);
        }
        else {
            isError = true;
            setPhoneInvalid(true);
        }

        if (values.city != null && values.city.length >= 1) {
            setCityInvalid(false);
        }
        else {
            isError = true;
            setCityInvalid(true);
        }

        if (values.gender != null && values.gender !== "") {
            setGenderInvalid(false);
        }
        else {
            isError = true;
            setGenderInvalid(true);
        }

        if (values.education != null && values.education !== "") {
            setEducationInvalid(false);
        }
        else {
            isError = true;
            setEducationInvalid(true);
        }

        if (values.hkrConnection != null && values.hkrConnection !== "") {
            setHkrConnectionInvalid(false);
        }
        else {
            isError = true;
            setHkrConnectionInvalid(true);
        }

        if (values.expectations != null && values.expectations.length >= 15) {
            setExpectationsInvalid(false);
        }
        else {
            isError = true;
            setExpectationsInvalid(true);
        }

        if (values.reference != null && values.reference !== "" && values.reference !== ";") {
            setReferenceInvalid(false);
        }
        else {
            isError = true;
            setReferenceInvalid(true);
        }

        if (values.date != null && values.date !== ""){
            setBirthdayInvalid(false);
        }
        else{
            setBirthdayInvalid(true);
        }

        setGeneralInvalid(isError);
        return isError;
    }

    const validateInput = (type) => {
        switch (type) {
            case 'phone':
                //console.log("Phone", values.phone)
                if (values.phone != null && values.phone.length > 7 && values.phone.length < 15) {
                    setPhoneInvalid(false);
                }
                else {
                    setPhoneInvalid(true);
                }
                break;
            case 'city':
                if (values.city != null && values.city.length >= 1) {
                    setCityInvalid(false);
                }
                else {
                    setCityInvalid(true);
                }
                break;
            case 'language':
                break;
            case 'gender':
                if (values.gender != null && values.gender !== "") {
                    setGenderInvalid(false);
                }
                else {
                    setGenderInvalid(true);
                }
                break;
            case 'education':
                if (values.education != null && values.education !== "") {
                    setEducationInvalid(false);
                }
                else {
                    setEducationInvalid(true);
                }
                break;
            case 'hkrConnection':
                if (values.hkrConnection != null && values.hkrConnection !== "") {
                    setHkrConnectionInvalid(false);
                }
                else {
                    setHkrConnectionInvalid(true);
                }
                break;
            case 'expectations':
                if (values.expectations != null && values.expectations.length >= 15) {
                    setExpectationsInvalid(false);
                }
                else {
                    setExpectationsInvalid(true);
                }
                break;
            case 'reference':
                if (values.reference != null && values.reference !== "" && values.reference !== ";") {
                    setReferenceInvalid(false);
                }
                else {
                    setReferenceInvalid(true);
                }
                break;
            default:
                console.log("Other: ", type)
                break;
        }
    }

    const clearInputError = (type) => {
        switch (type) {
            case 'phone':
                setPhoneInvalid(false);
                break;
            case 'city':
                setCityInvalid(false);
                break;
            case 'language':
                break;
            case 'gender':
                setGenderInvalid(false);
                break;
            case 'education':
                setEducationInvalid(false);
                break;
            case 'hkrConnection':
                setHkrConnectionInvalid(false);
                break;
            case 'expectations':
                setExpectationsInvalid(false);
                break;
            case 'reference':
                setReferenceInvalid(false);
                break;
            default:
                console.log("Other: ", type)
                break;
        }
    }

    const setErrorMessage = (type) => {
        switch (type) {
            case 'phone':
                if (isPhoneInvalid && values.phone != null && values.phone !== "") {
                    return "Please fill in a valid phone number"
                }
                else if (isPhoneInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'city':
                if (isCityInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'gender':
                if (isGenderInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'education':
                if (isEducationInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'hkrConnection':
                if (isHkrConnectionInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'expectations':
                if (isExpecationsInvalid && values.expectations === "") {
                    return "Answer is required";
                }
                else if (isExpecationsInvalid) {
                    return "Minimum of 15 charachters is required";
                }
                else {
                    return "";
                }
            case 'reference':
                if (isReferenceInvalid) {
                    return "Please choose one or more options";
                }
                else {
                    return "";
                }
            case 'birthday':
                if (isBirthdayInvalid) {
                    return "Please set your date of birth."
                }
                else {
                    return ""
                }
            case 'general':
                if (validateAllFields() === true) {
                    return "Please fill the required fields."
                }
                else {
                    return ""
                }
            default:
                console.log("Other: ", type)
                break;
        }
    }

    useEffect(() => {
        if (saveTrigger !== '') {
            const delayDebounceFn = setTimeout(() => {
                saveProgress();
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTrigger]);


    function saveProgress() {
        setIsLoading(true);

        updateUserData({
            userID: currentUser.uid,
            gender: values.gender,
            dob: values.date,
            city: values.city,
            phoneNr: values.phone,
            prefLang: values.language,
            education: values.education,
            hkrConnection: values.hkrConnection,
            expectation: values.expectations,
            reference: values.reference,
            noDataSet: true,
        })
            .then(() => {
                setAutoSave(new Date(Date.now()).toLocaleString())
            })
            .catch((error) => {
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true)
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    const handleChangeLocal = (e) => {
        handleChange(e);
        setSaveTrigger(e.target.value);
        clearInputError(e.target.name);
        setUpdate(!update);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validateAllFields() === false) {
            saveProgress();
            logEvent(analytics, 'completed_signup_step', {
                step: 1,
                invited: invite,
            })
            if (invite === true) {
                navigate('/invited#confirm')
            }
            else {
                navigate('/set-up-account#confirm')
            }

        }
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit} id="search-form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className="setup-phone">
                        <TextField
                            fullWidth
                            label="Phone number"
                            name="phone"
                            type={'tel'}
                            placeholder="i.e: xxx-xxx-xxxx"
                            value={values.phone}
                            onChange={handleChangeLocal}
                            onBlur={() => { validateInput('phone') }}
                            //onInput={validatePhone}
                            error={isPhoneInvalid}
                            helperText={setErrorMessage('phone')}
                            margin="normal"

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="setup-city">
                        <TextField
                            fullWidth
                            label="City"
                            name="city"
                            placeholder="Enter your city"
                            onBlur={() => { validateInput('city') }}
                            value={values.city || ""}
                            margin="normal"
                            onChange={handleChangeLocal}
                            //onInput={validateCity}
                            error={isCityInvalid}
                            helperText={setErrorMessage('city')}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}>
                        <FormControl fullWidth required margin="normal" className="setup-language">
                            <InputLabel id='first-step-gender'>Prefered language</InputLabel>
                            <Select
                                id="first-step-gender"
                                label="Prefered language"
                                value={values.language} onChange={handleChangeLocal} name="language"
                            >

                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Svenska"}>Svenska</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <DatepickerBithday
                            dobDate={values.date}
                            handleChange={handleChangeLocal}
                            setErrorMessage={setErrorMessage}
                            isBirthdayInvalid={isBirthdayInvalid}
                            setBirthdayInvalid={setBirthdayInvalid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl error={isGenderInvalid}>
                            <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>

                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="gender"
                                value={values.gender}
                                onChange={handleChangeLocal}
                            //onInput={validateGender}

                            >
                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                <FormControlLabel value="Other" control={<Radio />} label="Other" />

                            </RadioGroup>
                            <FormHelperText error={isGenderInvalid}>{setErrorMessage('gender')}</FormHelperText>

                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl required error={isEducationInvalid}>
                            <FormLabel id="demo-controlled-radio-buttons-group">Education (highest)</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="education"
                                value={values.education}
                                onChange={handleChangeLocal}
                            //error={isEducationInvalid}
                            //onInput={validateEducation}
                            >
                                <FormControlLabel value="Elementary School" control={<Radio />} label="Elementary School" />
                                <FormControlLabel value="Secondary" control={<Radio />} label="Secondary / High School" />
                                <FormControlLabel value="Post secondary education &lt; 3 years" control={<Radio />} label="Post secondary education &lt; 3 years" />
                                <FormControlLabel value="Post secondary education 3-5 years" control={<Radio />} label="Post secondary education 3-5 years" />
                                <FormControlLabel value="Post secondary education &gt; 5 years" control={<Radio />} label="Post secondary education &gt; 5 years" />
                                <FormControlLabel value="PhD" control={<Radio />} label="PhD" />
                            </RadioGroup>
                            <FormHelperText error={isEducationInvalid}>{setErrorMessage('education')}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* <span>Are you a student at or employed by Kristianstad University?</span> */}
                        <FormControl required>
                            <FormLabel id="demo-controlled-radio-buttons-group">Are you a student at or employed by Kristianstad University?</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="hkrConnection"
                                value={values.hkrConnection}
                                onChange={handleChangeLocal}
                            //onInput={validateHkrConnection}

                            >
                                <FormControlLabel value="Student" control={<Radio />} label="Student" />
                                <FormControlLabel value="Employee (researcher)" control={<Radio />} label="Employee (researcher)" />
                                <FormControlLabel value="Employee (teacher)" control={<Radio />} label="Employee (teacher)" />
                                <FormControlLabel value="Employee (other)" control={<Radio />} label="Employee (other)" />
                                <FormControlLabel value="None" control={<Radio />} label="None of the above" />
                            </RadioGroup>
                            <FormHelperText error={isHkrConnectionInvalid}>{setErrorMessage('hkrConnection')}</FormHelperText>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl component="fieldset" required>
                            <FormLabel component="legend">How did you hear of Krinova?</FormLabel>
                            <FormGroup aria-label="position" id="reference">
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['advertising']}
                                        checked={values.referenceList[REFERENCE_LABELS['advertising']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via advertising on social media"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['corporate']}
                                        checked={values.referenceList[REFERENCE_LABELS['corporate']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via corporate events"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['recommendation']}
                                        checked={values.referenceList[REFERENCE_LABELS['recommendation']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via recommendation"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['university']}
                                        checked={values.referenceList[REFERENCE_LABELS['university']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via the university"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['project']}
                                        checked={values.referenceList[REFERENCE_LABELS['project']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"

                                    />}
                                    label="Via a project that Krivnova is involved in"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['other']}
                                        checked={values.referenceList[REFERENCE_LABELS['other']] || false}
                                        onChange={handleChangeLocal}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Other"

                                />
                            </FormGroup>
                            <FormHelperText error={isReferenceInvalid}>{setErrorMessage('reference')}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        {/* <span>Are you a student at or employed by Kristianstad University?</span> */}
                        <FormControl component="fieldset" required>
                            <FormLabel>What are your expectations on the meeting with Krinova? What do you think your meeting with Krinova will result in?</FormLabel>
                            <TextField
                                id="outlined-multiline-static"
                                label="Answer"
                                multiline
                                name="expectations"
                                rows={4}
                                onChange={(e) => {
                                    handleChangeLocal(e);
                                    validateInput('expectations');
                                }}
                                onBlur={() => { validateInput('expectations') }}
                                //onInput={validateAnswer}
                                value={values.expectations || ""}
                                error={isExpecationsInvalid}
                                helperText={setErrorMessage('expectations')}
                            />

                        </FormControl>

                    </Grid>

                </Grid>


                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                    {isLoading ? (
                        <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                            <LinearProgress color="info" />
                        </Box>
                    ) : (
                        <Box sx={{ marginBottom: "4px" }}>

                        </Box>
                    )}
                </div>
                <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveProgress}
                        disabled={isLoading}
                    >
                        Save
                    </Button>

                    <Button
                        style={{ marginLeft: 20 }}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isLoading}
                    >
                        Save & Continue
                    </Button>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                    <i>{autoSave !== '' ? ('Last save: ' + autoSave) : ('')}</i>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                    <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                </div>
                <FormHelperText error={generalInvalid}>{""}</FormHelperText>
            </form>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Fragment >
    );
};

export default UserInfo;