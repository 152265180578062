import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material"
import { format } from "date-fns"
import { doc, getDocFromServer } from "firebase/firestore"
import { Fragment, useEffect, useState } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import { db } from "../../../firebase"
import { setEmailPref } from "../../../firebase/cloudFunctions"
import { isLate } from "../../../helper/dateHelpers"
import { ErrorAlert } from "../../../helper/errorAlert"
import { useGetSettingsTexts } from "../../../hooks/languageHooks/useGetSettingsTexts"
import AddParticipant from "./AddParticipant"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useGetProjectLinkStep } from "../../../hooks/projectData/useGetProjectLinkStep"

export const SettingsIBOComponent = ({ project }) => {
    const { currentUser } = useAuth();
    const { stepData, isLoading } = useGetProjectLinkStep(project.id, 'f');
    const sentences = useGetSettingsTexts();
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [finished, setFinished] = useState(false);

    const [isSending, setIsSending] = useState(false);
    const [state, setState] = useState({
        unlocked: true,
        reviewed: false,
        meetingApproved: true,
        oneDay: false,
        oneWeek: false,
    });

    useEffect(() => {
        if (isLoading === false) {
            if (stepData?.IBOState === 'Completed') {
                setFinished(true);
            }
        }
    }, [isLoading])

    useEffect(() => {
        const getEmailPrefs = () => {
            getDocFromServer(doc(db, "Users/" + currentUser.uid + "/Preferences/emailPrefs")).then((querySnapshot) => {
                if (querySnapshot.data() != null) {
                    // @ts-ignore
                    setState(querySnapshot.data());
                }
            });
        }

        getEmailPrefs();
    }, [currentUser.uid]);

    const openMiroLink = () => {
        window.open(project?.miroLink, '_blank');
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });

        setIsSending(true);

        setEmailPref({
            prefType: event.target.name,
            prefValue: event.target.checked,
        }).catch((error) => {
            setErrorInfo(error)
            setErrorMsg('Something went wrong! Could not save preferences');
            setError(true)
        }).finally(() => {
            setIsSending(false);
        })
    };

    return (
        <Fragment>
            <div className="home-ibo-info-box">
                {project?.deadline != null ? (
                    <>
                        <span>{sentences.deadline}</span>
                        {finished === true ? (
                             <span style={{ color: '#a9bc52', fontStyle: "italic" }}>
                                <strong>Finished</strong>
                            </span>
                        ) : (
                            <Fragment>
                                {isLate(new Date(project?.deadline['time']?.seconds * 1000)) ? (
                                    <span style={{ color: '#ac2020', fontStyle: "italic" }}>
                                        <strong>{format(new Date(project?.deadline['time']?.seconds * 1000), 'yyyy-MM-dd HH:mm')}</strong>
                                    </span>
                                ) : (
                                    <span>
                                        <strong>{format(new Date(project?.deadline['time']?.seconds * 1000), 'yyyy-MM-dd HH:mm')}</strong>
                                    </span>
                                )}
                            </Fragment>
                        )}

                    </>
                ) : ("")}

                <Box mt={3}>
                    <FormControl sx={{ mb: 4 }} component="fieldset" variant="standard">
                        <FormLabel component="legend"><strong>{sentences.notificationsHeader}</strong></FormLabel>
                        <FormHelperText sx={{ mb: 2 }}><i>{sentences.notificationsLabel}</i></FormHelperText>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state["unlocked"] || true} onChange={handleChange} name="unlocked" size="small" sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0, }} />
                                }
                                label={sentences.unlocked}
                                sx={{ mb: 0 }}

                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state["oneWeek"] || false} onChange={handleChange} name="oneWeek" size="small" sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0, }} />
                                }
                                label={sentences.oneWeek}
                                sx={{ mb: 0, mt: 0 }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state["oneDay"] || false} onChange={handleChange} name="oneDay" size="small" sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0, }} />
                                }
                                label={sentences.oneDay}
                                sx={{ mb: 0, mt: 0 }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state["reviewed"] || false} onChange={handleChange} name="reviewed" size="small" sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0, }} />
                                }
                                label={sentences.reviewed}
                                sx={{ mb: 0, mt: 0 }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state["meetingApproved"] || true} onChange={handleChange} size="small" name="meetingApproved" sx={{ paddingLeft: 1, paddingTop: 0, paddingBottom: 0, }} />
                                }
                                label={sentences.meetingApproved}
                                sx={{ mt: 0 }}
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
                <Box>
                    {project?.miroLink != null ? (
                        <>
                            <Button
                                //size="medium"
                                onClick={openMiroLink}
                                variant="contained"
                            >
                                <OpenInNewIcon style={{ marginRight: "8px", fontSize: "17px" }} /> {sentences.miroLink}</Button>
                        </>
                    ) : (
                        <>
                            <Button
                                variant="contained"
                                disabled
                            >{sentences.noMiroLink}</Button>
                        </>
                    )}
                </Box>
                <Box mt={1}>
                    <AddParticipant projID={project?.id} headerText={sentences.addPatricipant} />
                </Box>
            </div>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Fragment >
    )
}