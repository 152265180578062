import { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop, Box, Checkbox, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserTerms from "../Components/userTerms";
import { updateUserData } from "../../../firebase/cloudFunctions";
import { useAuth } from "../../../contexts/AuthContext";
import { ErrorAlert } from "../../../helper/errorAlert";
import { getAnalytics, logEvent } from "firebase/analytics";

import textBubble from '../../../assets/images/tip-bubble.png'

const ConfirmUserInfo = ({ checkTerms, values, invite = false }) => {
    const navigate = useNavigate();
    const analytics = getAnalytics();

    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(values.termsChecked);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const btnStyle = {
        'background': 'none',
        'border': 'none',
        'padding': '0',
        'color': '#003eaa',
        'fontWeight': 'bold',
        'textDecoration': 'underline',
        'cursor': 'pointer',
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        checkTerms(event.target.checked);
    };

    const handleSetChecked = (checked) => {
        setChecked(checked);
        checkTerms(checked);
    };
    //const { currentUser} = useAuth()
    const handleSubmit = () => {
        setIsLoading(true);

        updateUserData({
            userID: currentUser.uid,
            gender: values.gender,
            dob: values.date,
            city: values.city,
            phoneNr: values.phone,
            prefLang: values.language,
            education: values.education,
            hkrConnection: values.hkrConnection,
            expectation: values.expectations,
            reference: values.reference,
            acceptTerms: values.termsChecked,
        })
            .then(() => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 2,
                    invited: invite,
                })
                setIsLoading(false);

                if (invite === true) {
                    navigate('/home')
                }
                else {
                    navigate('/set-up-account#project')
                }

            })
            .catch((error) => {
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true);
                setIsLoading(false);
            });
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBack = () => {
        if (invite === true) {
            navigate('/invited#user', { replace: true })
        }
        else {
            navigate('/set-up-account#user', { replace: true })
        }

    }

    const handleCloseDisagree = () => {
        setOpen(false);
        handleSetChecked(false);
    };

    const handleCloseAgree = () => {
        setOpen(false);
        handleSetChecked(true);
    };

    document.querySelector('span').addEventListener('click', function (event) {
        event.stopPropagation();
    });


    return (
        <Fragment>
            <List disablePadding>
                <ListItem>
                    <ListItemText primary="Phone Number" secondary={values.phone} />
                </ListItem>

                <Divider />

                <ListItem>
                    <ListItemText primary="City" secondary={values.city} />
                </ListItem>
                <Divider />

                <ListItem>
                    <ListItemText primary="Prefered language" secondary={values.language} />
                </ListItem>

                <Divider />
                <ListItem>
                    <ListItemText primary="Date of birth" secondary={values.date} />
                </ListItem>


                <Divider />
                <ListItem>
                    <ListItemText primary="Gender" secondary={values.gender} />
                </ListItem>


                <Divider />

                <ListItem>
                    <ListItemText primary="Education (highest)" secondary={values.education} />
                </ListItem>

                <Divider />

                <ListItem>
                    <ListItemText
                        primary="Are you a student at or employed by Kristianstad University?"
                        secondary={values.hkrConnection}
                    />
                </ListItem>

                <Divider />
                <span style={{ display: 'block', marginLeft: "15px", marginTop: "10px" }}>How did you hear of Krinova?</span>
                <ListItem>

                    {/*
                            <ListItemText
                                primary="How did you hear of Krinova?"
                                secondary={reference}
                            />*/}
                    {String(values.reference).split(';').map((value) => {
                        return (
                            <ListItemText
                                primary=""
                                secondary={value}
                            />
                        );
                    })}
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        primary="What are your expectations on the meeting with Krinova? What do you think your meeting with Krinova will result in?"
                        secondary={values.expectations}
                    />
                </ListItem>
            </List>
            <div className="terms-checkbox">

                <Checkbox name="termsChecked" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                <p style={{ paddingTop: '14px' }}>I have read and agree with the <button style={btnStyle} onClick={handleClickOpen}>Terms</button> for Krinova's SUS-platform</p>
                <UserTerms open={open} handleClose={handleClose} handleCloseAgree={handleCloseAgree} handleCloseDisagree={handleCloseDisagree} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isLoading ? (
                    <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                        <LinearProgress color="primary" />
                    </Box>
                ) : (
                    <Box sx={{ marginBottom: "4px" }}>

                    </Box>
                )}
            </div>

            <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                <Button variant="contained" onClick={handleBack} disabled={isLoading}>
                    Back
                </Button>
                <Button
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={checked === false || isLoading}
                >
                    {invite === true ? ('Confirm & Finish') : ('Confirm & Continue')}
                </Button>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
                <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
            </div>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Fragment>
    );
};

export default ConfirmUserInfo;
