import { useState } from 'react';
import Popover from '@mui/material/Popover';
import StepperGraphic from './StepperGraphic'
//import { ReactComponent as GreenSquareProgress } from '../assets/images/progress/greenProgress.svg';
import IboTableStepperStatusIcon from './IboTableStepperStatusIcon.js'

export default function StepperPopOver({projData}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div style={{ display: "flex", marginLeft: "10px", marginTop: "-18px", }}>
            <span aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{
                    cursor: "pointer"
                }}>
                    <IboTableStepperStatusIcon data={projData} />
            </span>
            {/* <GreenSquareProgress aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ marginLeft: "3px", marginTop: "-3px" }}
            /> */}

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <StepperGraphic data={projData} /> 
            </Popover>
        </div>
    );
}
