import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';

import { useGetExternalComments } from '../../hooks/commentData/useGetExternalComments';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';
import CommentLog from './CommentLog';
import { Box, Divider, Stack, Switch, Typography } from '@mui/material';


export default function CommentDialogLogExternal({ projID, projData }) {
    const [open, setOpen] = useState(false);

    const { comments } = useGetExternalComments(projID, null, null, 'created');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [sortOrder, setSortOrder] = useState('ascending');
    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        if (sortOrder === 'descending') {
            setChecked(true);
            setSortOrder('ascending')
        }
        else {
            setChecked(false);
            setSortOrder('descending')
        }
    }

    return (
        <div>
            {comments.length > 0 ? (
                <RecordVoiceOverRoundedIcon className="add_miro comment_icon_internal not_empty" fontSize="small"
                    style={{
                        cursor: "pointer",
                        //textlign: "center",
                        marginLeft: "5px",
                    }}
                    onClick={handleClickOpen}
                />

            ) : (
                <RecordVoiceOverRoundedIcon className="add_miro comment_icon_internal" fontSize="small"
                    style={{
                        cursor: "pointer",
                        //textlign: "center",
                        marginLeft: "5px",
                    }}
                    onClick={handleClickOpen}
                />
            )}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" style={{ marginTop: 8, marginBottom: 4, marginLeft: 15, }}>
                        <Typography sx={{maxWidth: '600px'}} fontSize={'16px !important'}>Comment log <strong>(External)</strong> for IBO: {projData.value}</Typography>
                        <Box sx={{ paddingRight: '50px' }}>
                            <Stack direction={'row'} alignItems="center">
                                <span>
                                    Desc
                                </span>
                                <Switch checked={checked} onClick={handleChange} />
                                <span>
                                    Asc
                                </span>
                            </Stack>
                        </Box>
                    </Stack>
                </BootstrapDialogTitle>
                <Divider sx={{borderColor: '#4eacc1'}} />
                <DialogContent dividers>
                    <CommentLog projID={projID} comments={comments} isExternal={true} sortOrder={sortOrder} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
