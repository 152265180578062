import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import { entryStepQuestions, entryStepQuestions_save } from '../../../firebase/cloudFunctions';
import { useGetUserProjectId } from '../../../hooks/userData/useGetUserProjectId';
import { useNavigate } from "react-router-dom";
import { Box, FormHelperText, LinearProgress, Stack } from "@mui/material";
import { ErrorAlert } from "../../../helper/errorAlert";
import { getAnalytics, logEvent } from "firebase/analytics";

import textBubble from '../../../assets/images/tip-bubble.png'


const IdeasStep = ({ handleChange, values }) => {
    const navigate = useNavigate();
    const analytics = getAnalytics();
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [liveSaving, setLiveSaving] = useState(false);
    const [saveTrigger, setSaveTrigger] = useState('')
    const [autoSave, setAutoSave] = useState('');
    const { projectIDs, loadingProjID } = useGetUserProjectId();
    const [update, setUpdate] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');


    const [isNextStepInvalid, setNextStepInvalid] = useState(false);
    const [isIdeasInvalid, setIdeasInvalid] = useState(false);
    const [isUniqueInvalid, setUniqueInvalid] = useState(false);
    const [isAlreadyInvalid, setAlreadyInvalid] = useState(false);
    const [isCompetitorsInvalid, setCompetitorsInvalid] = useState(false);
    const [isCustomersInvalid, setCustomersInvalid] = useState(false);
    const [isDemandInvalid, setDemandInvalid] = useState(false);
    const [isChallengeInvalid, setChallengeInvalid] = useState(false);
    const [isAloneORteamInvalid, setAloneORteamInvalid] = useState(false);
    const [isCompetencesInvalid, setCompetencesInvalid] = useState(false);
    const [isTimeANDresourcesInvalid, setTimeANDresourcesInvalid] = useState(false);
    const [isGeneralInvalid, setGeneralInvalid] = useState(false);



    const validateInput = (type) => {
        switch (type) {
            case 'nextStep':
                if (values.nextStep != null && values.nextStep !== "") {
                    setNextStepInvalid(false);
                }
                else {
                    setNextStepInvalid(true);
                }
                break;
            case 'ideas':
                if (values.ideas != null && values.ideas !== "") {
                    setIdeasInvalid(false);
                }
                else {
                    setIdeasInvalid(true);
                }
                break;
            case "unique":
                if (values.unique != null && values.unique !== "") {
                    setUniqueInvalid(false);
                }
                else {
                    setUniqueInvalid(true);
                }
                break;
            case "already":
                if (values.already != null && values.already !== "") {
                    setAlreadyInvalid(false);
                }
                else {
                    setAlreadyInvalid(true);
                }
                break;
            case "competitors":
                if (values.competitors != null && values.competitors !== "") {
                    setCompetitorsInvalid(false);
                }
                else {
                    setCompetitorsInvalid(true);
                }
                break;
            case "customers":
                if (values.customers != null && values.customers !== "") {
                    setCustomersInvalid(false);
                }
                else {
                    setCustomersInvalid(true);
                }
                break;
            case "demand":
                if (values.demand != null && values.demand !== "") {
                    setDemandInvalid(false);
                }
                else {
                    setDemandInvalid(true);
                }
                break;
            case "challenge":
                if (values.challenge != null && values.challenge !== "") {
                    setChallengeInvalid(false);
                }
                else {
                    setChallengeInvalid(true);
                }
                break;
            case "aloneORteam":
                if (values.aloneORteam != null && values.aloneORteam !== "") {
                    setAloneORteamInvalid(false);
                }
                else {
                    setAloneORteamInvalid(true);
                }
                break;
            case "competences":
                if (values.competences != null && values.competences !== "") {
                    setCompetencesInvalid(false);
                }
                else {
                    setCompetencesInvalid(true);
                }
                break;
            case "timeANDresources":
                if (values.timeANDresources != null && values.timeANDresources !== "") {
                    setTimeANDresourcesInvalid(false);
                }
                else {
                    setTimeANDresourcesInvalid(true);
                }
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }


    function collectData() {
        const dataToSend = [
            { title: "ideas", answer: values.ideas },
            { title: "unique", answer: values.unique },
            { title: "already", answer: values.already },
            { title: "competitors", answer: values.competitors },
            { title: "customers", answer: values.customers },
            { title: "demand", answer: values.demand },
            { title: "challenge", answer: values.challenge },
            { title: "aloneORteam", answer: values.aloneORteam },
            { title: "competences", answer: values.competences },
            { title: "timeANDresources", answer: values.timeANDresources },
            { title: "motivate", answer: values.motivate },
            { title: "globalGoals", answer: values.globalGoals },
            { title: "nextStep", answer: values.nextStep },
        ];

        return dataToSend;
    }

    function collectDataSaveProgress() {
        const dataToSend = [];

        if (values.ideas != null && values.ideas !== "") {
            dataToSend.push({ title: "ideas", answer: values.ideas })
        }
        if (values.unique != null && values.unique !== "") {
            dataToSend.push({ title: "unique", answer: values.unique })
        }
        if (values.already != null && values.already !== "") {
            dataToSend.push({ title: "already", answer: values.already })
        }
        if (values.competitors != null && values.competitors !== "") {
            dataToSend.push({ title: "competitors", answer: values.competitors })
        }
        if (values.customers != null && values.customers !== "") {
            dataToSend.push({ title: "customers", answer: values.customers })
        }
        if (values.demand != null && values.demand !== "") {
            dataToSend.push({ title: "demand", answer: values.demand })
        }
        if (values.challenge != null && values.challenge !== "") {
            dataToSend.push({ title: "challenge", answer: values.challenge })
        }
        if (values.aloneORteam != null && values.aloneORteam !== "") {
            dataToSend.push({ title: "aloneORteam", answer: values.aloneORteam })
        }
        if (values.competences != null && values.competences !== "") {
            dataToSend.push({ title: "competences", answer: values.competences })
        }
        if (values.timeANDresources != null && values.timeANDresources !== "") {
            dataToSend.push({ title: "timeANDresources", answer: values.timeANDresources })
        }
        if (values.motivate != null && values.motivate !== "") {
            dataToSend.push({ title: "motivate", answer: values.motivate })
        }
        if (values.globalGoals != null && values.globalGoals !== "") {
            dataToSend.push({ title: "globalGoals", answer: values.globalGoals })
        }
        if (values.nextStep != null && values.nextStep !== "") {
            dataToSend.push({ title: "nextStep", answer: values.nextStep })
        }

        return dataToSend;
    }

    useEffect(() => {
        if (saveTrigger !== '') {
            const delayDebounceFn = setTimeout(() => {
                saveProgress();
            }, 3000)

            return () => {
                clearTimeout(delayDebounceFn);
            }
        }
    }, [saveTrigger]);

    const saveProgress = () => {
        if (collectDataSaveProgress().length > 0) {
            setIsSaveLoading(true);
            // call the cloud function. 

            //console.log("Saving! ", collectDataSaveProgress())

            entryStepQuestions_save({
                projectID: projectIDs[0],
                entryQuestions: collectDataSaveProgress(),
            }).then(() => {
                setAutoSave(new Date(Date.now()).toLocaleString())
            }).catch((error) => {
                console.error("Error", error)
            }).finally(() => {
                setIsSaveLoading(false);
            });

        }

    };

    const clearInputError = (type) => {
        switch (type) {
            case 'nextStep':
                setNextStepInvalid(false);
                break;
            case 'ideas':
                setIdeasInvalid(false);
                break;
            case "unique":
                setUniqueInvalid(false);
                break;
            case "already":
                setAlreadyInvalid(false);
                break;
            case "competitors":
                setCompetitorsInvalid(false);
                break;
            case "customers":
                setCustomersInvalid(false);
                break;
            case "demand":
                setDemandInvalid(false);
                break;
            case "challenge":
                setChallengeInvalid(false);
                break;
            case "aloneORteam":
                setAloneORteamInvalid(false);
                break;
            case "competences":
                setCompetencesInvalid(false);
                break;
            case "timeANDresources":
                setTimeANDresourcesInvalid(false);
                break;
            default:
                //console.log("Other: ", type)
                break;
        }
    }

    const validateAllFields = () => {
        let isError = false;

        if (values.nextStep != null && values.nextStep !== "") {
            setNextStepInvalid(false);
        }
        else {
            setNextStepInvalid(true);
            isError = true;
        }

        if (values.ideas != null && values.ideas !== "") {
            setIdeasInvalid(false);
        }
        else {
            setIdeasInvalid(true);
            isError = true;
        }

        if (values.unique != null && values.unique !== "") {
            setUniqueInvalid(false);
        }
        else {
            setUniqueInvalid(true);
            isError = true;
        }

        if (values.already != null && values.already !== "") {
            setAlreadyInvalid(false);
        }
        else {
            setAlreadyInvalid(true);
            isError = true;
        }

        if (values.competitors != null && values.competitors !== "") {
            setCompetitorsInvalid(false);
        }
        else {
            setCompetitorsInvalid(true);
            isError = true;
        }

        if (values.customers != null && values.customers !== "") {
            setCustomersInvalid(false);
        }
        else {
            setCustomersInvalid(true);
            isError = true;
        }

        if (values.demand != null && values.demand !== "") {
            setDemandInvalid(false);
        }
        else {
            setDemandInvalid(true);
            isError = true;
        }

        if (values.challenge != null && values.challenge !== "") {
            setChallengeInvalid(false);
        }
        else {
            setChallengeInvalid(true);
            isError = true;
        }

        if (values.aloneORteam != null && values.aloneORteam !== "") {
            setAloneORteamInvalid(false);
        }
        else {
            setAloneORteamInvalid(true);
            isError = true;
        }

        if (values.competences != null && values.competences !== "") {
            setCompetencesInvalid(false);
        }
        else {
            setCompetencesInvalid(true);
            isError = true;
        }

        if (values.timeANDresources != null && values.timeANDresources !== "") {
            setTimeANDresourcesInvalid(false);
        }
        else {
            setTimeANDresourcesInvalid(true);
            isError = true;
        }

        setGeneralInvalid(isError);
        return isError;
    }

    const handleChangeLocal = (e) => {
        handleChange(e);
        clearInputError(e.target.name);
        setGeneralInvalid(false);
        setSaveTrigger(e.target.value);
        setUpdate(!update);
    };


    //this function is called when clicking the `Send` button
    const handleSubmit = e => {
        e.preventDefault()

        if (validateAllFields() === false) {
            setIsLoading(true);

            entryStepQuestions({
                projectID: projectIDs[0],
                entryQuestions: collectData(),
            }).then(() => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 4,
                    invited: false,
                });
                navigate('/set-up-account#firstmeet')
            }).catch((error) => {
                console.error("Error", error);
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true)
            }).finally(() => {
                setIsLoading(false);
            })
        }

    };

    return (
        <div style={{ padding: '10px' }}>
            <form onSubmit={handleSubmit} id="project-form" >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <h6 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Business idea:</h6>
                        <FormLabel className="ideas-input-label">1. What need does your business idea meet? How did you discover that need?</FormLabel>
                        <TextField
                            label="Answer"
                            multiline
                            name="ideas"
                            rows={4}
                            onChange={handleChangeLocal}
                            onBlur={() => { validateInput('ideas') }}
                            value={values.ideas}
                            error={isIdeasInvalid}
                            helperText={isIdeasInvalid && "Answer is required"}

                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">2. Describe your service / product / idea?</FormLabel>
                        <TextField
                            label="Answer"
                            multiline
                            name="already"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.already}
                            onBlur={() => { validateInput('already') }}
                            error={isAlreadyInvalid}
                            helperText={isAlreadyInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">3. In what way is your service / product / idea better than those on the market today?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="unique"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.unique}
                            onBlur={() => { validateInput('unique') }}
                            error={isUniqueInvalid}
                            helperText={isUniqueInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" className="ideas-input-field">
                        <FormLabel className="ideas-input-label">4. In what way does your project contribute to the sustainable development of society?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="motivate"
                            rows={4}
                            onBlur={() => { validateInput('motivate') }}
                            onChange={handleChangeLocal}
                            value={values.motivate}
                        />
                    </FormControl>

                    <FormControl component="fieldset" className="ideas-input-field">
                        <FormLabel component="legend" className="ideas-input-label">4b. Which of the Global goals for sustainable development does your idea contribute to, if any?</FormLabel>
                        <FormGroup aria-label="position" id="reference">
                            <FormControlLabel
                                control={<Checkbox
                                    value={"1 - No poverty"}
                                    checked={values.globalGoals.includes("1 - No poverty")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="1 - No poverty"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"2 - Zero hunger"}
                                    checked={values.globalGoals.includes("2 - Zero hunger")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="2 - Zero hunger"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"3 - Good health and well-being"}
                                    checked={values.globalGoals.includes("3 - Good health and well-being")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="3 - Good health and well-being"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"4 - Quality education"}
                                    checked={values.globalGoals.includes("4 - Quality education")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="4 - Quality education"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"5 - Gender equality"}
                                    checked={values.globalGoals.includes("5 - Gender equality")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="5 - Gender equality"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"6 - Clean water and sanitation"}
                                    checked={values.globalGoals.includes("6 - Clean water and sanitation")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="6 - Clean water and sanitation"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"7 - Affordable and clean energy"}
                                    checked={values.globalGoals.includes("7 - Affordable and clean energy")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="7 - Affordable and clean energy"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"8 - Decent work and economic growth"}
                                    checked={values.globalGoals.includes("8 - Decent work and economic growth")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="8 - Decent work and economic growth"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"9 - Industry, innovation and infrastructure"}
                                    checked={values.globalGoals.includes("9 - Industry, innovation and infrastructure")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="9 - Industry, innovation and infrastructure"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"10 - Reduced inequalities"}
                                    checked={values.globalGoals.includes("10 - Reduced inequalities")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"

                                />}
                                label="10 - Reduced inequalities"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"11 - Sustainable cities and communities"}
                                    checked={values.globalGoals.includes("11 - Sustainable cities and communities")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="11 - Sustainable cities and communities"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"12 - Responsible consumption and production"}
                                    checked={values.globalGoals.includes("12 - Responsible consumption and production")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="12 - Responsible consumption and production"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"13 - Climate action"}
                                    checked={values.globalGoals.includes("13 - Climate action")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="13 - Climate action"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"14 - Life below water"}
                                    checked={values.globalGoals.includes("14 - Life below water")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="14 - Life below water"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"15 - Life on land"}
                                    checked={values.globalGoals.includes("15 - Life on land")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="15 - Life on land"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"16 - Peace, justice and strong institutions"}
                                    checked={values.globalGoals.includes("16 - Peace, justice and strong institutions")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="16 - Peace, justice and strong institutions"

                            />
                            <FormControlLabel
                                control={<Checkbox
                                    value={"17 - Partnerships for the goals"}
                                    checked={values.globalGoals.includes("17 - Partnerships for the goals")}
                                    onChange={handleChangeLocal}
                                    name="globalGoals"
                                    //name="checked"
                                    color="primary"
                                />}
                                label="17 - Partnerships for the goals"

                            />

                        </FormGroup>
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">5. Describe the target group for your product / service, who is your customer?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="customers"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.customers}
                            onBlur={() => { validateInput('customers') }}
                            error={isCustomersInvalid}
                            helperText={isCustomersInvalid && "Answer is required"}

                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">6. Have you tested your business idea?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="demand"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.demand}
                            onBlur={() => { validateInput('demand') }}
                            error={isDemandInvalid}
                            helperText={isDemandInvalid && "Answer is required"}

                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">7. If you launched your product / service today, who would be your three biggest competitors?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="competitors"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.competitors}
                            onBlur={() => { validateInput('competitors') }}
                            error={isCompetitorsInvalid}
                            helperText={isCompetitorsInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <h6 style={{ fontWeight: 'bold', marginBottom: '10px', }}> Team:</h6>
                        <FormLabel className="ideas-input-label">1. In what way does the teams's knowledge and experience contribute to the project?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="aloneORteam"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.aloneORteam}
                            onBlur={() => { validateInput('aloneORteam') }}
                            error={isAloneORteamInvalid}
                            helperText={isAloneORteamInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel className="ideas-input-label">2. Describe one or more future team members who could contribute to the development of the project?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="competences"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.competences}
                            onBlur={() => { validateInput('competences') }}
                            error={isCompetencesInvalid}
                            helperText={isCompetencesInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel component="legend" className="ideas-input-label">3. How much time (in total for the whole team) can you spend on the project each week? For example: 10 hours a week.</FormLabel>

                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="timeANDresources"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.timeANDresources}
                            onBlur={() => { validateInput('timeANDresources') }}
                            error={isTimeANDresourcesInvalid}
                            helperText={isTimeANDresourcesInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel component="legend" className="ideas-input-label">4. What are you working on right now?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="challenge"
                            rows={4}
                            onChange={handleChangeLocal}
                            value={values.challenge}
                            onBlur={() => { validateInput('challenge') }}
                            error={isChallengeInvalid}
                            helperText={isChallengeInvalid && "Answer is required"}
                        />
                    </FormControl>

                    <FormControl component="fieldset" required className="ideas-input-field">
                        <FormLabel component="legend" className="ideas-input-label">5. What is the next step in the project?</FormLabel>
                        <TextField
                            id="outlined-multiline-static"
                            label="Answer"
                            multiline
                            name="nextStep"
                            rows={4}
                            onBlur={() => { validateInput('nextStep') }}
                            onChange={handleChangeLocal}
                            value={values.nextStep}
                            error={isNextStepInvalid}
                            helperText={isNextStepInvalid && "Answer is required"}

                        />
                    </FormControl>
                </Stack>

                <div className="backConfirm" style={{ marginTop: '20px' }}>
                    <FormHelperText error={isGeneralInvalid}>{isGeneralInvalid ? ("Please fill the required fields.") : ("")}</FormHelperText>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {isLoading || isSaveLoading ? (
                        <Box sx={{ maxWidth: '310px', minWidth: '260px' }}>
                            <LinearProgress color='info' />
                        </Box>
                    ) : (
                        <Box sx={{ marginBottom: "4px" }}>

                        </Box>
                    )}
                </div>

                <div className="backConfirm" style={{ paddingBottom: '10px' }}>
                    <Button variant="contained" onClick={saveProgress} disabled={isSaveLoading} style={{ marginRight: 40 }}>
                        Save
                    </Button>
                    <Button
                        disabled={isSaveLoading || isGeneralInvalid}
                        variant="contained"
                        type="submit"
                    >
                        Save & Continue
                    </Button>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', color: '#28a745' }}>
                    <i>{autoSave !== '' ? ('Last save: ' + autoSave) : ('')}</i>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '10px'}}>
                    <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                </div>
            </form>
            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </div>
    );
};

export default IdeasStep;