import React, { Fragment, useState } from 'react'
import Stack from '@mui/material/Stack'
import { createTimeSlot } from '../../firebase/cloudFunctions';
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import AvailableDates from '../HomeBD/AvailableDates';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Divider, IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';
import { generateTimeslots } from '../../helper/timeslotGenerator';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DatePickerTimeslot() {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [selectedTimeFrom, setSelectedTimeFrom] = useState('');
    const [selectedTimeTo, setSelectedTimeTo] = useState('');

    const [selectedDate, setSelectedDate] = useState('');

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    // call the cloud function
    const submitTimeSlot = () => {
        setIsLoading(true);
        let selectedFullDate = selectedDate['$d'].toDateString();
        let startDate = new Date(Date.parse(selectedFullDate + " " + selectedTimeFrom));
        let endDate = new Date(Date.parse(selectedFullDate + " " + selectedTimeTo))

        createTimeSlot({
            startTime: startDate.toUTCString(),
            endTime: endDate.toUTCString(),
        })
            .then(() => {
                setSelectedDate(null);
                setSelectedTimeFrom('');
                setSelectedTimeTo('');
                setOpenSnackbar(true);
            })
            .catch(() => {
                setError(true);
                setOpenSnackbar(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleDateChange = (newValue) => {
        //console.log(new Date(newValue).toDateString() + " " + new Date(selectedTime).toTimeString());
        if (newValue != null) {
            setSelectedDate(newValue)
        }
        else {
            setSelectedDate(null)
        }
    };

    const handleTimeChangeFrom = (newValue) => {
        //console.log(newValue);
        if (selectedTimeTo != null && selectedTimeTo !== "") {
            if (selectedTimeTo < newValue) {
                setSelectedTimeFrom(selectedTimeTo);
                setSelectedTimeTo(newValue);
            }
            else {
                setSelectedTimeFrom(newValue);
            }
        }
        else {
            setSelectedTimeFrom(newValue);
        }
    };

    const handleTimeChangeTo = (newValue) => {

        if (selectedTimeFrom != null && selectedTimeFrom !== "") {
            //console.log("Time: ", selectedTimeFrom, newValue, selectedTimeFrom > newValue)
            if (selectedTimeFrom > newValue) {
                setSelectedTimeTo(selectedTimeFrom);
                setSelectedTimeFrom(newValue);
            }
            else {
                setSelectedTimeTo(newValue);
            }
        }
        else {
            setSelectedTimeTo(newValue);
        }
    };

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: "5px" }}>Create new timeslot</div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack spacing={3}>
                        <DatePicker
                            label="Date"
                            value={selectedDate}
                            disablePast
                            inputFormat={'dddd YYYY-MM-DD'}
                            onChange={handleDateChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    size="small"
                                    fullWidth
                                    error={false}
                                />}
                        />

                        <Stack direction="row" spacing={1} alignItems="center">
                            <Autocomplete
                                freeSolo
                                size="small"
                                disableClearable
                                value={selectedTimeFrom}
                                onChange={(event, newValue) => handleTimeChangeFrom(newValue)}
                                options={generateTimeslots().map((option) => option.title)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Time"
                                        sx={{ width: 140 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                            <ArrowRightAltIcon />
                            <Autocomplete
                                freeSolo
                                size="small"
                                disableClearable
                                value={selectedTimeTo}
                                onChange={(event, newValue) => handleTimeChangeTo(newValue)}
                                options={generateTimeslots().map((option) => option.title)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Time"
                                        sx={{ width: 140 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {isLoading ? (
                        <Box sx={{mt: '10px'}}>
                            <LinearProgress sx={{ width: '132px' }} />
                        </Box>
                    ) : (
                        <Box sx={{mt: '10px', height: '4px'}}>

                        </Box>
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{mt: '4px'}}>
                        <Button
                            //size="medium"
                            variant="contained"
                            //color="primary"
                            disabled={(selectedDate == null || selectedDate === "") ||
                                (selectedTimeFrom == null || selectedTimeFrom === "") ||
                                (selectedTimeTo == null || selectedTimeTo === "") ||
                                isLoading === true}
                            onClick={submitTimeSlot}>Add timeslot</Button>
                    </Box>
                </div>
                <Divider sx={{mt: 3}}/>
                <Box>
                    <AvailableDates />
                </Box>
                {error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Timeslot is saved!</Alert>
                    </Snackbar>
                )}
            </LocalizationProvider>
        </Fragment>
    );
}
