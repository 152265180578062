import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import CommentsQuestionExternal from './CommentsQuestionExternal'
import { useAuth } from '../../contexts/AuthContext'
import { useGetExternalComments } from '../../hooks/commentData/useGetExternalComments';
import { BootstrapDialog, BootstrapDialogTitle } from '../../helper/bootstrapDialog';
import { Divider } from '@mui/material';

export default function CommentDialogExternal({ projID, projData, questionKey, location, step }) {
    const [open, setOpen] = useState(false);
    //const [comments, setComments] = useState([])
    const { currentUser } = useAuth();
    const { comments } = useGetExternalComments(projID, location, questionKey, "created");

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const showIcon = () => {
        if (currentUser.role >= 2 && comments?.length < 1) {
            return (
                <RecordVoiceOverRoundedIcon className="add_miro comment_icon_external" fontSize="small"
                    style={{
                        cursor: "pointer",
                        textAlign: "center",
                    }}
                    onClick={handleClickOpen}
                />
            )
        }
        else if (comments?.length > 0) {
            let approved = false;

            for (let index = 0; index < comments.length; index++) {
                const comment = comments[index];

                 if (comment.approved === true) {
                    approved = true;
                }
            }

            if (approved === true) {
                return (
                    <RecordVoiceOverRoundedIcon className="add_miro comment_icon_external positive_feedback" fontSize="small"
                        style={{
                            cursor: "pointer",
                            textAlign: "center",

                        }}
                        onClick={handleClickOpen}
                    />
                )
            }
            else {
                return (
                    <RecordVoiceOverRoundedIcon className="add_miro comment_icon_external negative_feedback" fontSize="small"
                        style={{
                            cursor: "pointer",
                            textAlign: "center",

                        }}
                        onClick={handleClickOpen}
                    />
                )

            }
        }
    }

    return (
        <div>
            {showIcon()}

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                minWidth='lg'
                maxWidth='xl'
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    <h6 style={{ marginTop: 15, marginLeft: 15, }}>Feedback for {projData.value}</h6>
                </BootstrapDialogTitle>
                <Divider sx={{ borderColor: '#4eacc1' }} />
                <DialogContent dividers>
                    <CommentsQuestionExternal projID={projID} project={projData} questionKey={questionKey} step={step} location={location} comments={comments} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
