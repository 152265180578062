import { collection, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'


export const useGetProjects = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const getAllProjects = async () => {
            const ref = onSnapshot(collection(db, "Projects"), (querySnapshot) => {
                let arr = [];
                querySnapshot.forEach((doc) => {
                    arr.push({ id: doc.id, value: doc.data().projectName, bdowner: doc.data().BDOwner, deadline: doc.data().nextDeadline, miroLink: doc.data().miroLink, currentStep: doc.data().currentStep, iboOwner: doc.data().IBOOwner, existingComp: doc.data().existingComp, website: doc.data().website, isActive: doc.data().isActive });
                });
                setProjects(arr)
            });

            firebaseRefList.push(ref);
        }
        getAllProjects()
    }, []);

    return projects;
};