import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from "../../contexts/AuthContext"
import useGetUserProject from '../../hooks/userData/useGetUserProject'
import CircularProgress from '@mui/material/CircularProgress'
import useGetUserData from '../../hooks/userData/useGetUserData'
import { Fragment, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import IBOStepper from '../Stepper/iboStepper'
//import Grading from './Grading'

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { project, loading } = useGetUserProject()
    //const { userData, loadingUser } = useGetUserData();
    const { currentUser } = useAuth();
    const { userData, loading: loadingData } = useGetUserData();
    const [projectData, setProjectData] = useState({})
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        if (currentUser != null) {
            if (currentUser.role === 2 || currentUser.role === 3) {
                if (location.state != null && location.state.projectData != null) {
                    setProjectData(location.state.projectData);
                    setIsLoading(false);
                }
                else {
                    navigate("/home-bd", { replace: true });
                }
            }
            else {
                if (loadingData === false) {
                    if (userData != null && Object.keys(userData).length > 0) {
                        // @ts-ignore
                        if (userData.isSecondary === true) {
                            // @ts-ignore
                            if (userData.dataSet === 0) {
                                navigate("/invited", { replace: true });
                            }
                        }
                        // @ts-ignore
                        else if (userData.dataSet == null || userData.dataSet !== 5) {
                            navigate("/set-up-account", { replace: true });
                        }

                        if (project != null && project.data != null) {
                            // @ts-ignore
                            setProjectData({ id: project.id, value: project.data.projectName, miroLink: project.data.miroLink, iboOwner: project.data.IBOOwner, deadline: project.data.nextDeadline, existingComp: project.data.existingComp, website: project.data.website })
                            if (project.data.isActive === false){
                                navigate("/inactive", { replace: true });
                            }
                            
                            
                            setIsLoading(false);
                        }
                    }
                }
            }
        }
        else {
            navigate("/login", { replace: true });
        }


    }, [loadingData, currentUser, loading, userData])



    if (isLoading) {
        return <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
    }

    return (
        <>
            <div className="home-container">
                {projectData != null && currentUser.role >= 2 ? (
                    <Box>
                        <span>IBO: <strong>{projectData.value} {projectData.value?.projectName}</strong></span>
                        {projectData?.isActive === false ? (
                            <div style={{ color: 'grey' }}>
                                <i>Inactive</i>
                            </div>

                        ) : (
                            <div>
                                <strong>Active</strong>
                            </div>
                        )}
                    </Box>


                ) : ("")}
            </div>

            <>
                <div>
                    <IBOStepper data={projectData} />
                </div>

            </>
        </>
    )
}

export default Home