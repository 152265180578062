import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from '@mui/material/FormLabel';
import { entryStepQuestions } from '../../firebase/cloudFunctions';
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { ErrorAlert } from "../../helper/errorAlert";

//const entryStepQuestions = firebase.app().functions("europe-west1").httpsCallable('entryStepQuestions');

// Destructuring props
const UpdateTeam = ({ projectID, teamData, closeModal }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [oldChallenge] = useState(setData('challenge'));
    const [oldAloneORteam] = useState(setData('aloneORteam'));
    const [oldCompetences] = useState(setData('competences'));
    const [oldTimeANDresources] = useState(setData('timeANDresources'));
    const [oldNextStep] = useState(setData('nextStep'));
    const [challenge, setChallenge] = useState(setData('challenge'));
    const [aloneORteam, setAloneORteam] = useState(setData('aloneORteam'));
    const [competences, setCompetences] = useState(setData('competences'));
    const [timeANDresources, setTimeANDresources] = useState(setData("timeANDresources"));

    const [nextStep, setNextStep] = useState(setData('nextStep'));


    function dataUpdated() {
        if (
            oldChallenge === challenge &&
            oldAloneORteam === aloneORteam &&
            oldCompetences === competences &&
            oldTimeANDresources === timeANDresources &&
            oldNextStep === nextStep
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    function setData(type) {
        if (teamData != null) {
            let teamObject = teamData.find(obj => obj.id === type);

            if (teamObject != null) {
                return teamObject.answerData?.answer;;
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }


    const handleChange = (event) => {
        const value = event.target.value;

        switch (event.target.name) {
            case "challenge":
                setChallenge(value);
                break;
            case "aloneORteam":
                setAloneORteam(value);
                break;
            case "competences":
                setCompetences(value);
                break;
            case "timeANDresources":
                setTimeANDresources(value);
                break;
            case "nextStep":
                setNextStep(value);
                break;
            default:
                break;
        }
    }

    function collectData() {
        const dataToSend = [
            { title: "challenge", answer: challenge },
            { title: "aloneORteam", answer: aloneORteam },
            { title: "competences", answer: competences },
            { title: "timeANDresources", answer: timeANDresources },
            { title: "nextStep", answer: nextStep },
        ];

        return dataToSend;
    }

    const handleSubmit = e => {
        e.preventDefault();

        setIsLoading(true);
        console.log(projectID);

        entryStepQuestions({
            projectID: projectID,
            entryQuestions: collectData(),
            updateData: true,
        }).then(() => {
            closeModal();
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })
    };



    return (
        <Box className="update-ideas">
            <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                <CloseRoundedIcon /></IconButton>
            <div style={{ marginBottom: '20px' }}>
                <span><strong>Update team:</strong></span>
            </div>
            <Fragment>
                <form onSubmit={handleSubmit} id="project-form">
                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>1. In what way does the teams's knowledge and experience contribute to the project?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="aloneORteam"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={aloneORteam}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>2. Describe one or more future team members who could contribute to the development of the project?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="competences"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={competences}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>3. How much time (in total for the whole team) can you spend on the project each week? For example: 10 hours a week.</FormLabel>

                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="competences"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={timeANDresources}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>4. What are you working on right now?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="challenge"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={challenge}
                                />
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={12} lg={12} xl={12}>

                            <FormControl component="fieldset" required className="ideas-input-update">
                                <FormLabel>5. What is the next step in the project?</FormLabel>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Answer"
                                    multiline
                                    name="nextStep"
                                    rows={4}
                                    onChange={handleChange}
                                    defaultValue={nextStep}
                                />
                            </FormControl>
                        </Grid>


                    </Grid>

                    <div className="backConfirm" style={{ paddingTop: '10px' }}>
                        <LoadingButton
                            sx={{ width: '170px' }}
                            onClick={handleSubmit}
                            loading={isLoading}
                            loadingPosition="start"
                            variant="contained"
                            disabled={dataUpdated()}
                        >
                            Update Info
                        </LoadingButton>
                    </div>
                </form>
            </Fragment>
            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
};

export default UpdateTeam;