import { useState } from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert, FormHelperText, LinearProgress } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { bookTimeSlot } from '../../../firebase/cloudFunctions';
import { useGetAllAvailableTimes } from '../../../hooks/timeslotData/useGetAllAvailableTimes';
import { useGetUserProjectId } from '../../../hooks/userData/useGetUserProjectId';
import { ErrorAlert } from '../../../helper/errorAlert';
import { getAnalytics, logEvent } from 'firebase/analytics';

import textBubble from '../../../assets/images/tip-bubble.png'

function FirstMeetDatePicker() {
    const [alltimes] = useGetAllAvailableTimes();
    const { projectIDs } = useGetUserProjectId();
    const [timeSlot, setTimeSlot] = useState('');
    const [displayError, setDisplayError] = useState(false);
    const navigate = useNavigate()
    const analytics = getAnalytics();

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    //define `openSnackbar` state and the function to change it. The value is false by default
    const [openSnackbar, setOpenSnackbar] = useState(false);

    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);


    const handleChange = (event) => {
        setTimeSlot(event.target.value);
        setDisplayError(false);
    };

    //this function is called when the user closes the snackbar after getting an error (when the cloud function fails)
    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };


    const submitTimeSlot = async () => {
        if (timeSlot != null && timeSlot !== '') {
            setIsLoading(true);
            bookTimeSlot({
                timeSlotID: timeSlot,
                projectID: projectIDs[0],
                step: "meet1"
            }).then((data) => {
                logEvent(analytics, 'completed_signup_step', {
                    step: 4,
                    invited: false,
                })
                navigate("/home")
            }).catch((error) => {
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not book timeslot!');
                setError(true)
            }).finally(() => {
                setIsLoading(false);
            })
        }
        else {
            setDisplayError(true)
        }
    };


    return (
        <Box mt="3rem" mb="6rem" >
            <Grid container alignItems="center" direction="column">
                <span className="book-a-meeting-text" style={{ marginBottom: '20px', marginTop: '20px' }}>Book a meeting with one of our business designers</span>
                <Box className="book-a-meeting-box">
                    <FormControl fullWidth>
                        <InputLabel id="combo-box-demo">Available times</InputLabel>
                        <Select
                            id="combo-box-demo"
                            label="Available meetings"
                            value={timeSlot}
                            onChange={handleChange}
                        >

                            {alltimes.map((allavail) => {
                                return (
                                    <MenuItem
                                        key={allavail.id}
                                        value={allavail.id}>{allavail.startTime}-{allavail.endTime} </MenuItem>
                                )
                            })
                            }
                        </Select>

                    </FormControl>
                    <Box mt="1rem">

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                            {isLoading ? (
                                <Box sx={{ maxWidth: '310px', minWidth: '260px' }}>
                                    <LinearProgress color='info' />
                                </Box>
                            ) : (
                                <Box sx={{ marginBottom: "4px" }}>

                                </Box>
                            )}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                            <Button
                                disabled={displayError || timeSlot == null || timeSlot === ''}
                                onClick={submitTimeSlot}
                                variant="contained"
                                color="primary"
                            >
                                Complete
                            </Button>
                        </div>
                        <div className="backConfirm">
                            <FormHelperText error={displayError}>{displayError ? ("Please select a timeslot!") : ("")}</FormHelperText>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '10px'}}>
                    <img src={textBubble} alt="Tip! You can save and resume your sign up at a later time." height={'190px'} />
                </div>
                    </Box>
                </Box>
            </Grid>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );

}

export default FirstMeetDatePicker;