import { Fragment, useState } from 'react'
import '../../assets/scss/app.scss'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { writeNote, deleteNote, editNote } from '../../firebase/cloudFunctions';
import { useAuth } from '../../contexts/AuthContext'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useGetExternalComments } from '../../hooks/commentData/useGetExternalComments'
import { Grid, IconButton, LinearProgress } from '@mui/material';
import { ErrorAlert } from '../../helper/errorAlert';
import EditIcon from '@mui/icons-material/Edit';
import { useGetExternalCommentTexts } from '../../hooks/languageHooks/useGetExternalCommentTexts';

const CommentLogMeetNotes = ({ project, projID, step, location }) => {
    const { currentUser } = useAuth()
    const { comments } = useGetExternalComments(projID, location, "undefined", 'created');
    const sentences = useGetExternalCommentTexts();
    
    //define `isLoading` state and the function to change it. The value is false by default
    const [isLoading, setIsLoading] = useState(false);
    const [commentVal, setCommentVal] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [editComment, seteditComment] = useState({});


    const submitComment = async () => {
        //set `isLoading` state to true, so the spinner is rendered
        setIsLoading(true);

        // call the cloud function. 
        writeNote({
            assignedID: "undefined",
            projectID: project.id,
            location: location,
            step: step,
            isExternal: true,
            message: commentVal,
        })
            .then(() => {
                setCommentVal('');
            })
            .catch((error) => {
                console.error(error);
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not add comment!');
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const submiteditComment = () => {
        if (editComment != null) {
            setIsLoading(true);

            const data = {
                messageID: editComment.id,
                projectID: projID,
                isExternal: true,
                message: commentVal,
                location: location,
                step: step,
            }

            editNote(data).then(() => {
                seteditComment({});
                setCommentVal('')
                setIsEdit(false);
            }).catch((error) => {
                console.error(error);
                setErrorInfo(error)
                setErrorMsg('Something went wrong! Could not edit comment!');
                setError(true);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    const editCommentFunc = (comment) => {
        console.log("Edit: ", comment, projID);
        seteditComment(comment);
        setCommentVal(comment?.message)
        setIsEdit(true);
    };

    const canceleditCommentFunc = () => {
        seteditComment({});
        setCommentVal('')
        setIsEdit(false);
    };


    const deleteComment = async (comments) => {
        setIsLoading(true);

        // call the cloud function. 
        deleteNote({
            messageID: comments.id,
            projectID: project.id,
            isExternal: true,
        })
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                setErrorInfo(error);
                setErrorMsg('Something went wrong! Could not delete comment.');
                setError(true);
                setIsLoading(false);
            })
    };


    return (
        <>
            {(comments.length > 0 && currentUser.role === 1) || currentUser.role >= 2 ? (
                <div className="meet-comments">
                    {currentUser.role === 1 ? (
                        <p style={{ marginLeft: "10px" }}><strong>{sentences.headerMeetIBO}</strong></p>
                    ) : (
                        <p style={{ marginLeft: "10px" }}><strong>{sentences.headerMeet}</strong></p>
                    )}


                    <div className="meet-comment-log-box">
                        {comments.map(comment =>
                            <Box key={comment.id}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <span className="comment_username">
                                            {comment.createdUserName}
                                        </span>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <span style={{ float: 'right' }}>
                                            {currentUser && currentUser.uid === comment.createdBy && (
                                                <Stack direction={'row'} alignItems={'center'}>
                                                    <IconButton onClick={() => { editCommentFunc(comment) }} size={'small'}>
                                                        <EditIcon
                                                            fontSize="small"
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => { deleteComment(comment) }} size={'small'}>
                                                        <HighlightOffOutlinedIcon
                                                            style={{
                                                                fontSize: "13px",
                                                            }}
                                                        />
                                                    </IconButton>

                                                </Stack>
                                            )}
                                        </span>
                                    </Grid>
                                </Grid>


                                <p className="comment-ibo-box">
                                    {comment.message}
                                </p>
                            </Box>
                        )}
                    </div>

                    {currentUser.role >= 2 &&
                        <form>



                            {isEdit ? (
                                <Fragment>
                                    <Stack spacing={0} direction="column">
                                        <Stack direction={'row'} justifyContent="center">
                                            <TextField
                                                value={commentVal}
                                                onChange={(e) => { setCommentVal(e.target.value) }}
                                                id='standard-textarea'
                                                variant="standard"
                                                label='Add note here..'
                                                multiline
                                                style={{ width: '98%', alignSelf: 'center' }}
                                            />
                                            <Button size="small" className="post_comment_btn" variant="text"
                                                color="primary"
                                                disabled={isLoading}
                                                onClick={canceleditCommentFunc}
                                            >Cancel</Button>
                                        </Stack>

                                        {isLoading ? (
                                            <Box sx={{ marginTop: '3px', marginBottom: '-5px' }}>
                                                <LinearProgress />
                                            </Box>
                                        ) : (
                                            <Box sx={{ height: '2px' }}>

                                            </Box>
                                        )}

                                        <Button size="medium" className="post_note_comment_btn" variant="contained"
                                            color="primary"
                                            disabled={isLoading || commentVal.length < 1}
                                            onClick={submiteditComment}
                                        >Update note</Button>
                                    </Stack>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Stack spacing={0} direction="column">
                                        <Stack direction={'row'} justifyContent="center">
                                            <TextField
                                                value={commentVal}
                                                onChange={(e) => { setCommentVal(e.target.value) }}
                                                id='standard-textarea'
                                                variant="standard"
                                                label='Add note here..'
                                                multiline
                                                style={{ width: '98%', alignSelf: 'center' }}
                                            />
                                        </Stack>
                                        {isLoading ? (
                                            <Box sx={{ marginTop: '3px', marginBottom: '-5px' }}>
                                                <LinearProgress />
                                            </Box>
                                        ) : (
                                            <Box sx={{ height: '2px' }}>

                                            </Box>
                                        )}
                                        <Button size="medium" className="post_note_comment_btn" variant="contained"
                                            color="primary"
                                            disabled={isLoading || commentVal === ""}
                                            onClick={submitComment}
                                        >Post note</Button>
                                    </Stack>
                                </Fragment>


                            )}

                        </form>
                    }
                </div>
            ) : ("")}

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </>
    )
}

export default CommentLogMeetNotes
