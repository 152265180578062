import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useState, useEffect } from 'react'
import { db } from '../../firebase'
import firebaseRefList from '../../firebase/firebaseRefList'

export const useGetBdOwners = () => {
    const [bdowners, setBDowners] = useState([]);

    useEffect(() => {
        const getAllBDowners = async () => {
            const ref = onSnapshot(query(collection(db, "Users"), where('role', ">=", 2)),
                (querySnapshot) => {
                    let arr = [];
                    querySnapshot.forEach((doc) => {
                        if (doc.data().role >= 2 && doc.data().disabled !== true) {
                            let fullUserName = doc.data().firstName + " " + doc.data().lastName
                            arr.push({ id: doc.id, value: fullUserName, firstName: doc.data().firstName, lastName: doc.data().lastName, email: doc.data().email })
                        }
                    });
                    setBDowners(arr)
                });

            firebaseRefList.push(ref);
        }
        getAllBDowners()
    }, []);

    return bdowners;
};