import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { updateCompInfo } from '../../firebase/cloudFunctions';
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Autocomplete, Button, Divider, LinearProgress } from "@mui/material";
import { COMPANY_CHOICES } from "../../constants/lang/eng/fields";
import { ErrorAlert } from "../../helper/errorAlert";
import { COUNTY_LIST, REGION_LIST } from "../../constants/lang/general/regions";

//const updateCompInfo = firebase.app().functions("europe-west1").httpsCallable('updateCompInfo');

// Destructuring props
const UpdateCompInfo = ({ projectData, compInfo, closeModal }) => {
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState(setYearInit());

    const [oldYear] = useState(setYearInit());
    const [oldProjName] = useState(setData('projName'))
    const [oldWebsite] = useState(setData('website'))
    const [oldExistingComp] = useState(setData('existingComp'))
    const [oldCompForm] = useState(setData('compForm'));
    const [oldCompName] = useState(setData('compName'));
    const [oldOrgNum] = useState(setData('orgNum'));
    const [oldCompCity] = useState(setData('compCity'));
    const [oldRegion] = useState(setData('region'));
    const [oldCounty] = useState(setData('county'));
    const [oldCompRole] = useState(setData('compRole'));
    const [oldRevenue] = useState(setData('revenue'));

    const [projName, setProjName] = useState(setData('projName'))
    const [website, setWebsite] = useState(setData('website'))
    const [existingComp, setExistingComp] = useState(setData('existingComp'))
    const [compForm, setCompForm] = useState(setData('compForm'));
    const [compName, setCompName] = useState(setData('compName'));
    const [orgNum, setOrgNum] = useState(setData('orgNum'));
    const [compCity, setCompCity] = useState(setData('compCity'));
    const [region, setRegion] = useState(setData('region'));
    const [county, setCounty] = useState(setData('county'));
    const [compRole, setCompRole] = useState(setData('compRole'));
    const [revenue, setRevenue] = useState(setData('revenue'));

    function dataUpdated() {
        if (oldYear !== "") {
            if (year !== "") {
                if (oldProjName === projName &&
                    oldWebsite === website &&
                    oldExistingComp === existingComp &&
                    oldCompForm === compForm &&
                    oldCompName === compName &&
                    oldOrgNum === orgNum &&
                    oldCompCity === compCity &&
                    oldCompRole === compRole &&
                    oldRevenue === revenue &&
                    oldCounty === county &&
                    oldRegion === region &&
                    // @ts-ignore
                    year.getFullYear() === oldYear.getFullYear()) {
                    return true;
                }
                else {
                    //console.log("False")
                    return false;
                }
            }
            else {
                if (oldProjName === projName &&
                    oldWebsite === website &&
                    oldExistingComp === existingComp &&
                    oldCompForm === compForm &&
                    oldCompName === compName &&
                    oldOrgNum === orgNum &&
                    oldCompCity === compCity &&
                    oldCounty === county &&
                    oldRegion === region &&
                    oldCompRole === compRole &&
                    oldRevenue === revenue) {
                    return true;
                }
                else {
                    console.log("False")
                    return false;
                }
            }

        }

    }

    function setYearInit() {
        let newDate = new Date(Date.now());
        if (compInfo != null && compInfo['founded'] != null) {
            const year = compInfo['founded'];
            newDate.setFullYear(Number(year))
            return newDate;
        }
        else {
            return "";
        }
    }

    function setData(type) {
        if (type === 'projName') {
            return projectData.value;
        }
        else if (type === 'region') {
            if (compInfo['compCity'] != null) {
                let value = String(compInfo['compCity']).split(';');
                if (value.length > 1) {
                    return value[1];
                }
                else {
                    return value[0];
                }
            }
            else {
                return '';
            }
        }
        else if (type === 'county') {
            if (compInfo['compCity'] != null) {
                let value = String(compInfo['compCity']).split(';');
                if (value.length > 1) {
                    return value[0];
                }
                else {
                    return '';
                }

            }
            console.log("Region:", compInfo['compCity'])
        }
        else if (type === 'existingComp') {
            if (projectData != null) {
                return projectData.existingComp;
            }
            else {
                return '';
            }

        }
        else if (type === 'website') {
            if (projectData != null) {
                return projectData.website;
            }
            else {
                return '';
            }

        }
        else if (compInfo != null && compInfo[type] != null) {
            return compInfo[type];
        }
        else {
            return '';
        }
    }

    const handleChange = (event) => {
        const value = event.target.value;

        switch (event.target.name) {
            case "existingComp":
                setExistingComp(value);
                break;
            case "projName":
                setProjName(value);
                break;
            case "website":
                setWebsite(value);
                break;
            case "compForm":
                setCompForm(value);
                break;
            case "compName":
                setCompName(value);
                break;
            case "orgNum":
                setOrgNum(value);
                break;
            case "compCity":
                setCompCity(value);
                break;
            case "region":
                console.log("Val: ", value)
                setRegion(value.label);
                break;
            case "county":
                console.log("Val: ", value)
                setCounty(value.label);
                break;
            case "compRole":
                setCompRole(value);
                break;
            case "revenue":
                setRevenue(value);
                break;
            default:
                break;
        }
    }

    const onChangeYear = (value) => {
        setYear(value)

    }

    const handleSubmit = e => {
        e.preventDefault();

        setIsLoading(true);
        //console.log("Region: ", region)
        const data = {
            projectID: projectData.id,
            existingComp: existingComp,
        }

        if (projName != null && projName !== "") {
            data['projName'] = projName;
        }

        if (website != null && website !== "") {
            data['website'] = website
        }

        if (existingComp === COMPANY_CHOICES['yes_new'] || existingComp === COMPANY_CHOICES['yes_established']) {
            data['compForm'] = compForm;
            data['orgNum'] = orgNum;
            data['compName'] = compName;
            if (region != null && region !== "") {
                if (region === 'Skåne' && county != null && county !== "") {
                    data['compCity'] = county + ';' + region;
                }
                else {
                    data['compCity'] = region;
                }
            }
            else {
                data['compCity'] = compCity;
            }

            console.log(data['compCity'])
            if (existingComp === COMPANY_CHOICES['yes_established']) {
                if (year !== "") {
                    // @ts-ignore
                    data['founded'] = year.getFullYear();
                }

                data['compRole'] = compRole;
                data['revenue'] = revenue;
            }
        }


        updateCompInfo(data).then(() => {
            closeModal();
        }).catch((error) => {
            setErrorInfo(error);
            setErrorMsg('Something went wrong! Could not save data.');
            setError(true);
        }).finally(() => {
            setIsLoading(false);
        })
    };

    const handleChangeRegion = (value) => {
        console.log(value);
        handleChange({ target: { name: "region", value: value } });
    };

    const handleChangeCounty = (value) => {
        console.log(value);
        handleChange({ target: { name: "county", value: value } });
    };


    return (
        <Box className="update-comp-info">
            <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                <CloseRoundedIcon /></IconButton>
            <span><strong>Update company info:</strong></span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <form onSubmit={handleSubmit} id="project-form">
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                sx={{ marginBottom: '10px' }}
                                fullWidth
                                label="Project name"
                                name="projName"
                                type={'text'}
                                value={projName || ""}
                                onChange={handleChange}
                                margin="normal"
                            />

                            <TextField
                                fullWidth
                                className="website-input"
                                label="Website (if applicable)"
                                name="website"
                                placeholder="i.e: www.youradress.com"
                                value={website || ""}
                                onChange={handleChange}
                                margin="normal"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl required>
                                <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: '10px' }}>Are you developing this idea witin an existing company?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="existingComp"
                                    value={existingComp}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="No/Not yet" control={<Radio />} label="No/Not yet" />
                                    <FormControlLabel value="Yes, in a newly formed company based on the idea" control={<Radio />} label="Yes, in a newly formed company based on the idea" />
                                    <FormControlLabel value="Yes, within an established company" control={<Radio />} label="Yes, within an established company" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {existingComp === "Yes, in a newly formed company based on the idea" || existingComp === "Yes, within an established company" ? (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl required>
                                        <FormLabel>Company form</FormLabel>
                                        <RadioGroup
                                            name="compForm"
                                            value={compForm}
                                            onChange={handleChange}

                                        >
                                            <FormControlLabel value="Enskild firma (or equivalent)" control={<Radio />} label="Enskild firma (or equivalent)" />
                                            <FormControlLabel value="Handelsbolag/Kommanditbolag (or equivalent)" control={<Radio />} label="Handelsbolag / Kommanditbolag (or equivalent)" />
                                            <FormControlLabel value="Aktiebolag (or equivalent)" control={<Radio />} label="Aktiebolag (or equivalent)" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Company Name"
                                        name="compName"
                                        placeholder="Enter your company name"
                                        value={compName || ""}
                                        onChange={handleChange}
                                        margin="normal"
                                    />
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Organization number"
                                        name="orgNum"
                                        placeholder="Enter number"
                                        value={orgNum || ""}
                                        onChange={handleChange}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <Autocomplete
                                        disablePortal
                                        autoComplete
                                        value={region}
                                        options={REGION_LIST}
                                        onChange={(event, newValue) => {
                                            handleChangeRegion(newValue);
                                        }}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="Region (headquarter if multiple)" margin="normal" />}
                                    />

                                    {region?.label === "Skåne" || region === "Skåne" ? (
                                        <Autocomplete
                                            disablePortal
                                            autoComplete
                                            value={county}
                                            options={COUNTY_LIST}
                                            onChange={(event, newValue) => {
                                                handleChangeCounty(newValue);
                                            }}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="County" margin="normal" />}
                                        />) : ("")}
                                </Grid>

                                {existingComp === "Yes, within an established company" ? (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                views={['year']}
                                                label="Year founded"
                                                mask="____"
                                                value={year}
                                                onChange={onChangeYear}
                                                renderInput={(params) => <TextField {...params} margin="normal" fullWidth />}

                                            />

                                        </Grid>


                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Your role in the company"
                                                name="compRole"
                                                placeholder="Answer"
                                                value={compRole || ""}
                                                margin="normal"
                                                onChange={handleChange}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl required>
                                                <FormLabel id="demo-controlled-radio-buttons-group">Revenue (latest full fiscal year)</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="revenue"
                                                    value={revenue}
                                                    onChange={handleChange}
                                                >
                                                    <FormControlLabel value="&lt;500k SEK" control={<Radio />} label="&lt;500k SEK" />
                                                    <FormControlLabel value="501k - 2m SEK" control={<Radio />} label="501k - 2m SEK" />
                                                    <FormControlLabel value="2-10m SEK" control={<Radio />} label="2-10m SEK" />
                                                    <FormControlLabel value="&gt;10m SEK" control={<Radio />} label="&gt;10m SEK" />

                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>
                                ) : ("")}
                            </>
                        ) : ("")}


                    </Grid>


                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                        {isLoading ? (
                            <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                                <LinearProgress color="info" />
                            </Box>
                        ) : (
                            <Box sx={{ marginBottom: "4px" }}>

                            </Box>
                        )}
                    </div>
                    <div className="backConfirm">
                        <Button
                            onClick={handleSubmit}
                            sx={{ width: '170px' }}
                            disabled={isLoading || dataUpdated()}
                            variant="contained"
                        >
                            Update Info
                        </Button>
                    </div>
                </form>
            </LocalizationProvider>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
};

export default UpdateCompInfo;