import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal } from "@mui/material";
import { Fragment, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from "../../../contexts/AuthContext";
import { QUESTION_INFO, QUESTION_INFO_TEAM } from "../../../constants/lang/eng/fields";
import UpdateIdeas from "../../UpdateData/UpdateIdeas";
import UpdateTeam from "../../UpdateData/UpdateTeam";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";



export const DisplayEntryQuestions = ({ projectID, questionList, updateFrame }) => {
    const { currentUser } = useAuth();

    const [openModalIdea, setOpenModalIdea] = useState(false);
    const handleModalIdeaOpen = () => {
        setOpenModalIdea(true);
    }

    const handleModalIdeaClose = () => {
        updateFrame();
        setOpenModalIdea(false);
    }

    const [openModalTeam, setOpenModalTeam] = useState(false);
    const handleModalTeamOpen = () => {
        setOpenModalTeam(true);
    }
    const handleModalTeamClose = () => {
        updateFrame();
        setOpenModalTeam(false);
    }

    return (
        <Fragment>
            <Box sx={{ paddingBottom: '30px' }}>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>Business idea:</strong>

                        <IconButton
                            className="edit-icon-right"

                            onClick={handleModalIdeaOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>Business idea:</strong>
                    </span>
                )}

                {Object.keys(QUESTION_INFO).map((question, questionIndex) => {
                    let answer = questionList.find((val) => val.id === question);

                    if (answer != null) {
                        if (question === "globalGoals") {
                            return (
                                <List key={question} style={{ paddingLeft: '15px', width: '80%' }}>
                                    <StyledListItemEntryStep primary={QUESTION_INFO[question]} secondary={""} />
                                    {answer.answerData?.answer != null ? String(answer.answerData?.answer).split(';').map((answer, index) => {
                                        return (
                                            <ListItem key={questionIndex + "" + index}>
                                                <StyledListItemEntryStep primary={""} secondary={answer} />
                                            </ListItem>);
                                    }) : [""]}
                                    <Divider />
                                </List>
                            )
                        }
                        else {
                            return (
                                <List key={question} disablePadding style={{ width: '80%' }}>
                                    <ListItem key={question + "-questionText"}>
                                        <StyledListItemEntryStep primary={QUESTION_INFO[question]} secondary={answer.answerData?.answer != null ? answer.answerData?.answer : "No answer!"} />
                                    </ListItem>
                                    <Divider />
                                </List>)
                        }
                    }
                    else {
                        return (
                            <List key={question} disablePadding style={{ width: '80%' }}>
                                <ListItem key={question + "-questionText"}>
                                    <StyledListItemEntryStep primary={QUESTION_INFO[question]} secondary={"No answer!"} />
                                </ListItem>
                                <Divider />
                            </List>)
                    }
                })}
            </Box>
            <Box>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>Team:</strong>

                        <IconButton
                            className="edit-icon-right"
                            onClick={handleModalTeamOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>Team:</strong>
                    </span>
                )}

                {Object.keys(QUESTION_INFO_TEAM).map((question, questionIndex) => {
                    let answer = questionList.find((val) => val.id === question);

                    if (answer != null) {
                        if (question === "globalGoals") {
                            return (
                                <List key={question} style={{ paddingLeft: '15px', width: '80%' }}>
                                    <StyledListItemEntryStep primary={QUESTION_INFO_TEAM[question]} secondary={""} />
                                    {answer.answerData?.answer != null ? String(answer.answerData?.answer).split(';').map((answer, index) => {
                                        return (
                                            <ListItem key={questionIndex + "" + index}>
                                                <StyledListItemEntryStep primary={""} secondary={answer} />
                                            </ListItem>);
                                    }) : [""]}
                                    <Divider />
                                </List>
                            )
                        }
                        else {
                            return (
                                <List key={question} disablePadding style={{ width: '80%' }}>
                                    <ListItem key={question + "-questionText"}>
                                        <StyledListItemEntryStep primary={QUESTION_INFO_TEAM[question]} secondary={answer.answerData?.answer != null ? answer.answerData?.answer : "No answer!"} />
                                    </ListItem>
                                    <Divider />
                                </List>)
                        }
                    }
                    else {
                        return (
                            <List key={question} disablePadding style={{ width: '80%' }}>
                                <ListItem key={question + "-questionText"}>
                                    <StyledListItemEntryStep primary={QUESTION_INFO_TEAM[question]} secondary={"No answer!"} />
                                </ListItem>
                                <Divider />
                            </List>)
                    }
                })}
            </Box>
            <Modal
                open={openModalIdea}
                onClose={handleModalIdeaClose}
            >
                <UpdateIdeas projectID={projectID} ideasData={questionList} closeModal={handleModalIdeaClose} />
            </Modal>

            <Modal
                open={openModalTeam}
                onClose={handleModalTeamClose}
            >
                <UpdateTeam projectID={projectID} teamData={questionList} closeModal={handleModalTeamClose} />
            </Modal>
        </Fragment>
    )
};