import { useState } from "react"
import { Alert } from 'react-bootstrap'
import Box from '@mui/material/Box';
import { editBDUser } from '../../firebase/cloudFunctions';
import CircularProgress from "@mui/material/CircularProgress";

//const editBDUser = firebase.app().functions("europe-west1").httpsCallable('editBDUser');

const EditBD = (
    props,
) => {
    //console.log("Props: ", props);
    const [emailRef, setEmailRef] = useState(props.user.email);
    const [firstNameRef, setFirstNameRef] = useState(props.user.firstName);
    const [lastNameRef, setLastNameRef] = useState(props.user.lastName);

    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const changeEmail = (event) => {
        setEmailRef(event.target.value);
    }

    const changeFirstName = (event) => {
        setFirstNameRef(event.target.value);
    }

    const changeLastName = (event) => {
        setLastNameRef(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            setError("");
            setLoading(true);

            //console.log("Send:", firstNameRef);
            //console.log("Send:", lastNameRef);

            editBDUser({
                userID: props.user.id,
                firstName: firstNameRef,
                lastName: lastNameRef,
            }).then(() => {
                props.onClose();
            }).catch((error) => {
                setError("Failed to update user. " + error);
            }).finally(() => {
                setLoading(false);
            });

        } catch (e) {
            setError("Failed to create user. " + e);
            setLoading(false);
        }
    }

    return (
        <Box sx={style}>
            <div className="log-box" >

                <h3>Edit BD</h3>
                {error && <Alert variant="danger">{error}</Alert>}

                <form onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>Email:</label>
                        <input type="email" className="form-control" value={emailRef} placeholder="Enter email" disabled />
                    </div>

                    <div className="form-group">
                        <label>First Name:</label>
                        <input type="text" className="form-control" value={firstNameRef} onChange={changeFirstName} placeholder="Enter first name" required />
                    </div>

                    <div className="form-group">
                        <label>Last Name:</label>
                        <input type="text" className="form-control" value={lastNameRef} onChange={changeLastName} placeholder="Enter last name" required />
                    </div>


                    <br></br>

                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                    ) : (
                        <button disabled={loading} type="submit" className="btn btn-info btn-lg btn-block">Update account</button>
                    )}
                </form>

            </div>
        </Box>

    )
}

export default EditBD