import { useEffect, useState, forwardRef } from "react";
import { db } from '../../firebase';
import { assignMultipleTemplates, setNextTimeStep, setStepState } from '../../firebase/cloudFunctions';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom'
import parse from 'html-react-parser';
import Explanation from './Explanation'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactComponent as AssembledBricks } from '../../assets/images/krinova__AssembledBricks_line3_colour1.svg';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import firebaseRefList from '../../firebase/firebaseRefList'
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';

import moment from 'moment';
import { Box, CardContent, Stack, FormControl, Card, Checkbox, Grid, Radio, Select, Slider, FormControlLabel, FormGroup, Button, IconButton, InputLabel, MenuItem, Modal, Snackbar, TextField, Autocomplete, InputAdornment, Skeleton, LinearProgress } from "@mui/material";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetCategories } from "../../hooks/questionData/useGetCategories";
import { generateTimeslots } from "../../helper/timeslotGenerator";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { ISOTimeZoneCompensated } from "../../functions";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Questions = () => {
    const [columns, setColumns] = useState({})
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [error2, setError2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [deadlineLoading, setDeadlineLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openSnackbar2, setOpenSnackbar2] = useState(false);
    const location = useLocation()
    // @ts-ignore
    const { projectData, step, stepName } = location.state
    const [assignedTemplates, setAssignedTemplates] = useState([]);
    const navigate = useNavigate();

    const [selectedQuestions, setSelectedQuestion] = useState([]);

    const [allQuestions, setAllQuestions] = useState({});
    const [allTemplates, setAllTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([])
    const [valueNextDeadline, setValueNextDeadline] = useState(new Date());

    const [selectedTime, setSelectedTime] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTimeDate, setSelectedTimeDate] = useState("");

    const [openModal, setOpenModal] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [openModalBool, setOpenModalBool] = useState(false);
    const [noteData, setNoteData] = useState({})
    const [noteInfo, setNoteInfo] = useState("")

    const { groupCategories } = useGetCategories();

    //console.log(stepName)
    useEffect(() => {
        const getNextStepData = () => {
            setIsLoading(true);
            getDoc(doc(db, "Projects/" + projectData.id + "/Steps/" + step)).then((querySnapshot) => {
                if (querySnapshot.data() != null) {
                    if (querySnapshot.data().nextTime != null) {

                        //console.log("Hello ", querySnapshot.data().nextTime)
                        setValueNextDeadline(new Date(querySnapshot.data().nextTime.seconds * 1000));

                    }
                } else {
                    //console.log("No such document!");
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
        // return cleanup function

        getNextStepData();
    }, [projectData.id, step]);

    useEffect(() => {
        const ref = onSnapshot(collection(db, "Questions"), (querySnapshot) => {
            const getQuestionsFromFirebase = {};

            querySnapshot.forEach((doc) => {
                getQuestionsFromFirebase[doc.id] = {
                    ...doc.data(), //spread operator
                    key: doc.id, // `id` given to us by Firebase
                };
            });
            //console.log("Q: " + Object.keys(getQuestionsFromFirebase))
            setAllQuestions(getQuestionsFromFirebase)
        })

        const getQuestionData = async () => {
            const getAssignedTemplatesFromFirebase = [];
            const getTemplatesFromFirebase = [];

            const stepRef = "Projects/" + projectData.id + "/Steps/" + step;

            const promiseTemplates = getDocs(query(collection(db, "QuestionTemplates"), orderBy("createdAt", "desc")));
            const assignedPromises = getDocs(query(collection(db, stepRef + "/AssignedTemplates"), orderBy("order"))).then(async (assignedTemplatesSnap) => {
                const promises = [];

                assignedTemplatesSnap.forEach((doc) => {
                    const promise = getDocs(query(collection(db, stepRef + "/AssignedQuestions"), where("templateID", "==", doc.id), orderBy('order'))).then((questions) => {
                        let questionsLocal = [];
                        questions.forEach((question) => {
                            //console.log("Key: " + question.id);
                            questionsLocal.push({ key: question.data().questionID })
                        })

                        getAssignedTemplatesFromFirebase.push({
                            ...doc.data(), //spread operator
                            key: doc.id, // `id` given to us by Firebase
                            questions: questionsLocal,
                        });
                    });

                    promises.push(promise);
                });

                await Promise.all(promises);
                setAssignedTemplates(getAssignedTemplatesFromFirebase);
            });

            await Promise.all([promiseTemplates, assignedPromises]);

            await promiseTemplates.then((querySnapshot) => {
                const promises = [];

                querySnapshot.forEach((doc) => {
                    const promise = getDocs(query(collection(db, doc.ref.path + "/AssignedQuestions"), orderBy("order"))).then((questions) => {
                        let questionsLocal = [];
                        questions.forEach((question) => {
                            //console.log("Key: Temp " + question.key);

                            questionsLocal.push({ key: question.data().questionID })
                        })

                        getTemplatesFromFirebase.push({
                            ...doc.data(), //spread operator
                            key: doc.id, // `id` given to us by Firebase
                            questions: questionsLocal,
                        });
                    });

                    promises.push(promise);

                });

                return Promise.all(promises);
            });

            const usedTemplates = []
            const availableTemplates = [];

            getAssignedTemplatesFromFirebase.forEach((item) => {
                getTemplatesFromFirebase.forEach((template) => {
                    if (item.templateID === template.key) {
                        usedTemplates.push(template);
                    }
                })
            })

            getTemplatesFromFirebase.forEach((item) => {
                if (!usedTemplates.includes(item)) {
                    availableTemplates.push(item);
                }
            })

            setAllTemplates(getTemplatesFromFirebase);
            setSelectedTemplates(usedTemplates);

            const columnsFromBackend = {
                questionColumn: {
                    name: "Content",
                    items: availableTemplates
                },
                selectedColumn: {
                    name: "Form",
                    items: usedTemplates
                },
            };

            setColumns(columnsFromBackend);
            setLoading(false);
        }

        getQuestionData();
        firebaseRefList.push(ref)

    }, []); // empty dependencies array => useEffect only called once



    function fillList(items) {
        let selectedItems = [];
        setSelectedTemplates(items)
        items.forEach((item) => {
            selectedItems.push({ key: item.key })
        })
        //console.log(selectedItems)
        return selectedItems
    }

    function sortList(sortOption) {
        //console.log(sortOption);
        const availList = [];
        //console.log("Lista: " + allQuestions.length);
        if (sortOption === "All") {
            allTemplates.forEach(element => {
                if (!selectedQuestions.includes(element)) {
                    availList.push(element)
                }
            });
        }
        else {
            allTemplates.forEach(element => {
                if (element.category === sortOption && !selectedQuestions.includes(element)) {
                    availList.push(element)
                }
            });
        }


        const columnsFromBackend = {
            questionColumn: {
                name: "Content",
                items: availList
            },
            selectedColumn: {
                name: "Group",
                items: selectedTemplates
            },
        };

        setColumns(columnsFromBackend);
    }

    const handleChange = (event) => {
        sortList(event.target.value);
    };

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }

            });

            let saveItems = destItems;
            if (source.droppableId === "selectedColumn") {
                saveItems = sourceItems;
            }

            fillList(saveItems)
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };
    const closeSnackbar2 = () => {
        setOpenSnackbar2(false);
    };

    // call the cloud function
    const handleAssignQuestion = async () => {
        setSaveLoading(true);

        assignMultipleTemplates({
            projectID: projectData.id,
            templates: fillList(columns?.selectedColumn?.items),
            step: step,
            noteData: noteData,
        });

        // eslint-disable-next-line eqeqeq
        if (selectedTimeDate != null && selectedTimeDate != "" && selectedDate != null && selectedDate !== "") {
            let selectedDeadline;
            // eslint-disable-next-line eqeqeq
            if (selectedTime == null || selectedTime == "") {
                selectedDeadline = new Date(selectedDate);
                selectedDeadline.setHours(23, 59, 0, 0);
            }
            else {
                selectedDeadline = new Date(Date.parse(selectedTimeDate));
            }

            setNextTimeStep({
                time: selectedDeadline.toISOString(),
                projectID: projectData.id,
                step: step,
            });
        }

        setStepState({
            projectID: projectData.id,
            stateType: 'IBOState',
            step: step,
            state: "Not Completed",
        })

        setStepState({
            projectID: projectData.id,
            stateType: 'BDState',
            step: step,
            state: "Unlocked",
        })
            .then(() => {
                setOpenSnackbar(true);
                navigate(goToStep(step), { replace: true, state: { projectData: projectData } })
                //setLoading(false);
            })
            .catch(() => {
                setError(true);

                //setLoading(false);

                setOpenSnackbar(true);
            });
    }

    function goToStep(step) {
        switch (step) {
            case 'b':
                return "/home#step3"
            case 'c':
                return "/home#step5"
            case 'd':
                return "/home#step7"
            case 'e':
                return "/home#step9"
            case 'f':
                return "/home#step11"
            default:
                return "/home"
        }
    }

    // call the cloud function
    const submitNextDeadline = () => {
        setDeadlineLoading(true);
        //console.log('Time:' + valueStart + valueEnd)
        setNextTimeStep({
            time: valueNextDeadline.toUTCString(),
            projectID: projectData.id,
            step: step,

        })
            .then(() => {
                setDeadlineLoading(false);

                setOpenSnackbar2(true);
            })

            .catch(() => {
                setError2(true);

                setDeadlineLoading(false);

                setOpenSnackbar2(true);
            });
    }


    //console.log('selected ' + columns.selectedColumn.items)
    function valuetext(value) {
        return `${value}`;
    }

    // function valueLabelFormat(value) {
    //     return marks.findIndex((mark) => mark.value === value) + 1;
    // }

    function createMarks(options) {
        let marks = [];

        String(options).split(';').forEach((option) => {
            marks.push({
                value: option,
                label: option,
            });
        })

        return marks;
    }

    /*
    const sliderChange = (event, value) => {
        console.log("Value: " + value);
    }*/

    function checkModalOpen(templateID, questionID) {
        // eslint-disable-next-line eqeqeq
        if (openModal[templateID] != undefined) {
            // eslint-disable-next-line eqeqeq
            if (openModal[templateID][questionID.key] != undefined) {
                return openModal[templateID][questionID.key];
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }

    }

    const handleModalOpen = (templateID, questionID) => {
        const localModal = openModal;
        // eslint-disable-next-line eqeqeq
        if (localModal[templateID] == undefined) {
            localModal[templateID] = {};
        }
        if (localModal[templateID][questionID.key] !== true) {
            localModal[templateID][questionID.key] = true;

            setOpenModal(localModal);
            setOpenModalBool(true);
        }
    }

    const handleModalClose = (templateID, questionID) => {
        const localModal = openModal;
        // eslint-disable-next-line eqeqeq
        if (localModal[templateID] == undefined) {
            localModal[templateID] = {};
        }
        localModal[templateID][questionID.key] = false;

        setOpenModal(localModal);
        setOpenModalBool(false);
    }

    function getNoteData(templateID, questionID) {
        //console.log("Template:", templateID);
        //console.log("QuestionID")
        // eslint-disable-next-line eqeqeq
        if (noteData[templateID] == undefined) {
            setNoteInfo("");
        } // eslint-disable-next-line eqeqeq
        else if (noteData[templateID][questionID.key] == undefined) {
            setNoteInfo("");
        }
        else {
            setNoteInfo(noteData[templateID][questionID.key]);
        }
    }

    const handleAddNote = (templateID, questionID, value) => {
        const localNoteData = noteData;
        // eslint-disable-next-line eqeqeq
        if (localNoteData[templateID] == undefined) {
            localNoteData[templateID] = {};
        }
        localNoteData[templateID][questionID.key] = value;

        setNoteData(localNoteData);
        handleModalClose(templateID, questionID);
    }

    const handleTimeChange = (newValue) => {
        //console.log(new Date(selectedDate).toDateString() + " " + new Date(newValue.time).toTimeString());
        //console.log("Time: ", newValue)
        if (newValue != null) {
            // @ts-ignore
            setSelectedTime(newValue);
            setSelectedTimeDate(new Date(selectedDate).toDateString() + " " + newValue);
        }
        else {
            setSelectedTime(null)
            setSelectedTimeDate(new Date(selectedDate).toDateString());
        }
    };

    const handleDateChange = (newValue) => {
        //console.log(new Date(newValue).toDateString() + " " + new Date(selectedTime).toTimeString());
        if (newValue != null) {
            setSelectedDate(newValue)
            setSelectedTimeDate(new Date(newValue).toDateString() + " " + selectedTime);
        }
        else {
            setSelectedDate(null)
            setSelectedTimeDate(new Date().toDateString() + " " + selectedTime);
        }
    };
    function mapTemplateQuestions(template) {
        const result = [];
        //console.log("Temp: ", template);
        template.questions.forEach(questionID => {
            const question = allQuestions[questionID.key]

            if (question.type === "TextInput") {
                result.push(
                    <>
                        <Card className="characters" key={question.key} sx={{ maxWidth: 705, marginBottom: '0rem', paddingBottom: '0rem' }}>
                            <CardContent>

                                {parse(question.text)} {question.perks !== "Infinity" ? (
                                    <IconButton
                                        onClick={() => {
                                            setLoading(true);
                                            handleModalOpen(template.key, questionID)
                                            getNoteData(template.key, questionID);
                                            setLoading(false);
                                        }}>

                                        <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                                    </IconButton>
                                ) : ("")} {question.questionExplanation || question.explanation !== "" ? (<Explanation key={question.key} data={question} />) : ('')}

                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { mb: '1rem', mt: '0.5rem', width: '100%' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    className="question_form_textfield"
                                >
                                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}

                                    {question.answer != null ? (<TextField id="standard-multiline-static"
                                        label=""
                                        defaultValue={question.answer || null}

                                        name={question.key}
                                        multiline
                                        rows={4}

                                        variant="standard" />) : (<TextField id="standard-multiline-static"
                                            label="Answer"

                                            name={question.key}
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="standard" />)}
                                </Box>


                            </CardContent>
                        </Card>
                    </>
                )
            }

            else if (question.type === "EmbededLinkInput") {
                result.push(
                    <Card className="characters" key={question.key} sx={{ maxWidth: 530, marginBottom: '0rem', paddingBottom: '0rem' }}>
                        <CardContent>
                            {question.questionExplanation || question.explanation !== "" || null ? (<Explanation key={question.key} data={question} />) : ('')} <iframe width="460" height="215" src={question.text} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </CardContent>
                        {question.perks !== "Infinity" ? (
                            <IconButton
                                onClick={() => {
                                    setLoading(true);
                                    handleModalOpen(template.key, questionID)
                                    getNoteData(template.key, questionID);
                                    setLoading(false);
                                }}>

                                <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                            </IconButton>
                        ) : ("")}
                    </Card>
                )
            }
            else if (question.type === "fileUpload") {
                //console.log("File: ", question)
                result.push(
                    <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem' }}>
                        <CardContent>
                            <span>{question.text}</span>
                            <div style={{ paddingTop: '20px', paddingLeft: '20px' }}>
                                <Button variant="outlined">Upload File</Button>
                            </div>

                            {question.perks !== "Infinity" ? (
                                <IconButton
                                    onClick={() => {
                                        setLoading(true);
                                        handleModalOpen(template.key, questionID)
                                        getNoteData(template.key, questionID);
                                        setLoading(false);
                                    }}>

                                    <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                                </IconButton>
                            ) : ("")}
                        </CardContent>


                    </Card>
                )
            }
            else if (question.type === "miro") {
                result.push(
                    <Card className="characters" key={question.key} sx={{ maxWidth: 730, marginBottom: '0rem', paddingBottom: '0rem' }}>
                        <CardContent>
                            <span>{question.text}</span>{question.perks !== "Infinity" ? (
                                <IconButton
                                    onClick={() => {
                                        setLoading(true);
                                        handleModalOpen(template.key, questionID)
                                        getNoteData(template.key, questionID);
                                        setLoading(false);
                                    }}>

                                    <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                                </IconButton>
                            ) : ("")}
                        </CardContent>


                    </Card>
                )
            }
            else if (question.type === "RichTextInput") {
                result.push(
                    <Card className="characters" key={question.key} sx={{ marginBottom: '0rem', paddingBottom: '0rem' }}>
                        <CardContent>
                            {question.explanation !== "" || null ? (<Explanation key={question.key} data={question} />) : ('')}
                            {parse(question.text)}  {question.perks !== "Infinity" ? (
                                <IconButton
                                    onClick={() => {
                                        setLoading(true);
                                        handleModalOpen(template.key, questionID)
                                        getNoteData(template.key, questionID);
                                        setLoading(false);
                                    }}>

                                    <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                                </IconButton>
                            ) : ("")}
                        </CardContent>


                    </Card>
                )
            } else if (question.type === "Option") {
                result.push(
                    <div style={{ marginLeft: '20px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={question.answer || false}
                                    name={question.key}
                                    //name="checked"
                                    color="primary"
                                    labelPlacement={question.labelPlacement}
                                />
                            }
                            label={parse(question.text)}
                        />

                        {question.perks !== "Infinity" ? (
                            <IconButton
                                onClick={() => {
                                    setLoading(true);
                                    handleModalOpen(template.key, questionID)
                                    getNoteData(template.key, questionID);
                                    setLoading(false);
                                }}>

                                <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                            </IconButton>
                        ) : ("")}
                    </div>

                )
            }
            else if (question.type === "Checked") {
                result.push(
                    <div style={{ marginLeft: '20px' }}>
                        <span style={{ display: 'block', marginTop: 10 }}>{parse(question.text)}</span>
                        {question.perks !== "Infinity" ? (
                            <IconButton
                                onClick={() => {
                                    setLoading(true);
                                    handleModalOpen(template.key, questionID)
                                    getNoteData(template.key, questionID);
                                    setLoading(false);
                                }}>

                                <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                            </IconButton>
                        ) : ("")}
                        {
                            String(question.options).split(';').map(element => (<FormControl component="fieldset">

                                <FormGroup aria-label="position" row>
                                    <FormControlLabel

                                        checked={question.answer || false}
                                        name={question.key}
                                        //name="checked"
                                        color="primary"
                                        control={<Checkbox />}
                                        label={parse(element)}
                                        labelPlacement={question.labelPlacement}
                                    />
                                </FormGroup>

                            </FormControl>

                            ))}

                        {question.perks}

                    </div>

                )
            }
            else if (question.type === "RadioButton") {
                result.push(
                    <>
                        <div style={{ marginLeft: '20px' }}>
                            <span style={{ display: 'inline-flex', marginTop: 10 }}>{parse(question.text)}</span>
                            {question.perks !== "Infinity" ? (
                                <IconButton
                                    onClick={() => {
                                        setLoading(true);
                                        handleModalOpen(template.key, questionID)
                                        getNoteData(template.key, questionID);
                                        setLoading(false);
                                    }}>

                                    <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                                </IconButton>
                            ) : ("")}
                        </div>
                        <div style={{ display: 'inline-block', marginTop: 5, marginLeft: '20px' }}>
                            {
                                String(question.options).split(';').map(element => (<FormControl component="fieldset">

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            checked={question.answer || false}
                                            aria-label="gender"
                                            name={question.key}
                                            control={<Radio />}
                                            labelPlacement={question.labelPlacement}
                                            label={parse(element)}
                                        />
                                    </FormGroup>
                                </FormControl>
                                ))}


                        </div>
                    </>
                )
            }
            else if (question.type === "Slider") {
                result.push(
                    <div style={{ marginLeft: '20px' }}>
                        <span style={{ display: 'inline-flex', marginTop: 10 }}>{parse(question.text)}</span>
                        {question.perks !== "Infinity" ? (
                            <IconButton
                                onClick={() => {
                                    setLoading(true);
                                    handleModalOpen(template.key, questionID)
                                    getNoteData(template.key, questionID);
                                    setLoading(false);
                                }}>

                                <NoteAddRoundedIcon style={{ width: "18px", height: "fit-content", color: "green" }} />
                            </IconButton>
                        ) : ("")}

                        <Box sx={{ width: 400 }}>
                            <Slider
                                aria-label="Restricted values"
                                //defaultValue={20}
                                // valueLabelFormat={valueLabelFormat(createMarks(assignedQuestion.options))}
                                getAriaValueText={valuetext}
                                step={null}
                                valueLabelDisplay="off"
                                marks={createMarks(question.options)}
                                min={Number(createMarks(question.options)[0].value)}
                                max={Number(createMarks(question.options)[createMarks(question.options).length - 1].value)}

                            />

                        </Box>
                    </div>
                )
            }
            else {
                result.push(
                    <span>No questions assigned.</span>
                )
            }
            result.push(
                <Modal
                    open={checkModalOpen(template.key, questionID)}
                    onClose={() => handleModalClose(template.key, questionID)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 650,
                        bgcolor: 'background.paper',
                        border: "none",
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <div className="log-box" >
                            <h3>Additional instructions to IBO:</h3>
                            <form onSubmit={handleAddNote}>
                                <div className="form-group">
                                    <TextField
                                        sx={{
                                            width: 800,
                                            maxWidth: '100%',
                                        }}
                                        multiline
                                        rows={2}
                                        type="text"
                                        id="outlined"
                                        label="Enter note:"
                                        value={noteInfo}
                                        onChange={(event) => { setNoteInfo(event.target.value) }}
                                    />
                                </div>

                                <Button
                                    variant="contained"
                                    onClick={() => handleAddNote(template.key, questionID, noteInfo)}>
                                    Save Info
                                </Button>
                            </form>

                        </div>
                    </Box>
                </Modal>);
        });
        return result;
    }


    function disabledDate(current) {
        // Can not select days before today and today
        return current <= moment().startOf('day');
    }

    return (
        <>
            <div className="save_questionaire" style={{
                marginBottom: "40px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                {isLoading || saveLoading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Stack direction="row" spacing={3} alignItems="flex-end">
                            <div style={{ display: "inline-cell", verticalAlign: "middle" }}>
                                {selectedDate == null || selectedDate === "" ? (
                                    <>
                                        <Button size="medium" color="error" variant="contained" style={{ width: 200, marginRight: 70, height: 40, marginTop: 60 }} onClick={handleAssignQuestion} disabled >Fill in date</Button>
                                    </>
                                ) : (
                                    <Button size="medium" variant="contained" style={{ width: 200, marginRight: 70, height: 40, marginTop: 60 }} onClick={handleAssignQuestion}>Save & Continue</Button>
                                )}

                            </div>

                            <div style={{ width: "400px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <span style={{ marginBottom: "0px" }}>Set deadline for {stepName}</span>
                                        <Stack direction={'row'} spacing={2}>
                                            <DatePicker
                                                label="Date"
                                                value={selectedDate}
                                                disablePast
                                                inputFormat={'ddd YYYY-MM-DD'}
                                                onChange={handleDateChange}
                                                renderInput={(params) => <TextField size="small" sx={{ width: 200 }} {...params} />}
                                            />

                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                disableClearable
                                                value={selectedTime}
                                                onChange={(event, newValue) => handleTimeChange(newValue)}
                                                options={generateTimeslots().map((option) => option.title)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Time"
                                                        sx={{ width: 140 }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: <InputAdornment position="end"><IconButton size="small"><AccessTimeRoundedIcon /></IconButton></InputAdornment>,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>

                                    </Stack>
                                </LocalizationProvider>
                            </div>
                        </Stack>
                    </>
                )}
            </div>

            {isLoading || saveLoading ? (
                <LinearProgress />
            ) : (
                <>
                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", height: "100%" }}><span>IBO: <strong>{projectData.value}</strong></span></div>

                    <Box sx={{ flexGrow: 1 }}>
                        <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
                            <DragDropContext
                                onDragEnd={result => onDragEnd(result, columns, setColumns)}
                            >
                                {Object.entries(columns).map(([columnId, column], index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: "20px",
                                            }}
                                            key={columnId}
                                        >
                                            {column.name === "Content" ? (
                                                <>
                                                    <div className="header-available-groups"><AssembledBricks style={{ width: "40px", height: "fit-content", marginRight: "10px" }} /><span><strong>Available groups</strong></span></div>
                                                    <Box sx={{ minWidth: 300 }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <FormControl fullWidth size="small">
                                                                    <InputLabel id="demo-simple-select-label"><FilterAltIcon fontSize="small" />Category</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"

                                                                        label="Category"
                                                                        onChange={handleChange}
                                                                    >
                                                                        <MenuItem value={"All"}>All</MenuItem>
                                                                        {groupCategories.map((category) => {
                                                                            return (
                                                                                <MenuItem value={category}>{category}</MenuItem>)
                                                                        })}

                                                                    </Select>

                                                                </FormControl>
                                                            </Grid>

                                                        </Grid>
                                                    </Box></>) : (<>
                                                        <div className="header-assigned-groups"><AssembledBricks style={{ width: "40px", height: "fit-content", marginRight: "10px" }} /><span><strong>Assigned groups</strong></span></div></>)}
                                            <div style={{ margin: 8 }}>
                                                <Droppable droppableId={columnId} key={columnId}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    background: snapshot.isDraggingOver
                                                                        ? "rgba(169, 188, 82, 0.5)"
                                                                        : "white",
                                                                    padding: 10,
                                                                    width: 600,
                                                                    minHeight: 300,
                                                                    height: "800px",
                                                                    overflowY: "auto",
                                                                    overflowX: "hidden",
                                                                    borderRadius: 5,
                                                                    border: "1px solid #f2f3f5",
                                                                    marginBottom: 20,
                                                                }}
                                                            >
                                                                {column.items.map((item, index) => {
                                                                    return (
                                                                        <Draggable
                                                                            key={item.key}
                                                                            draggableId={item.key}
                                                                            index={index}
                                                                        >
                                                                            {(provided, snapshot, selectedColumn) => {
                                                                                //console.log("Selected: ", selectedColumn.source.droppableId);
                                                                                return (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            userSelect: "none",
                                                                                            padding: 12,
                                                                                            borderRadius: 5,
                                                                                            margin: "0 0 8px 0",
                                                                                            minHeight: "50px",
                                                                                            border: "1px solid #f2f3f5",
                                                                                            backgroundColor: selectedColumn.source.droppableId === "selectedColumn" ? "rgba(169, 188, 82, 0.2)" : "rgba(78, 172, 193, 0.2)",
                                                                                            color: "#000000",
                                                                                            ...provided.draggableProps.style
                                                                                        }}
                                                                                    >
                                                                                        <AssembledBricks style={{ width: "24px", height: "fit-content", marginRight: "10px" }} />{item.name}
                                                                                        {/* <Button size="small">More</Button> */}
                                                                                        <div className="group-category-qm-view"> {parse(item.category)}</div>
                                                                                    </div>
                                                                                );
                                                                            }}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            </div>
                                        </div>
                                    );
                                })}
                            </DragDropContext>
                        </div>

                        {/* <Grid item md={6} xl={7}>   <>
                        <div className="header-preview-content"><RemoveRedEyeOutlinedIcon sx={{ fontSize: "40px", marginRight: "10px" }} /><span><strong>Preview</strong></span></div>
                    </>
                        <div className="container" style={{ borderLeft: '2px solid black' }}>

                            <Card className="characters" variant="outlined" sx={{
                                marginBottom: '1rem', paddingBottom: '0rem', height: "85vh", overflow: "scroll",
                            }}>
                                <ul className="preview-list">
                                    {console.log("colums: " + Object.entries(columns).length)}
                                    {assignedTemplates != null && columns.selectedColumn != null ? (

                                        columns.selectedColumn.items.map((assignedTemplate) => {
                                            return mapTemplateQuestions(assignedTemplate);
                                            //return (<h6>Drag over some content to preview it here</h6>)

                                        }

                                        )

                                    ) : (
                                        <h6>Drag over some content to preview it here</h6>
                                    )}

                                </ul>
                            </Card>
                        </div>
                    </Grid> */}



                        <div className="container" style={{ maxWidth: 1300 }}>
                            <div className="header-qm-preview-content">
                                <RemoveRedEyeOutlinedIcon sx={{ fontSize: "40px", marginRight: "10px" }} />
                                <span><strong>Preview</strong></span>
                            </div>

                            <Card className="characters" variant="outlined" sx={{
                                marginBottom: '1rem',
                                paddingBottom: '0rem',
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}>
                                <ul>
                                    {assignedTemplates != null && columns.selectedColumn != null && columns?.selectedColumn?.items.length > 0 ? (
                                        <>
                                            {columns?.selectedColumn?.items.map((assignedTemplate) => {
                                                return mapTemplateQuestions(assignedTemplate);
                                            })}
                                        </>

                                    ) : (
                                        <h6>Drag over some content to preview it here</h6>
                                    )}

                                </ul>
                            </Card>
                        </div>
                        <div className="save_questionaire" style={{ alignContent: 'center' }}>

                            {isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
                            ) : ('')}

                        </div>
                    </Box>
                </>)
            }

            {
                error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Your set of questions is saved!</Alert>
                    </Snackbar>
                )
            }

            {
                error2 ? (
                    <Snackbar
                        open={openSnackbar2}
                        onClose={closeSnackbar2}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar2} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar2}
                        autoHideDuration={2000}
                        onClose={closeSnackbar2}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Deadline has been set!</Alert>
                    </Snackbar>
                )
            }
        </>
    );
}

export default Questions;
