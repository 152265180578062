import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { updateUserData } from '../../firebase/cloudFunctions';
import Box from "@mui/material/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { REFERENCE_LABELS } from "../../constants/lang/eng/fields";
import { Button, FormHelperText, LinearProgress } from "@mui/material";
import { ErrorAlert } from "../../helper/errorAlert";
import { DatepickerBithday } from "../Signup/Components/DatepicketBithday";


//const updateUserData = firebase.functions().httpsCallable('updateUserData')

// Destructuring props
const UpdateUserInfo = ({ userInfo, closeModal }) => {
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errorInfo, setErrorInfo] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [year, setYear] = useState(setYearInit());
    const [month, setMonth] = useState(setMonthInit());
    const [day, setDay] = useState(setDayInit());
    const [daysList, setDays] = useState(setDates());

    const [oldFirstName] = useState(setData('firstName'))
    const [oldLastName] = useState(setData('lastName'))
    const [oldPhone] = useState(setData('phoneNr'));
    const [oldCity] = useState(setData('city'));
    const [oldLanguage] = useState(setData('prefLang'));
    const [oldGender] = useState(setData('gender'));
    const [oldEducation] = useState(setData('education'));
    const [oldHkrConnection] = useState(setData('hkrConnection'));
    const [oldReference] = useState(setData('reference'));
    const [oldExpectations] = useState(setData('expectation'));
    const [oldDob] = useState(setData('dateOfBirth'));

    const [firstName, setFirstName] = useState(setData('firstName'))
    const [lastName, setLastName] = useState(setData('lastName'))
    const [phone, setPhone] = useState(setData('phoneNr'));
    const [city, setCity] = useState(setData('city'));
    const [language, setLanguage] = useState(setData('prefLang'));
    const [gender, setGender] = useState(setData('gender'));
    const [education, setEducation] = useState(setData('education'));
    const [hkrConnection, setHkrConnection] = useState(setData('hkrConnection'));
    const [reference, setReference] = useState(setData('reference'));
    const [updateRef, setUpdateRef] = useState(false);
    const [expectations, setExpectations] = useState(setData('expectation'));
    const [dob, setDob] = useState(setData('dateOfBirth'));

    const [isExpecationsInvalid, setExpectationsInvalid] = useState(false);
    const [isCityInvalid, setCityInvalid] = useState(false);
    const [isPhoneInvalid, setPhoneInvalid] = useState(false);
    const [isGenderInvalid, setGenderInvalid] = useState(false);
    const [isEducationInvalid, setEducationInvalid] = useState(false);
    const [isHkrConnectionInvalid, setHkrConnectionInvalid] = useState(false);
    const [isReferenceInvalid, setReferenceInvalid] = useState(false);
    const [isYearInvalid, setYearInvalid] = useState(false);
    const [isMonthInvalid, setMonthInvalid] = useState(false);
    const [isDayInvalid, setDayInvalid] = useState(false);
    const [generalInvalid, setGeneralInvalid] = useState(false);
    const [isBirthdayInvalid, setBirthdayInvalid] = useState(false);

    const validateAllFields = () => {
        let isError = false;
        if (phone != null && phone.length > 7 && phone.length < 15) {
            setPhoneInvalid(false);
        }
        else {
            isError = true;
            setPhoneInvalid(true);
        }

        if (city != null && city.length >= 1) {
            setCityInvalid(false);
        }
        else {
            isError = true;
            setCityInvalid(true);
        }

        if (gender != null && gender !== "") {
            setGenderInvalid(false);
        }
        else {
            isError = true;
            setGenderInvalid(true);
        }

        if (education != null && education !== "") {
            setEducationInvalid(false);
        }
        else {
            isError = true;
            setEducationInvalid(true);
        }

        if (hkrConnection != null && hkrConnection !== "") {
            setHkrConnectionInvalid(false);
        }
        else {
            isError = true;
            setHkrConnectionInvalid(true);
        }

        if (expectations != null && expectations.length >= 15) {
            setExpectationsInvalid(false);
        }
        else {
            isError = true;
            setExpectationsInvalid(true);
        }

        if (reference != null && reference !== "" && reference !== ";") {
            setReferenceInvalid(false);
        }
        else {
            isError = true;
            setReferenceInvalid(true);
        }

        // @ts-ignore
        if (year != null && year !== "") {
            setYearInvalid(false);
        }
        else {
            isError = true;
            setYearInvalid(true);
        }

        // @ts-ignore
        if (month != null && month !== "") {
            setMonthInvalid(false);
        }
        else {
            isError = true;
            setMonthInvalid(true);
        }

        // @ts-ignore
        if (day != null && day !== "") {
            setDayInvalid(false);
        }
        else {
            isError = true;
            setDayInvalid(true);
        }

        setGeneralInvalid(isError);
        return isError;
    }

    const validateInput = (type) => {
        switch (type) {
            case 'phone':
                console.log("Phone", phone)
                if (phone != null && phone.length > 7 && phone.length < 15) {
                    setPhoneInvalid(false);
                }
                else {
                    setPhoneInvalid(true);
                }
                break;
            case 'city':
                if (city != null && city.length >= 1) {
                    setCityInvalid(false);
                }
                else {
                    setCityInvalid(true);
                }
                break;
            case 'language':
                break;
            case 'gender':
                if (gender != null && gender !== "") {
                    setGenderInvalid(false);
                }
                else {
                    setGenderInvalid(true);
                }
                break;
            case 'education':
                if (education != null && education !== "") {
                    setEducationInvalid(false);
                }
                else {
                    setEducationInvalid(true);
                }
                break;
            case 'hkrConnection':
                if (hkrConnection != null && hkrConnection !== "") {
                    setHkrConnectionInvalid(false);
                }
                else {
                    setHkrConnectionInvalid(true);
                }
                break;
            case 'expectations':
                if (expectations != null && expectations.length >= 15) {
                    setExpectationsInvalid(false);
                }
                else {
                    setExpectationsInvalid(true);
                }
                break;
            case 'reference':
                if (reference != null && reference !== "" && reference !== ";") {
                    setReferenceInvalid(false);
                }
                else {
                    setReferenceInvalid(true);
                }
                break;
            case 'year':
                // @ts-ignore
                if (year != null && year !== "") {
                    setYearInvalid(false);
                }
                else {
                    setYearInvalid(true);
                }
                break;
            case 'month':
                // @ts-ignore
                if (month != null && month !== "") {
                    setMonthInvalid(false);
                }
                else {
                    setMonthInvalid(true);
                }
                break;
            case 'day':
                // @ts-ignore
                if (day != null && day !== "") {
                    setDayInvalid(false);
                }
                else {
                    setDayInvalid(true);
                }
                break;
            default:
                console.log("Other: ", type)
                break;
        }
    }

    const clearInputError = (type) => {
        switch (type) {
            case 'phone':
                setPhoneInvalid(false);
                break;
            case 'city':
                setCityInvalid(false);
                break;
            case 'language':
                break;
            case 'gender':
                setGenderInvalid(false);
                break;
            case 'education':
                setEducationInvalid(false);
                break;
            case 'hkrConnection':
                setHkrConnectionInvalid(false);
                break;
            case 'expectations':
                setExpectationsInvalid(false);
                break;
            case 'reference':
                setReferenceInvalid(false);
                break;
            case 'year':
                setYearInvalid(false);
                break;
            case 'month':
                setMonthInvalid(false);
                break;
            case 'day':
                setDayInvalid(false);
                break;
            default:
                console.log("Other: ", type)
                break;
        }
    }

    const setErrorMessage = (type) => {
        switch (type) {
            case 'phone':
                if (isPhoneInvalid && phone != null && phone !== "") {
                    return "Please fill in a valid phone number"
                }
                else if (isPhoneInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'city':
                if (isCityInvalid) {
                    return "Answer is required";
                }
                else {
                    return "";
                }
            case 'gender':
                if (isGenderInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'education':
                if (isEducationInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'hkrConnection':
                if (isHkrConnectionInvalid) {
                    return "Please choose one option";
                }
                else {
                    return "";
                }
            case 'expectations':
                if (isExpecationsInvalid && expectations === "") {
                    return "Answer is required";
                }
                else if (isExpecationsInvalid) {
                    return "Minimum of 15 charachters is required";
                }
                else {
                    return "";
                }
            case 'reference':
                if (isReferenceInvalid) {
                    return "Please choose one or more options";
                }
                else {
                    return "";
                }
            case 'birthday':
                if (isYearInvalid || isMonthInvalid || isDayInvalid) {
                    return "Please set your date of birth."
                }
                else {
                    return ""
                }
            case 'general':
                if (validateAllFields() === true) {
                    return "Please fill the required fields."
                }
                else {
                    return ""
                }
            default:
                console.log("Other: ", type)
                break;
        }
    }

    function dataUpdated() {
        if (oldFirstName === firstName &&
            oldLastName === lastName &&
            oldPhone === phone &&
            oldCity === city &&
            oldLanguage === language &&
            oldGender === gender &&
            oldEducation === education &&
            oldHkrConnection === hkrConnection &&
            oldExpectations === expectations &&
            oldDob === dob) {
            let changed = false;
            Object.keys(oldReference).forEach((key) => {
                if (oldReference[key] !== reference[key]) {
                    changed = true;
                };
            });
            if (changed === false) {
                return true;
            }
            else {
                return false;
            }

        }
        else {
            return false;
        }
    }

    function setYearInit() {
        let newDate = new Date(Date.now());
        if (userInfo != null && userInfo.data && userInfo.data['dateOfBirth'] != null) {
            const split = String(userInfo.data['dateOfBirth']).split('-');
            newDate.setFullYear(Number(split[0]), Number(split[1]) - 1, Number(split[2]))
            return newDate;
        }
        else {
            return newDate;
        }
    }

    function setMonthInit() {
        if (userInfo != null && userInfo.data && userInfo.data['dateOfBirth'] != null) {
            const split = String(userInfo.data['dateOfBirth']).split('-');
            return Number(split[1]) - 1;
        }
        else {
            return -1;
        }
    }

    function setDayInit() {
        if (userInfo != null && userInfo.data && userInfo.data['dateOfBirth'] != null) {
            const split = String(userInfo.data['dateOfBirth']).split('-');
            return Number(split[2]);
        }
        else {
            return -1;
        }
    }

    function setData(type) {
        if (userInfo != null && userInfo.data && userInfo.data[type] != null) {
            if (type === 'prefLang') {
                if (userInfo.data[type] === 'swe') {
                    return 'Svenska'
                }
                else if (userInfo.data[type] === 'eng') {
                    return 'English'
                }
            }
            else if (type === 'reference') {
                const options = {
                    advertising: false,
                    corporate: false,
                    recommendation: false,
                    university: false,
                    project: false,
                    other: false,
                }
                const split = String(userInfo.data[type]).split(';');
                split.forEach((val) => {
                    const index = Object.values(REFERENCE_LABELS).indexOf(val)
                    const key = Object.keys(REFERENCE_LABELS).at(index);

                    options[key] = true;
                });

                return options;
            }
            return userInfo.data[type];
        }
        else {
            return '';
        }
    }

    const handleChange = (event) => {
        const value = event.target.value;

        switch (event.target.name) {
            case "firstName":
                setFirstName(value);
                break;
            case "lastName":
                setLastName(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "city":
                setCity(value);
                break;
            case "language":
                setLanguage(value);
                break;
            case "date":
            
                setDob(value);
                break;
            case "gender":
                setGender(value);
                break;
            case "education":
                setEducation(value);
                break;
            case "hkrConnection":
                setHkrConnection(value);
                break;
            case "expectations":
                setExpectations(value);
                break;
            case "reference":
                const index = Object.values(REFERENCE_LABELS).indexOf(value)
                const key = Object.keys(REFERENCE_LABELS).at(index);
                const options = reference;
                options[key] = !options[key];
                setReference(options);
                setUpdateRef(!updateRef);
                break;
            default:
                break;

        }
    }

    const onChangeYear = (value) => {
        setYearInvalid(false)
        setYear(value['$d']);
        setDateList(month, value['$d']);
        if (month != null && day != null) {
            const dob = new Date();
            // @ts-ignore
            dob.setFullYear(value['$d'].getFullYear(), month, day);
            handleChange({ target: { name: "date", value: dob.getFullYear() + "-" + String("0" + Number(dob.getMonth() + 1)).slice(-2) + "-" + String("0" + dob.getDate()).slice(-2) } });
        }
    }

    const onChangeMonth = (event) => {
        setMonth(event.target.value);
        setDateList(event.target.value, year);
        setMonthInvalid(false)


        // @ts-ignore
        if (year != null && year !== "" && day != null && day !== "") {
            const dob = new Date();
            // @ts-ignore
            dob.setFullYear(year.getFullYear(), event.target.value, day);
            handleChange({ target: { name: "date", value: dob.getFullYear() + "-" + String("0" + Number(dob.getMonth() + 1)).slice(-2) + "-" + String("0" + dob.getDate()).slice(-2) } });
        }
    }

    const onChangeDay = (event) => {
        //console.log("Date: ", event.target.value);
        setDay(event.target.value);
        setDayInvalid(false)

        // @ts-ignore
        if (year != null && year !== "" && month != null && month !== "") {
            const dob = new Date();
            // @ts-ignore
            dob.setFullYear(year.getFullYear(), month, event.target.value);
            handleChange({ target: { name: "date", value: dob.getFullYear() + "-" + String("0" + Number(dob.getMonth() + 1)).slice(-2) + "-" + String("0" + dob.getDate()).slice(-2) } });
        }

    }

    function setDates() {
        const date = new Date(Date.now());
        // eslint-disable-next-line eqeqeq
        if (year != undefined && month != undefined) {
            date.setFullYear(year.getFullYear());
            date.setMonth(month + 1);
            date.setDate(0);
        }
        const days = []
        for (let i = 1; i <= date.getDate(); i++) {
            days.push(i);
        }

        return days;
    }

    function setDateList(localMonth, localYear) {
        const date = new Date(Date.now());

        if (localYear != null && localYear !== "" && localMonth != null) {
            date.setFullYear(localYear.getFullYear());
            date.setMonth(localMonth + 1);
            date.setDate(0);
        }
        else if (localMonth != null) {
            date.setMonth(localMonth + 1);
            date.setDate(0);
        }

        const localDays = []
        for (let i = 1; i <= date.getDate(); i++) {
            localDays.push(i);
        }

        setDays(localDays);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setIsLoading(true);

        let refString = "";
        Object.keys(reference).forEach((key) => {
            if (reference[key] === true) {
                refString += REFERENCE_LABELS[key] + ";"
            }
        });

        refString = refString.slice(0, refString.length - 1);
        if (validateAllFields() === false) {
            updateUserData({
                userID: userInfo.id,
                gender: gender,
                dob: dob,
                city: city,
                phoneNr: phone,
                prefLang: language,
                education: education,
                hkrConnection: hkrConnection,
                expectation: expectations,
                reference: refString,
                noDataSet: true,
            }).then(() => {
                closeModal();
            }).catch((error) => {
                setErrorInfo(error);
                setErrorMsg('Something went wrong! Could not save data.');
                setError(true);
            }).finally(() => {
                setIsLoading(false);
            })
        }

    };

    return (
        <Box className="update-user-info">
            <IconButton style={{ float: 'right', cursor: 'pointer', marginTop: '-20px' }} onClick={closeModal}>
                <CloseRoundedIcon /></IconButton>
            <span><strong>Update user info:</strong></span>
            <form id="search-form">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} className="setup-phone">
                        <TextField
                            fullWidth
                            label="Phone number"
                            name="phone"
                            placeholder="i.e: xxx-xxx-xxxx"
                            value={phone}
                            onChange={handleChange}
                            onBlur={() => { validateInput('phone') }}
                            //onInput={validatePhone}
                            error={isPhoneInvalid}
                            helperText={setErrorMessage('phone')}
                            margin="normal"

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="setup-city">
                        <TextField
                            fullWidth
                            label="City"
                            name="city"
                            placeholder="Enter your city"
                            onBlur={() => { validateInput('city') }}
                            value={city || ""}
                            margin="normal"
                            onChange={handleChange}
                            //onInput={validateCity}
                            error={isCityInvalid}
                            helperText={setErrorMessage('city')}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth required margin="normal" className="setup-language">
                            <InputLabel id='first-step-gender'>Prefered language</InputLabel>
                            <Select
                                id="first-step-gender"
                                label="Prefered language"
                                value={language} onChange={handleChange} name="language"
                            >

                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Svenska"}>Svenska</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <DatepickerBithday
                            dobDate={userInfo?.data['dateOfBirth']}
                            handleChange={handleChange}
                            setErrorMessage={setErrorMessage}
                            isBirthdayInvalid={isBirthdayInvalid}
                            setBirthdayInvalid={setBirthdayInvalid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} >
                        <FormControl error={isGenderInvalid}>
                            <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>

                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="gender"
                                value={gender}
                                onChange={handleChange}
                            //onInput={validateGender}

                            >
                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                <FormControlLabel value="Other" control={<Radio />} label="Other" />

                            </RadioGroup>
                            <FormHelperText error={isGenderInvalid}>{setErrorMessage('gender')}</FormHelperText>

                        </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={7} md={6} >
                        <FormControl required error={isEducationInvalid}>
                            <FormLabel id="demo-controlled-radio-buttons-group">Education (highest)</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="education"
                                value={education}
                                onChange={handleChange}
                            //error={isEducationInvalid}
                            //onInput={validateEducation}
                            >
                                <FormControlLabel value="Elementary School" control={<Radio />} label="Elementary School" />
                                <FormControlLabel value="Secondary" control={<Radio />} label="Secondary / High School" />
                                <FormControlLabel value="Post secondary education &lt; 3 years" control={<Radio />} label="Post secondary education &lt; 3 years" />
                                <FormControlLabel value="Post secondary education 3-5 years" control={<Radio />} label="Post secondary education 3-5 years" />
                                <FormControlLabel value="Post secondary education &gt; 5 years" control={<Radio />} label="Post secondary education &gt; 5 years" />
                                <FormControlLabel value="PhD" control={<Radio />} label="PhD" />
                            </RadioGroup>
                            <FormHelperText error={isEducationInvalid}>{setErrorMessage('education')}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        {/* <span>Are you a student at or employed by Kristianstad University?</span> */}
                        <FormControl required>
                            <FormLabel id="demo-controlled-radio-buttons-group">Are you a student at or employed by Kristianstad University?</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="hkrConnection"
                                value={hkrConnection}
                                onChange={handleChange}
                            //onInput={validateHkrConnection}

                            >
                                <FormControlLabel value="Student" control={<Radio />} label="Student" />
                                <FormControlLabel value="Employee (researcher)" control={<Radio />} label="Employee (researcher)" />
                                <FormControlLabel value="Employee (teacher)" control={<Radio />} label="Employee (teacher)" />
                                <FormControlLabel value="Employee (other)" control={<Radio />} label="Employee (other)" />
                                <FormControlLabel value="None" control={<Radio />} label="None of the above" />
                            </RadioGroup>
                            <FormHelperText error={isHkrConnectionInvalid}>{setErrorMessage('hkrConnection')}</FormHelperText>
                        </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl component="fieldset" required>
                            <FormLabel component="legend">How did you hear of Krinova?</FormLabel>
                            <FormGroup aria-label="position" id="reference">
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['advertising']}
                                        checked={reference['advertising'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via advertising on social media"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['corporate']}
                                        checked={reference['corporate'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via corporate events"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['recommendation']}
                                        checked={reference['recommendation'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via recommendation"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['university']}
                                        checked={reference['university'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Via the university"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['project']}
                                        checked={reference['project'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"

                                    />}
                                    label="Via a project that Krivnova is involved in"

                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        value={REFERENCE_LABELS['other']}
                                        checked={reference['other'] || false}
                                        onChange={handleChange}
                                        name="reference"
                                        //name="checked"
                                        color="primary"
                                    />}
                                    label="Other"

                                />
                            </FormGroup>
                            <FormHelperText error={isReferenceInvalid}>{setErrorMessage('reference')}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={10} lg={8} >

                        {/* <span>Are you a student at or employed by Kristianstad University?</span> */}
                        <FormControl component="fieldset" required>
                            <FormLabel>What are your expectations on the meeting with Krinova? What do you think your meeting with Krinova will result in?</FormLabel>
                            <TextField
                                id="outlined-multiline-static"
                                label="Answer"
                                multiline
                                name="expectations"
                                rows={4}
                                onChange={handleChange}
                                onBlur={() => { validateInput('expectations') }}
                                //onInput={validateAnswer}
                                value={expectations || ""}
                                error={isExpecationsInvalid}
                                helperText={setErrorMessage('expectations')}

                            />

                        </FormControl>

                    </Grid>

                </Grid>


                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                    {isLoading ? (
                        <Box sx={{ maxWidth: '310px', minWidth: '275px' }}>
                            <LinearProgress color="info" />
                        </Box>
                    ) : (
                        <Box sx={{ marginBottom: "4px" }}>

                        </Box>
                    )}
                </div>
                <div className="backConfirm">
                    <Button
                        sx={{ width: '170px' }}
                        disabled={isLoading || dataUpdated()}
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Update Info
                    </Button>
                </div>
                <FormHelperText error={generalInvalid}>{""}</FormHelperText>
            </form>

            <ErrorAlert isError={error} setIsError={setError} errorMsg={errorMsg} errorInfo={errorInfo} />
        </Box>
    );
};

export default UpdateUserInfo;