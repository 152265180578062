import { httpsCallable } from 'firebase/functions';
import { functions, functionsEUWest } from '../firebase';

//Project
export const createNewProject = httpsCallable(functions, 'createNewProject');
export const addProjectInfo = httpsCallable(functionsEUWest, 'addProjectInfo');
export const addMiroLink = httpsCallable(functions, 'addMiroLink');
export const addUseToProj = httpsCallable(functionsEUWest, 'addParticipantToProj');
export const deleteProject = httpsCallable(functionsEUWest, 'deleteProject');
export const updateCompInfo = httpsCallable(functionsEUWest, 'updateCompInfo');
export const assignBDToProj = httpsCallable(functions, "assignBDToProj");
export const setActiveStatus = httpsCallable(functionsEUWest, 'setActiveStatus');

//UserData
export const setEmailPrefMulti = httpsCallable(functions, 'setEmailPrefMulti');
export const setEmailPref = httpsCallable(functions, 'setEmailPref');
export const createBDUser = httpsCallable(functionsEUWest, 'createBDUser');
export const changeGreetingPhrase = httpsCallable(functionsEUWest, 'changeGreetingPhrase');
export const changeTitle = httpsCallable(functionsEUWest, 'changeTitle');
export const editBDUser = httpsCallable(functionsEUWest, 'editBDUser');
export const createUserData = httpsCallable(functions, 'createUserData');
export const deactivateUser = httpsCallable(functionsEUWest, 'deactivateUser');
export const updateUserData = httpsCallable(functions, 'updateUserData');
export const updateLanguage = httpsCallable(functionsEUWest, 'updateLanguage');
export const deleteBDUser = httpsCallable(functionsEUWest, 'deleteBDUser');

//Timeslots
export const bookTimeSlot = httpsCallable(functions, 'bookTimeSlot');
export const createTimeSlot = httpsCallable(functions, 'createTimeSlot');
export const deleteTimeSlot = httpsCallable(functions, 'deleteTimeSlot');

//Steps
export const setNextTimeStep = httpsCallable(functionsEUWest, 'setNextTimeStep');
export const setStepState = httpsCallable(functionsEUWest, 'setStepState');
export const complemendStep = httpsCallable(functionsEUWest, 'complemendStep');
export const entryStepQuestions = httpsCallable(functionsEUWest, 'entryStepQuestions');
export const entryStepQuestions_save = httpsCallable(functionsEUWest, 'entryStepQuestions_save');
export const updateDeadlineStep = httpsCallable(functionsEUWest, 'updateDeadlineStep');

//Templates
export const createTemplate = httpsCallable(functions, 'createTemplate');
export const assignMultipleTemplates = httpsCallable(functions, 'assignMultipleTemplates');
export const editTemplate = httpsCallable(functions, 'editTemplate');
export const deleteTemplate = httpsCallable(functions, 'deleteTemplate');

//Notes
export const writeNote = httpsCallable(functions, 'writeNote');
export const deleteNote = httpsCallable(functions, 'deleteNote');
export const editNote = httpsCallable(functions, 'editNote');
export const addNoteMetaData = httpsCallable(functionsEUWest, 'addNoteMetaData');

//Questions
export const rectifyQuestion = httpsCallable(functionsEUWest, 'rectifyQuestion');
export const editQuestion = httpsCallable(functionsEUWest, 'editQuestion');
export const createQuestion = httpsCallable(functionsEUWest, 'createQuestion');
export const deleteQuestion = httpsCallable(functionsEUWest, 'deleteQuestion');
export const answerQuestion = httpsCallable(functionsEUWest, 'answerQuestion');
export const answerQuestions = httpsCallable(functionsEUWest, 'answerQuestions');

//Mail
export const sendInvitationalEmail = httpsCallable(functions, 'sendInvitationEmail');

//Grading
export const addGradingToProj = httpsCallable(functions, 'addGradingToProj');

//Statistics
export const gatherMeetingsStats = httpsCallable(functionsEUWest, 'gatherMeetingsStats');
export const gatherLastCompletedCheckpoint = httpsCallable(functionsEUWest, 'gatherLastCompletedCheckpoint');

//Files
export const uploadFile = httpsCallable(functionsEUWest, 'uploadFile');
export const removeFile = httpsCallable(functionsEUWest, 'removeFile');
export const setFileStatus = httpsCallable(functionsEUWest, 'setFileStatus');