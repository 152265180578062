import { useState, useEffect, forwardRef } from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useAuth } from '../../contexts/AuthContext';
import firebaseRefList from '../../firebase/firebaseRefList';
import { db } from '../../firebase';
import { changeGreetingPhrase, changeTitle } from '../../firebase/cloudFunctions';
import { doc, onSnapshot } from 'firebase/firestore';


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProfilePage() {
    const { currentUser } = useAuth();

    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSwe, setIsLoadingSwe] = useState(false);
    const [isLoadingEng, setIsLoadingEng] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [greetingPhraseEng, setGreetingPhraseEng] = useState("");
    const [greetingPhraseSwe, setGreetingPhraseSwe] = useState("");
    const [newGreetingPhraseEng, setNewGreetingPhraseEng] = useState("");
    const [newGreetingPhraseSwe, setNewGreetingPhraseSwe] = useState("");
    const [nameRow, setNameRow] = useState("");
    const [emailRow, setEmailRow] = useState("");
    const [titleRowEng, setTitleRowEng] = useState("");
    const [newTitleRowEng, setNewTitleRowEng] = useState("");
    const [titleRowSwe, setTitleRowSwe] = useState("");
    const [newTitleRowSwe, setNewTitleRowSwe] = useState("");


    //console.log('Time:' + valueStart + valueEnd)
    useEffect(() => {
        setIsLoading(true);
        const getStepData = () => {
            const ref = onSnapshot(doc(db, "Users/" + currentUser?.uid), (querySnapshot) => {
                if (querySnapshot.data() != null) {
                    setEmailRow(querySnapshot.data().email);
                    setNameRow(querySnapshot.data().firstName + " " + querySnapshot.data().lastName)

                    if (querySnapshot.data().signEng != null) {
                        setGreetingPhraseEng(querySnapshot.data().signEng);
                        setNewGreetingPhraseEng(querySnapshot.data().signEng);
                    }

                    if (querySnapshot.data().signSwe != null) {
                        setGreetingPhraseSwe(querySnapshot.data().signSwe);
                        setNewGreetingPhraseSwe(querySnapshot.data().signSwe);
                    }

                    if (querySnapshot.data().titleEng != null) {
                        setTitleRowEng(querySnapshot.data().titleEng);
                        setNewTitleRowEng(querySnapshot.data().titleEng);
                    }

                    if (querySnapshot.data().titleSwe != null) {
                        setTitleRowSwe(querySnapshot.data().titleSwe);
                        setNewTitleRowSwe(querySnapshot.data().titleSwe);
                    }
                }
                else {
                    //console.log("No such document!");
                }

                setIsLoading(false);
            })

            firebaseRefList.push(ref);
        }


        // return cleanup function
        getStepData();


    }, [currentUser]);

    const closeSnackbar = () => {
        setOpenSnackbar(false);
    };

    // call the cloud function
    const changeGreetingPhraseEng = () => {
        //console.log("New: ", newGreetingPhraseEng);

        setIsLoadingEng(true);
        //console.log('Time:' + valueStart + valueEnd)
        changeGreetingPhrase({
            language: "eng",
            phrase: newGreetingPhraseEng,
        })
            .then(() => {
                changeTitle({
                    language: "eng",
                    title: newTitleRowEng,
                }).then(() => {
                    setIsLoadingEng(false);

                    setOpenSnackbar(true);
                }).catch(() => {
                    setError(true);

                    setIsLoadingEng(false);

                    setOpenSnackbar(true);
                });
            }).catch(() => {
                setError(true);

                setIsLoadingEng(false);

                setOpenSnackbar(true);
            });
    }

    const changeGreetingPhraseSwe = async () => {
        //console.log("New: ", newGreetingPhraseSwe);

        setIsLoadingSwe(true);
        //console.log('Time:' + valueStart + valueEnd)
        await changeGreetingPhrase({
            language: "swe",
            phrase: newGreetingPhraseSwe,
        }).then(() => {
            changeTitle({
                language: "swe",
                title: newTitleRowSwe,
            }).then(() => {
                setIsLoadingSwe(false);

                setOpenSnackbar(true);
            }).catch(() => {
                setError(true);

                setIsLoadingSwe(false);

                setOpenSnackbar(true);
            });
        }).catch(() => {
            setError(true);

            setIsLoadingSwe(false);

            setOpenSnackbar(true);
        });


    }

    const handleChange = (event) => {
        if (event.target.name === "greetSwe") {
            setNewGreetingPhraseSwe(event.target.value);
        }
        else if (event.target.name === "greetEng") {
            setNewGreetingPhraseEng(event.target.value);
        }
        else if (event.target.name === "titleSwe") {
            setNewTitleRowSwe(event.target.value);
        }
        else if (event.target.name === "titleEng") {
            setNewTitleRowEng(event.target.value);
        }
    };

    return (
        <div>
            <Stack justifyContent={"center"}>

                <Grid container spacing={5} justifyContent={'center'}>
                    <Grid item xs={5}>
                        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'left' }}>
                            {greetingPhraseEng != null ? (<span>English email signature: <br /></span>) : (<span>No english email signature is currently set!</span>)}
                        </div>

                        <Box>
                            <TextField
                                fullWidth
                                size='small'
                                label="English email signature"
                                name="greetEng"
                                placeholder="New signature!"
                                value={newGreetingPhraseEng}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                {nameRow != null ? (<span>{nameRow}</span>) : (<></>)}
                            </div>
                            <TextField
                                fullWidth
                                size='small'
                                label="English title"
                                name="titleEng"
                                placeholder="New Title!"
                                value={newTitleRowEng}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                {emailRow != null ? (<span>{emailRow}</span>) : (<></>)}
                            </div>
                        </Box>
                        <Button
                            style={{ marginTop: '15px' }}
                            onClick={changeGreetingPhraseEng}
                            disabled={(newGreetingPhraseEng === greetingPhraseEng && titleRowEng === newTitleRowEng) || isLoadingEng}>
                            Update signature
                        </Button>
                        {isLoadingEng ? (<span><CircularProgress size={"12px"} color="success" /></span>) : (<></>)}
                    </Grid>

                    <Grid item xs={5}>
                        <div style={{ marginTop: '40px', display: 'flex', justifyContent: 'left' }}>
                            {greetingPhraseSwe != null ? (<span>Swedish email signature: <br /></span>) : (<span>No swedish email signature is currently set!</span>)}
                        </div>

                        <Box>
                            <TextField
                                fullWidth
                                size='small'
                                label="Swedish email signature"
                                name="greetSwe"
                                placeholder="Ny hälsningsfras!"
                                value={newGreetingPhraseSwe}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                {nameRow != null ? (<span>{nameRow}</span>) : (<></>)}
                            </div>
                            <TextField
                                fullWidth
                                size='small'
                                label="Swedish title"
                                name="titleSwe"
                                placeholder="Ny Title!"
                                value={newTitleRowSwe}
                                onChange={handleChange}
                                margin="normal"
                            />
                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                {emailRow != null ? (<span>{emailRow}</span>) : (<></>)}
                            </div>
                        </Box>
                        <Button
                            style={{ marginTop: '15px' }}
                            onClick={changeGreetingPhraseSwe}
                            disabled={(newGreetingPhraseSwe === greetingPhraseSwe && titleRowSwe === newTitleRowSwe) || isLoadingSwe}>
                            Update signature
                        </Button>
                        {isLoadingSwe ? (<span><CircularProgress size={"12px"} color="success" /></span>) : (<></>)}
                    </Grid>
                </Grid>
            </Stack>

            {
                error ? (
                    <Snackbar
                        open={openSnackbar}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={closeSnackbar} severity="error">
                            Something went wrong!
                        </Alert>
                    </Snackbar>
                ) : (
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={2000}
                        onClose={closeSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert severity="success">Done!!</Alert>
                    </Snackbar>
                )
            }
        </div>
    );
}
