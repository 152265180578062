import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { ConfirmProvider } from 'material-ui-confirm';
import App from './App';
import theme from './theme';

import { createRoot } from 'react-dom/client'
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import reportWebVitals from './reportWebVitals';
import { sendToGoogleAnalytics } from './firebase/analytics';

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <App />
          </ConfirmProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);
