import { Box, Divider, IconButton, List, ListItem, ListItemText, Modal } from "@mui/material";
import { Fragment, useState } from "react";
import AltRouteRoundedIcon from '@mui/icons-material/AltRouteRounded';
import EditIcon from '@mui/icons-material/Edit';

import { useAuth } from "../../../contexts/AuthContext";
import UpdateCompInfo from "../../UpdateData/UpdateCompInfo";
import { StyledListItemEntryStep } from "../../../helper/styledComponents";

export const DisplayCompanyInfo = ({ companyInfo, projectData, updateFrame }) => {
    const { currentUser } = useAuth();

    const [openModalComp, setOpenModalComp] = useState(false);
    const handleModalCompOpen = () => {
        setOpenModalComp(true);
    }

    const handleModalCompClose = () => {
        updateFrame();
        setOpenModalComp(false);
    }

    const displayCompCity = () => {
        if (companyInfo != null && companyInfo.compCity != null) {
            if (String(companyInfo.compCity).includes(';')) {
                let cityData = String(companyInfo.compCity).split(';');
                if (cityData[0] != null && cityData[0] !== '') {
                    return String(cityData[0] + " - " + cityData[1]);
                }
                else {
                    return String(cityData[1]);
                }
            }
            else {
                return String(companyInfo.compCity);
            }
        }
        else {
            return "Not Set";
        }
    }

    return (
        <Fragment>
            <Box sx={{ paddingBottom: '30px' }}>
                {currentUser.role >= 1 ? (
                    <span>
                        <strong>Company info:</strong>

                        <IconButton
                            className="edit-icon-right"

                            onClick={handleModalCompOpen}>

                            <EditIcon style={{ width: "18px", height: "fit-content" }} />
                        </IconButton>
                    </span>
                ) : (
                    <span>
                        <strong>Company info:</strong>
                    </span>
                )}

                <List disablePadding style={{ width: '80%' }}>
                    <ListItem>
                        <StyledListItemEntryStep primary="Website" secondary={projectData.website != null ? projectData.website : "Not Set"} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                        {currentUser.role >= 2 && (
                            <AltRouteRoundedIcon style={{ marginLeft: '-40px', marginRight: '15px', color: 'green' }} />
                        )}
                        <StyledListItemEntryStep primary="Are you developing this idea witin an existing company?" secondary={projectData != null ? projectData.existingComp : "Not Set"} />
                    </ListItem>
                    <Divider />

                    {projectData != null && (projectData.existingComp === "Yes, in a newly formed company based on the idea" || projectData.existingComp === "Yes, within an established company") ? (
                        <>
                            <ListItem>
                                <StyledListItemEntryStep primary="Headquarter" secondary={displayCompCity()} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Company form" secondary={companyInfo != null ? companyInfo.compForm : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Company Name" secondary={companyInfo != null ? companyInfo.compName : "Not Set"} />
                            </ListItem>
                            <Divider />

                            <ListItem>
                                <StyledListItemEntryStep primary="Organization number" secondary={companyInfo != null ? companyInfo.orgNum : "Not Set"} />
                            </ListItem>
                            <Divider />

                            {projectData != null && projectData.existingComp === "Yes, within an established company" ? (
                                <>
                                    <ListItem>
                                        <StyledListItemEntryStep primary="Founded" secondary={companyInfo != null ? companyInfo.founded : "Not Set"} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <StyledListItemEntryStep primary="Role in the company" secondary={companyInfo != null ? companyInfo.compRole : "Not Set"} />
                                    </ListItem>
                                    <Divider />

                                    <ListItem>
                                        <StyledListItemEntryStep primary="Revenue" secondary={companyInfo != null ? companyInfo.revenue : "Not Set"} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ) : ('')}
                        </>
                    ) : ('')}
                </List>
            </Box>

            <Modal
                open={openModalComp}
                onClose={handleModalCompClose}
            >
                <UpdateCompInfo projectData={projectData} compInfo={companyInfo} closeModal={handleModalCompClose} />
            </Modal>
        </Fragment>
    )
};